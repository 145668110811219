import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Header } from '../Header';
import { ContentWrapper } from '../../common/ContentWrapper';
import { Machine } from './machine/Machine';
import { ChangePopup } from '../../informations/laundry/ChangePopup';

import { machines as machines_state, getLaundryMachines } from '../../../redux/slices/informationSlice';
export const HotelLaundry = ({ tab }) => {
    const dispatch = useDispatch();
    const machines = useSelector(machines_state);

    const [changePopupIsOpen, setChangePopupIsOpen] = useState(false);
    const [machineId, setMachineId] = useState(null);

    useEffect(() => {
        dispatch(getLaundryMachines({ hotel_id: tab.hotel_id }));

        let interval;

        if (!changePopupIsOpen) {
            interval = setInterval(async () => {
                await dispatch(getLaundryMachines({ hotel_id: tab.hotel_id }));
            }, 5000);
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [changePopupIsOpen]);
    return (
        <>
            <Header tab={tab} />

            <ContentWrapper>
                <div className="flex flex-wrap gap-6">
                    {machines.length > 0 &&
                        machines.map(machine => (
                            <Machine machine={machine} key={machine.id} setChangePopupIsOpen={setChangePopupIsOpen} setMachineId={setMachineId} />
                        ))}
                </div>
            </ContentWrapper>
            {changePopupIsOpen && (
                <ChangePopup machine={machines.find(el => el.id === machineId)} isOpen={changePopupIsOpen} setIsOpen={setChangePopupIsOpen} />
            )}
        </>
    );
};
