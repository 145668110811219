import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api';
import { set_token_error } from './loginSlice';

export const chatSlice = createSlice({
    name: 'chat',

    initialState: {
        chatList: [],
        chat_list_fetching: false,
        message_list_fetching: false,
        message_send_fetching: false,
        unread_messages_count: [],
        push_send_fetching: false,
        push_status: false,
        chat_filter: '',
    },

    reducers: {
        set_chat_list: (state, action) => {
            state.chatList = action.payload;
        },
        chat_list_fetching_start: state => {
            state.chat_list_fetching = true;
        },
        chat_list_fetching_end: state => {
            state.chat_list_fetching = false;
        },
        message_send_fetching_start: state => {
            state.message_send_fetching = true;
        },
        message_send_fetching_end: state => {
            state.message_send_fetching = false;
        },
        set_unread_messages_counter: (state, action) => {
            state.unread_messages_count = action.payload;
        },
        push_send_fetching_start: state => {
            state.push_send_fetching = true;
        },
        push_send_fetching_end: state => {
            state.push_send_fetching = false;
        },
        set_push_status: (state, action) => {
            state.push_status = action.payload;
        },
        set_chat_filter: (state, action) => {
            state.chat_filter = action.payload;
        },
    },
});

export const {
    set_chat_filter,
    set_push_status,
    push_send_fetching_start,
    push_send_fetching_end,
    set_chat_list,
    chat_list_fetching_start,
    chat_list_fetching_end,
    message_send_fetching_start,
    message_send_fetching_end,
    set_unread_messages_counter,
} = chatSlice.actions;

const getChatList = async (access_token, hotel_id, search_str, dispatch) => {
    if (access_token && hotel_id) {
        const response = await api.getChatList.fetch(access_token, hotel_id, search_str);
        if (response.status === 200) {
            const res = await response.json();

            if (Array.isArray(res.result) && res.result.length) {
                const result = res.result.map(el => ({
                    name: el.Name,
                    room_number: el.room_number,
                    booking_id: el.booking_id,
                    unreadCount: el.messages.filter(message => message.is_admin_read === '0' && !message.from_admin).length,
                    messages: el.messages,
                    status_color: el.status_color,
                    date_from: el.date_from,
                    date_to: el.date_to,
                    podpislon: el.podpislon,
                }));

                dispatch(set_chat_list(result));
            } else {
                dispatch(set_chat_list([]));
            }
        } else if (response.status === 401) {
            dispatch(set_token_error(true));
        }
    }
};

export const getChatListFirstRender =
    ({ access_token, hotel_id, search_str }) =>
    async dispatch => {
        dispatch(chat_list_fetching_start());

        try {
            await getChatList(access_token, hotel_id, search_str, dispatch);
        } catch (e) {
            console.log('getChatList error: ', e);
        } finally {
            dispatch(chat_list_fetching_end());
        }
    };

export const getChatListInBackground =
    ({ access_token, hotel_id, search_str }) =>
    async dispatch => {
        try {
            await getChatList(access_token, hotel_id, search_str, dispatch);
        } catch (e) {
            console.log('getChatListBackground error: ', e);
        }
    };

export const sendMessage =
    ({ access_token, text, booking_id, hotel_id, file }) =>
    async dispatch => {
        dispatch(message_send_fetching_start());

        try {
            if ((text || file) && booking_id) {
                const response = await api.sendMessage.fetch(access_token, booking_id, text, file);
                if (response.status === 200) {
                    await dispatch(getChatList({ access_token, hotel_id }));
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                }
            }
        } catch (e) {
            console.log('sendMessage error: ', e);
        } finally {
            dispatch(message_send_fetching_end());
        }
    };

export const setPushToken =
    ({ access_token, push_token }) =>
    async dispatch => {
        try {
            if (access_token && push_token) {
                const response = await api.setPushToken.fetch(access_token, push_token);
                if (response.status === 200) {
                    // const res = await response.json();
                    // console.log('res', res);
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                }
            }
        } catch (e) {
            console.log('setPushToken error: ', e);
        } finally {
        }
    };

export const setMessagesAsRead =
    ({ access_token, booking_id }) =>
    async dispatch => {
        try {
            if (access_token && booking_id) {
                const response = await api.setMessagesAsRead.fetch(access_token, booking_id);
                if (response.status === 200) {
                    // const res = await response.json();
                    // console.log('res', res);
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                }
            }
        } catch (e) {
            console.log('setMessagesAsRead error: ', e);
        } finally {
        }
    };

export const getUnreadMessagesCount =
    ({ access_token }) =>
    async dispatch => {
        try {
            if (access_token) {
                const response = await api.getUnreadMessagesCount.fetch(access_token);
                if (response.status === 200) {
                    const res = await response.json();
                    if (res.hasOwnProperty('result')) {
                        dispatch(set_unread_messages_counter(res.result));
                    }
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                }
            }
        } catch (e) {
            console.log('getUnreadMessagesCount error: ', e);
        }
    };

export const sendPushNotification =
    ({ access_token, data }) =>
    async dispatch => {
        dispatch(push_send_fetching_start());
        try {
            if (access_token) {
                const response = await api.sendPushNotification.fetch(access_token, data);
                if (response.status === 200) {
                    const res = await response.json();
                    dispatch(set_push_status(res.code));
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                }
                return response.status;
            }
        } catch (e) {
            console.log('sendPushNotification error: ', e);
        } finally {
            dispatch(push_send_fetching_end());
        }
    };

export const chat_list = state => state.chat.chatList;
export const message_send_fetch = state => state.chat.message_send_fetching;
export const unread_messages_counter = state => state.chat.unread_messages_count;
export const chat_list_fetching = state => state.chat.chat_list_fetching;
export const push_send_fetching = state => state.chat.push_send_fetching;
export const push_status = state => state.chat.push_status;
export const chat_filter = state => state.chat.chat_filter;

export default chatSlice.reducer;
