import { useEffect, useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { RotatingLines } from 'react-loader-spinner';

import { Header } from './Header';
import { ContentWrapper } from '../common/ContentWrapper';
import { CustomReactQuill } from '../common/CustomReactQuill';
import { getUpdateString, errorNotification, successNotification } from '../../api/functions';

import {
    editInformation,
    set_response_status,
    set_update_info_fetch,
    response_status as response_status_state,
    information_tabs as information_tabs_state,
} from '../../redux/slices/informationSlice';
import { access_token as access_token_state } from '../../redux/slices/loginSlice';

const AboutSchema = Yup.object().shape({
    preview_name: Yup.string()
        .min(2, 'Поле "Название отеля" слишком короткое')
        .max(100, 'Поле "Название отеля" слишком длинное')
        .required('Поле "Название отеля" обязательное'),
    preview_name_en: Yup.string()
        .min(2, 'Поле "Название отеля (на англ)" слишком короткое')
        .max(100, 'Поле "Название отеля (на англ)" слишком длинное')
        .required('Поле "Название отеля (на англ)" обязательное'),
    preview_description: Yup.string().min(12, 'Поле "Описание" слишком короткое').required('Поле "Описание" обязательное'),
    preview_description_en: Yup.string().min(12, 'Поле "Описание (на англ)" слишком короткое').required('Поле "Описание (на англ)" обязательное'),
});

export const HotelAbout = ({ tab_info }) => {
    const [initValues, setInitValues] = useState({
        preview_name: '',
        preview_name_en: '',
        preview_description: '',
        preview_description_en: '',
    });

    const [fetchAccessToken, setFetchAccessToken] = useState('');
    const [disableBtn, setDisableBtn] = useState(false);

    const formikRef = useRef();

    const dispatch = useDispatch();
    const access_token = useSelector(access_token_state);
    const response_status = useSelector(response_status_state);
    const information_tabs = useSelector(information_tabs_state);

    useEffect(() => {
        if (response_status) {
            successNotification();
            dispatch(set_response_status(false));
        }
    }, [response_status]);

    useEffect(() => {
        const tab = information_tabs.find(el => el.id === tab_info.id);
        if (tab && information_tabs) {
            setInitValues({
                preview_name: tab.preview_name,
                preview_name_en: tab.preview_name_en,
                preview_description: tab.preview_description,
                preview_description_en: tab.preview_description_en,
            });
        }
    }, [tab_info]);

    const formSubmit = async (values, { setSubmitting, resetForm }) => {
        setFetchAccessToken(access_token);
        dispatch(set_update_info_fetch(true));

        let status;
        const detail_en = await getUpdateString(values.preview_description_en, tab_info, access_token);
        const detail_ru = await getUpdateString(values.preview_description, tab_info, access_token);

        if (detail_ru.length > 10000 || detail_en.length > 10000) {
            dispatch(set_update_info_fetch(false));
            return errorNotification('Уменьшите описание');
        }
        const data = {
            ...values,
            preview_description: detail_ru,
            preview_description_en: detail_en,
            tab_item_id: tab_info.id,
            hotel_id: tab_info.hotel_id,
            section_link: tab_info.section_link,
        };

        status = await dispatch(
            editInformation({
                access_token,
                data,
            })
        );

        if (status === 200) {
            setSubmitting(false);
            resetForm();
        }
    };
    useEffect(() => {
        (async () => {
            if (fetchAccessToken && fetchAccessToken !== access_token) {
                await formSubmit(formikRef.current.values, formikRef.current);
            }
        })();
    }, [access_token]);

    const disabledBtn = isDisabled => {
        setDisableBtn(isDisabled);
    };

    return (
        <>
            <Header tab={tab_info} />
            <ContentWrapper>
                <Formik initialValues={initValues} validationSchema={AboutSchema} innerRef={formikRef} onSubmit={formSubmit} enableReinitialize={true}>
                    {({ values, errors, isSubmitting, isValidating, dirty }) => {
                        if (isSubmitting && !isValidating) {
                            for (const err in errors) {
                                errorNotification(errors[err].toString());
                            }
                        }
                        return (
                            <Form>
                                <div className="bg-white 2xl:w-1/2">
                                    <div className="flex items-center border-b bg-white">
                                        <p className="pb-6 pt-6 pl-6 font-medium">Укажите основную информацию об отеле</p>
                                        <div className="ml-auto mr-6" data-tip="Информация об отеле">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path
                                                    d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                    stroke="#1890FF"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path
                                                    d="M11.9502 8H12.0502V8.1H11.9502V8Z"
                                                    stroke="#1890FF"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </div>

                                    {/* hotel name */}
                                    <div className="mt-3 pl-6 pr-6">
                                        <p className="text-sm mb-2 text-gray-20 flex w-full">Название отеля</p>
                                        <Field
                                            name="preview_name"
                                            component="textarea"
                                            value={values.preview_name}
                                            placeholder="Введите название отеля"
                                            className="box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2"
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                    {/* hotel name EN */}
                                    <div className="mt-3 pl-6 pr-6">
                                        <p className="text-sm mb-2 text-gray-20 flex w-full">Hotel name</p>
                                        <Field
                                            name="preview_name_en"
                                            component="textarea"
                                            value={values.preview_name_en}
                                            placeholder="Enter the hotel name"
                                            className="box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2"
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                    {/* Description */}
                                    <div className="mt-3 pl-6 pr-6">
                                        <p className="text-sm mb-2 text-gray-20 flex w-full">Описание</p>
                                        <Field
                                            name="preview_description"
                                            value={values.preview_description}
                                            placeholder="Описание"
                                            className="box-border w-full h-24 text-sm  rounded border-gray-10 p-1 pl-2"
                                            disabled={isSubmitting}
                                        >
                                            {({ field, imageHandler }) => (
                                                <CustomReactQuill
                                                    imageHandler={imageHandler}
                                                    value={field.value}
                                                    onChange={field.onChange(field.name)}
                                                    disabledBtn={disabledBtn}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    {/* Description EN */}
                                    <div className="mt-3 pl-6 pr-6 pb-6">
                                        <p className="text-sm mb-2 text-gray-20 flex w-full">Description</p>
                                        <Field
                                            name="preview_description_en"
                                            value={values.preview_description_en}
                                            placeholder="description"
                                            className="box-border w-full h-24 text-sm rounded border-gray-10 p-1 pl-2"
                                            disabled={isSubmitting}
                                        >
                                            {({ field, imageHandler }) => (
                                                <CustomReactQuill
                                                    imageHandler={imageHandler}
                                                    value={field.value}
                                                    onChange={field.onChange(field.name)}
                                                    disabledBtn={disabledBtn}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className={` mt-3 rounded-sm font-medium text-sm px-14 h-10  ${
                                        dirty && !disableBtn ? 'bg-blue-500 text-white cursor-pointer' : 'bg-slate-300 cursor-default text-gray-400'
                                    } `}
                                    disabled={(!isSubmitting && !dirty) || disableBtn}
                                >
                                    {isSubmitting ? <RotatingLines width="20" strokeColor="white" /> : 'Сохранить'}
                                </button>
                            </Form>
                        );
                    }}
                </Formik>
            </ContentWrapper>
            <ReactTooltip textColor="#FCFCFC" backgroundColor="#8c8d9d" effect="solid" className="!rounded-md !py-2 !px-2.5" />
        </>
    );
};
