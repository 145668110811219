import { useEffect, useState, useRef } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { RotatingLines } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

import { Header } from '../Header';
import { ContentWrapper } from '../../common/ContentWrapper';
import { Activity } from './templatePart/Activity';
import { SortablePicsLoader } from '../../common/SortablePicsLoader';
import { CustomReactQuill } from '../../common/CustomReactQuill';

import { getUpdateString, successNotification, errorNotification } from '../../../api/functions';
import {
    createRoom,
    editSubInformation,
    set_response_status,
    response_status as response_status_state,
    updateInfoFetch as updateInfoFetch_state,
} from '../../../redux/slices/informationSlice';
import { access_token as access_token_state } from '../../../redux/slices/loginSlice';

const RoomSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Поле "Название" слишком короткое').max(50, 'Поле "Название" слишком длинное').required('Поле "Название" обязательное'),
    name_en: Yup.string()
        .min(2, 'Поле "Название (на англ)" слишком короткое')
        .max(50, 'Поле "Название (на англ)" слишком длинное')
        .required('Поле "Название (на англ)" обязательное'),
    preview_description: Yup.string()
        .min(2, 'Поле "Размеры" слишком короткое')
        .max(50, 'Поле "Размеры" слишком длинное')
        .required('Поле "Размеры" обязательное'),
    detail_description: Yup.string().min(12, 'Поле "Описание" слишком короткое').required('Поле "Описание" обязательное'),
    detail_description_en: Yup.string().min(12, 'Поле "Описание (на англ)" слишком короткое').required('Поле "Описание (на англ)" обязательное'),
    files: Yup.array().min(1, 'Добавьте минимум одно фото номера'),
});

const initialValues = {
    name: '',
    name_en: '',
    preview_description: '',
    detail_description: '',
    detail_description_en: '',
    files: [],
    active: [],
};
export const RoomsTemplate = ({ tab_info }) => {
    const [fetchAccessToken, setFetchAccessToken] = useState('');
    const [disableBtn, setDisableBtn] = useState(false);
    const [initValues, setInitValues] = useState(initialValues);

    const formikRef = useRef();

    const dispatch = useDispatch();
    const access_token = useSelector(access_token_state);
    const response_status = useSelector(response_status_state);
    const updateInfoFetch = useSelector(updateInfoFetch_state);

    const navigate = useNavigate();

    useEffect(() => {
        if (response_status) {
            successNotification();
            dispatch(set_response_status(false));
        }
    }, [response_status]);

    const formSubmit = async (values, { setSubmitting, resetForm }) => {
        setFetchAccessToken(access_token);
        let status;
        let id;

        // создание номера, получение id
        const createData = {
            ...initValues,
            parent_id: tab_info.sub_tab_id,
            hotel_id: tab_info.hotel_id,
        };
        id = await dispatch(createRoom({ data: createData }));

        // обновление номера
        const detail_en = await getUpdateString(values.detail_description_en, tab_info, access_token);
        const detail_ru = await getUpdateString(values.detail_description, tab_info, access_token);

        if (detail_ru.length > 10000 || detail_en.length > 10000) {
            return errorNotification('Уменьшите описание');
        }

        const data = {
            ...values,
            detail_description: detail_ru,
            detail_description_en: detail_en,
            tab_item_id: id,
            hotel_id: tab_info.hotel_id,
            sub_tab_id: tab_info.sub_tab_id,
            section_link: tab_info.section_link,
            active: values.active[0] === 'active' ? '1' : '0',
        };

        status = await dispatch(editSubInformation({ access_token, data }));

        if (status === 200) {
            setSubmitting(false);
            resetForm({ values });
            return navigate(`/object/${tab_info.hotel_id}/information/information_hotel_rooms/`);
        }
    };
    useEffect(() => {
        (async () => {
            if (fetchAccessToken && fetchAccessToken !== access_token) {
                await formSubmit(formikRef.current.values, formikRef.current.setSubmitting);
            }
        })();
    }, [access_token]);

    const disabledBtn = isDisabled => {
        setDisableBtn(isDisabled);
    };

    const sortDispatch = data => {
        setInitValues({ ...formikRef.current.values, files: data });
    };

    return (
        <>
            <Header tab={tab_info} />

            <ContentWrapper>
                <Formik initialValues={initValues} validationSchema={RoomSchema} innerRef={formikRef} onSubmit={formSubmit} enableReinitialize={true}>
                    {({ values, errors, isValidating, isSubmitting, dirty, setFieldValue }) => {
                        if (isSubmitting && !isValidating) {
                            for (const err in errors) {
                                errorNotification(errors[err].toString());
                            }
                        }
                        return (
                            <Form>
                                <div className="sm:flex ">
                                    {/* main info */}
                                    <div className="w-full sm:w-1/2 bg-white">
                                        <div className="flex items-center border-b">
                                            <p className="pb-6 pt-6 pl-6 font-medium">Укажите основную информацию</p>
                                            <div className="ml-auto mr-6" data-tip="Информация">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path
                                                        d="M11.9502 8H12.0502V8.1H11.9502V8Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                        </div>

                                        {/* activity */}
                                        <Activity />

                                        {/* room name */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-sm mb-2 text-gray-20 flex w-full">Название</p>
                                            <Field
                                                name="name"
                                                component="textarea"
                                                value={values.name}
                                                placeholder="Введите название номера"
                                                className="box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2"
                                                disabled={isSubmitting}
                                            />
                                        </div>

                                        {/* room name en */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-sm mb-2 text-gray-20 flex w-full">Name</p>
                                            <Field
                                                name="name_en"
                                                component="textarea"
                                                value={values.name_en}
                                                placeholder="Enter the room name"
                                                className="box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2"
                                                disabled={isSubmitting}
                                            />
                                        </div>

                                        {/* size */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-sm mb-2 text-gray-20 flex w-full">Размеры</p>
                                            <Field
                                                name="preview_description"
                                                component="textarea"
                                                value={values.preview_description}
                                                placeholder="Enter the room size"
                                                className="box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2"
                                                disabled={isSubmitting}
                                            />
                                        </div>

                                        {/* description */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-sm mb-2 text-gray-20 flex w-full">Описание</p>
                                            <Field
                                                name="detail_description"
                                                value={values.detail_description}
                                                placeholder="Описание"
                                                className="box-border w-full h-40 text-sm  rounded border-gray-10 p-1 pl-2"
                                                disabled={isSubmitting}
                                            >
                                                {({ field, imageHandler }) => (
                                                    <CustomReactQuill
                                                        imageHandler={imageHandler}
                                                        value={field.value}
                                                        onChange={field.onChange(field.name)}
                                                        disabledBtn={disabledBtn}
                                                        placeholder="Описание..."
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                        {/* description EN */}
                                        <div className="mt-3 pl-6 pr-6 pb-6">
                                            <p className="text-sm mb-2 text-gray-20 flex w-full">Description</p>
                                            <Field
                                                name="detail_description_en"
                                                value={values.detail_description_en}
                                                placeholder="Description"
                                                className="box-border w-full h-40 text-sm rounded border-gray-10 p-1 pl-2"
                                                disabled={isSubmitting}
                                            >
                                                {({ field, imageHandler }) => (
                                                    <CustomReactQuill
                                                        imageHandler={imageHandler}
                                                        value={field.value}
                                                        onChange={field.onChange(field.name)}
                                                        disabledBtn={disabledBtn}
                                                        placeholder="Description..."
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    </div>

                                    {/* photo */}
                                    <div className="w-full ml-0 mt-3 pb-3 sm:w-1/2 sm:ml-6 sm:mt-0 bg-white  ">
                                        <div className="flex items-center border-b">
                                            <p className="pb-6 pt-6 pl-6 font-medium">Загрузите фотографии</p>
                                            <div className="ml-auto mr-6" data-tip="Фото">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path
                                                        d="M11.9502 8H12.0502V8.1H11.9502V8Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                        </div>

                                        <div className="ml-6 mt-6 mr-6">
                                            <SortablePicsLoader setFieldValue={setFieldValue} files={values.files} maxFiles={20} sortDispatch={sortDispatch} />
                                        </div>
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className={` mt-3 rounded-sm font-medium text-sm px-14 h-10  ${
                                        dirty && !disableBtn ? 'bg-blue-500 text-white cursor-pointer' : 'bg-slate-300 cursor-default text-gray-400'
                                    } `}
                                    disabled={(!isSubmitting && !dirty) || disableBtn || updateInfoFetch}
                                >
                                    {updateInfoFetch ? <RotatingLines width="20" strokeColor="white" /> : 'Сохранить'}
                                </button>
                            </Form>
                        );
                    }}
                </Formik>
            </ContentWrapper>

            <ReactTooltip textColor="#FCFCFC" backgroundColor="#8c8d9d" effect="solid" className="!rounded-md !py-2 !px-2.5" />
        </>
    );
};
