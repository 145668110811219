import { Field } from 'formik';
import ReactTooltip from 'react-tooltip';

export const Activity = () => {
    return (
        <>
            <div className="flex items-center">
                <p className="pb-6 pt-6 pl-6 font-medium mr-4">Укажите активность</p>
                <Field type="checkbox" name="active" value="active" />

                <div className="ml-auto mr-6" data-tip="Видимость раздела в мобильном приложении">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                            stroke="#1890FF"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M11.9502 8H12.0502V8.1H11.9502V8Z" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
            <ReactTooltip textColor="#FCFCFC" backgroundColor="#8c8d9d" effect="solid" className="!rounded-md !py-2 !px-2.5" />
        </>
    );
};
