import { useEffect, useState, useRef } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { RotatingLines } from 'react-loader-spinner';

import { Header } from './Header';
import { ContentWrapper } from '../common/ContentWrapper';

import {
    editInformation,
    set_response_status,
    response_status as response_status_state,
    information_tabs as information_tabs_state,
} from '../../redux/slices/informationSlice';
import { access_token as access_token_state } from '../../redux/slices/loginSlice';

const WifiSchema = Yup.object().shape({
    wi_fi_login: Yup.string().min(2, 'Поле "Логин" слишком короткое').max(100, 'Поле "Логин" слишком длинное').required('Поле "Логин" обязательное'),
    wi_fi_password: Yup.string().min(2, 'Поле "Пароль" слишком короткое').max(100, 'Поле "Пароль" слишком длинное').required('Поле "Пароль" обязательное'),
});

export const HotelWiFi = ({ tab_info }) => {
    const [initValues, setInitValues] = useState({
        wi_fi_login: '',
        wi_fi_password: '',
    });
    const [fetchAccessToken, setFetchAccessToken] = useState('');

    const formikRef = useRef();

    const dispatch = useDispatch();
    const access_token = useSelector(access_token_state);
    const response_status = useSelector(response_status_state);
    const information_tabs = useSelector(information_tabs_state);

    useEffect(() => {
        if (response_status) {
            toast.success('Информация сохранена', {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });

            dispatch(set_response_status(false));
        }
    }, [response_status]);

    useEffect(() => {
        const tab = information_tabs?.find(el => el.id === tab_info.id);

        if (tab) {
            setInitValues({
                wi_fi_login: tab.wi_fi_login === null ? '' : tab.wi_fi_login,
                wi_fi_password: tab.wi_fi_password === null ? '' : tab.wi_fi_password,
            });
        }
    }, [tab_info]);

    const formSubmit = async (values, { setSubmitting }) => {
        setFetchAccessToken(access_token);
        let status;
        const data = {
            ...values,
            tab_item_id: tab_info.id,
            hotel_id: tab_info.hotel_id,
            section_link: tab_info.section_link,
        };

        status = await dispatch(editInformation({ access_token, data }));

        if (status === 200) {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        (async () => {
            if (fetchAccessToken && fetchAccessToken !== access_token) {
                await formSubmit(formikRef.current.values, formikRef.current.setSubmitting);
            }
        })();
    }, [access_token]);

    return (
        <>
            <Header tab={tab_info} />
            <ContentWrapper>
                <Formik initialValues={initValues} validationSchema={WifiSchema} innerRef={formikRef} onSubmit={formSubmit} enableReinitialize={true}>
                    {({ values, errors, isValidating, isSubmitting, dirty }) => {
                        if (isSubmitting && !isValidating) {
                            for (const err in errors) {
                                toast.error(errors[err].toString(), {
                                    position: 'bottom-right',
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: 'colored',
                                });
                            }
                        }
                        return (
                            <Form>
                                <div className="sm:flex ">
                                    {/* main info */}
                                    <div className="pb-1 sm:w-1/2 bg-white">
                                        <div className="flex items-center border-b">
                                            <p className="pb-6 pt-6 pl-6 font-medium">Укажите информацию о WiFi</p>
                                            <div className="ml-auto mr-6" data-tip="Информация">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path
                                                        d="M11.9502 8H12.0502V8.1H11.9502V8Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                        </div>

                                        {/* working hours */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-sm mb-2 text-gray-20 flex w-full">Логин</p>
                                            <Field
                                                name="wi_fi_login"
                                                component="textarea"
                                                value={values.wi_fi_login}
                                                placeholder="Введите логин"
                                                className="box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2"
                                                disabled={isSubmitting}
                                            />
                                        </div>

                                        {/* working hours EN */}
                                        <div className="mt-3 pl-6 pr-6 mb-6">
                                            <p className="text-sm mb-2 text-gray-20 flex w-full">Пароль</p>
                                            <Field
                                                name="wi_fi_password"
                                                component="textarea"
                                                value={values.wi_fi_password}
                                                placeholder="Введите пароль"
                                                className="box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2"
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className={` mt-3 rounded-sm font-medium text-sm px-14 h-10  ${
                                        dirty ? 'bg-blue-500 text-white cursor-pointer' : 'bg-slate-300 cursor-default text-gray-400'
                                    } `}
                                    disabled={!isSubmitting && !dirty}
                                >
                                    {isSubmitting ? <RotatingLines width="20" strokeColor="white" /> : 'Сохранить'}
                                </button>
                            </Form>
                        );
                    }}
                </Formik>
            </ContentWrapper>
            <ReactTooltip textColor="#FCFCFC" backgroundColor="#8c8d9d" effect="solid" className="!rounded-md !py-2 !px-2.5" />
        </>
    );
};
