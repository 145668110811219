import { Field, FieldArray } from 'formik';

export const IncludedOptions = ({ values, isSubmitting }) => {
    return (
        <>
            <div className="text-base mb-2 text-gray-20 flex w-full justify-center">Опции входящие в тариф</div>
            <FieldArray name="included_options">
                {({ remove, push }) => (
                    <div className="relative ">
                        {values.included_options &&
                            values.included_options.map((option, id) => (
                                <div key={option.id} className="border-b-2 mb-4">
                                    {values.included_options.length > 0 && (
                                        <div className="absolute right-0 text-gray-500 border transition-colors rounded-full h-5 hover:border-red-600 hover:text-red-600">
                                            <button type="button" onClick={() => remove(id)}>
                                                <svg width="18" height="18" viewBox="0 0 24 24" className="rotate-45 fill-current">
                                                    <g clipPath="url(#clip0_2599_10981)">
                                                        <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2599_10981">
                                                            <rect width="24" height="24" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </button>
                                        </div>
                                    )}
                                    <div className="mb-1.5">
                                        <p className="text-sm mb-1 text-gray-20 flex w-full text-gray-500">Название опции</p>
                                        <Field
                                            name={`included_options.${id}.name`}
                                            component="textarea"
                                            placeholder="Введите название"
                                            className={`box-border w-full h-6 text-xs resize-none rounded border-gray-10 p-0.5 pl-2 ${
                                                values?.included_options[id]?.name === '' ? 'border-red-500' : ''
                                            }`}
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                    <div className="mb-1.5">
                                        <p className="text-sm mb-1 text-gray-20 flex w-full text-gray-500">Название опции на английском</p>
                                        <Field
                                            name={`included_options.${id}.name_en`}
                                            component="textarea"
                                            placeholder="Enter name"
                                            className={`box-border w-full h-6 text-xs resize-none rounded border-gray-10 p-0.5 pl-2 ${
                                                values?.included_options[id]?.name_en === '' ? 'border-red-500' : ''
                                            }`}
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                </div>
                            ))}
                        <div className="flex justify-center">
                            <button
                                className=" text-xs py-0.5 px-3 border rounded text-white transition-colors bg-blue-400 hover:bg-blue-600"
                                type="button"
                                onClick={() =>
                                    push({
                                        name: '',
                                        name_en: '',
                                        id: Date.now(),
                                    })
                                }
                            >
                                Добавить опцию
                            </button>
                        </div>
                    </div>
                )}
            </FieldArray>
        </>
    );
};
