import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';

export const Calendar = ({ selectedDate, setSelectedDate }) => {
    return (
        <div>
            <DatePicker
                locale={ru}
                onChange={date => setSelectedDate(date)}
                dateFormat="dd MMMM yyyy"
                className="h-8 w-40 text-center text-sm border-blue-500 text-blue-500 rounded cursor-pointer"
                selected={selectedDate}
            />
        </div>
    );
};
