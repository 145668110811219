import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { RotatingLines } from 'react-loader-spinner';

import { Header } from './Header';
import { ContentWrapper } from '../common/ContentWrapper';
import { SortablePicsLoader } from '../common/SortablePicsLoader';

import { access_token as access_token_state } from '../../redux/slices/loginSlice';
import { addHotelInfoPics, deleteHotelInfoPics, getHotelInfoPics, hotel_info_pics as hotel_info_pics_state } from '../../redux/slices/informationSlice';
import { errorNotification, successNotification } from '../../api/functions';

export const HotelMain = ({ tab_info }) => {
    const dispatch = useDispatch();
    const formikRef = useRef();

    const [initValues, setInitValues] = useState({
        files: [],
    });
    const [fetchAccessToken, setFetchAccessToken] = useState('');

    const access_token = useSelector(access_token_state);
    const hotel_info_pics = useSelector(hotel_info_pics_state);

    useEffect(() => {
        if (tab_info && tab_info.hasOwnProperty('hotel_id')) {
            dispatch(getHotelInfoPics({ access_token, hotel_id: tab_info.hotel_id }));
        }
    }, []);

    useEffect(() => {
        setInitValues({ files: hotel_info_pics });
    }, [hotel_info_pics]);

    const formSubmit = async (values, { setSubmitting, resetForm }) => {
        setFetchAccessToken(access_token);

        const result = await dispatch(addHotelInfoPics({ access_token, hotel_id: tab_info.hotel_id, files: values.files }));

        if (result.error) {
            if (result.error === 1) {
                errorNotification(result.message);
            }
        } else {
            setSubmitting(false);
            resetForm({ values });

            successNotification();
        }
    };

    useEffect(() => {
        (async () => {
            if (fetchAccessToken && fetchAccessToken !== access_token) {
                await formSubmit(formikRef.current.values, formikRef.current);
            }
        })();
    }, [access_token]);

    const deleteFile = async file => {
        const isPicAdd = hotel_info_pics.find(pic => pic.url === file.url);
        if (isPicAdd) {
            const result = await dispatch(deleteHotelInfoPics({ access_token, file: file.url }));
            if (result.error) {
                if (result.error === 1) {
                    errorNotification(result.message);
                }
            } else {
                successNotification();
            }
        }
    };

    const sortDispatch = data => {
        setInitValues(prevState => ({ ...prevState, files: data }));
    };

    return (
        <>
            <Header tab={tab_info} />
            <ContentWrapper>
                <Formik initialValues={initValues} innerRef={formikRef} onSubmit={formSubmit} enableReinitialize={true}>
                    {({ values, errors, isSubmitting, isValidating, dirty, setFieldValue }) => {
                        if (isSubmitting && !isValidating) {
                            for (const err in errors) {
                                errorNotification(errors[err]);
                            }
                        }
                        return (
                            <Form>
                                <div className="pb-6 bg-white">
                                    <div className="border-b p-6 font-medium">Загрузите фотографии</div>
                                    <div className="m-6 mb-0">
                                        <SortablePicsLoader
                                            setFieldValue={setFieldValue}
                                            files={values.files}
                                            maxFiles={20}
                                            callback={deleteFile}
                                            sortDispatch={sortDispatch}
                                        />
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className={`mt-3 rounded-sm font-medium text-sm px-14 h-10  ${
                                        !isSubmitting ? 'bg-blue-500 text-white cursor-pointer' : 'bg-slate-300 cursor-default text-gray-400'
                                    }`}
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? <RotatingLines width="20" strokeColor="white" /> : 'Сохранить'}
                                </button>
                            </Form>
                        );
                    }}
                </Formik>
            </ContentWrapper>
        </>
    );
};
