import { SubHeader } from '../common/SubHeader';

export const Header = ({ category, setItem, setEditCategory }) => {
    return (
        <SubHeader>
            {!!category && (
                <>
                    <div className="uppercase text-sm text-gray w-full flex flex-row text-xl font-semibold items-center text-gray-500">
                        <span className="whitespace-nowrap">{category.hotel_name}</span>
                        <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                            <path d="M9.70711 4.29289C9.31658 3.90237 8.68342 3.90237 8.29289 4.29289C7.90237 4.68342 7.90237 5.31658 8.29289 5.70711L9.70711 4.29289ZM16 12L16.7071 12.7071C17.0976 12.3166 17.0976 11.6834 16.7071 11.2929L16 12ZM8.29289 18.2929C7.90237 18.6834 7.90237 19.3166 8.29289 19.7071C8.68342 20.0976 9.31658 20.0976 9.70711 19.7071L8.29289 18.2929ZM8.29289 5.70711L15.2929 12.7071L16.7071 11.2929L9.70711 4.29289L8.29289 5.70711ZM15.2929 11.2929L8.29289 18.2929L9.70711 19.7071L16.7071 12.7071L15.2929 11.2929Z" />
                        </svg>
                        <span className="whitespace-nowrap">{category.shop_name}</span>
                        <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                            <path d="M9.70711 4.29289C9.31658 3.90237 8.68342 3.90237 8.29289 4.29289C7.90237 4.68342 7.90237 5.31658 8.29289 5.70711L9.70711 4.29289ZM16 12L16.7071 12.7071C17.0976 12.3166 17.0976 11.6834 16.7071 11.2929L16 12ZM8.29289 18.2929C7.90237 18.6834 7.90237 19.3166 8.29289 19.7071C8.68342 20.0976 9.31658 20.0976 9.70711 19.7071L8.29289 18.2929ZM8.29289 5.70711L15.2929 12.7071L16.7071 11.2929L9.70711 4.29289L8.29289 5.70711ZM15.2929 11.2929L8.29289 18.2929L9.70711 19.7071L16.7071 12.7071L15.2929 11.2929Z" />
                        </svg>
                        <span className="text-black whitespace-nowrap">{category.name}</span>
                        <button onClick={() => setEditCategory(category)} className="ml-2">
                            <svg width="512" height="512" viewBox="0 0 64 64" className="w-5 h-5 fill-current">
                                <path d="M11.105 43.597a2 2 0 0 1-1.414-3.414L40.945 8.929a2 2 0 1 1 2.828 2.828L12.519 43.011c-.39.39-.902.586-1.414.586z" />
                                <path d="M8.017 58a2 2 0 0 1-1.957-2.42l3.09-14.403a2 2 0 1 1 3.911.839l-3.09 14.403A2 2 0 0 1 8.017 58zM22.418 54.91a2 2 0 0 1-1.414-3.414l31.254-31.253a2 2 0 1 1 2.828 2.828L23.833 54.324a1.994 1.994 0 0 1-1.415.586z" />
                                <path d="M8.013 58a2.001 2.001 0 0 1-.418-3.956l14.403-3.09a2 2 0 0 1 .839 3.911l-14.403 3.09a1.958 1.958 0 0 1-.421.045zM48.015 29.313a1.99 1.99 0 0 1-1.414-.586L35.288 17.414a2 2 0 1 1 2.828-2.828l11.313 11.313a2 2 0 0 1-1.414 3.414zM53.672 23.657a2 2 0 0 1-1.415-3.415c1.113-1.113 1.726-2.62 1.726-4.242s-.613-3.129-1.726-4.242c-1.114-1.114-2.621-1.727-4.243-1.727s-3.129.613-4.242 1.727a2 2 0 1 1-2.829-2.829c1.868-1.869 4.379-2.898 7.071-2.898 2.691 0 5.203 1.029 7.071 2.898 1.869 1.868 2.898 4.379 2.898 7.071s-1.029 5.203-2.898 7.071a1.99 1.99 0 0 1-1.413.586z" />
                            </svg>
                        </button>
                    </div>

                    <div className="shrink-0">
                        <button
                            onClick={() => setItem({ id: 'new', shop_id: category.id })}
                            className="flex flex-row items-center text-white bg-blue-500 transition-colors hover:bg-blue-800 rounded-full py-2 px-6"
                        >
                            <svg className="w-5 h-5 fill-current stroke-current mr-2" viewBox="0 0 20 20">
                                <path d="M5 10H15" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M10 15L10 5" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            Добавить позицию
                        </button>
                    </div>
                </>
            )}
        </SubHeader>
    );
};
