export const BookingBlock = ({ el, handlerUpdateBooking, meetingRoom, selectMeetingRooms, tableWidth }) => {
    const top = el.start.split(':');
    const topCurrent = 24 + Number(top[0]) * 2 * 40 + Number(top[1] > 0 ? 40 : 0);
    const height = el.end.split(':');
    const heightEnd =
        (Number(height[0]) - Number(top[0])) * 80 + (Number(height[1]) - Number(top[1]) < 0 ? -40 : Number(height[1]) - Number(top[1]) === 0 ? 0 : 40);
    return (
        <div
            data-tip={el.comment}
            data-iscapture="true"
            onClick={() => handlerUpdateBooking(el)}
            className="absolute min-w-36 shrink-0 rounded-md flex justify-between h-full px-2 py-1 text-[10px] font-bold text-white cursor-pointer"
            style={{
                background: meetingRoom.color,
                top: topCurrent,
                height: heightEnd - 1,
                width: (tableWidth - 56) / selectMeetingRooms.filter(el => el.selected === '1').length - 2,
            }}
        >
            <span className="overflow-auto text-ellipsis h-full  mr-1">{el.fio}</span>
            <div className="flex flex-col">
                <span>{el.start}</span>
                <span>{el.end}</span>
            </div>
        </div>
    );
};
