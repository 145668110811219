export const SubHeader = ({ children, hiddenInMobile = false }) => {
    return (
        <div
            className={`w-full gap-4 py-4 md:py-0 md:h-20 shrink-0 bg-white border-b border-gray-10 ${
                hiddenInMobile ? 'hidden md:flex' : 'flex'
            } flex-col md:flex-row justify-center md:justify-between md:items-center px-4 sm:px-6 lg:px-8`}
        >
            {children}
        </div>
    );
};
