export const MeetingRoomColumn = ({ tableWidth, selectMeetingRooms, selectedDate, meetingRoom, getCurrentWorkTime }) => (
    <div className="bg-[#F8F8F8] min-w-36 shrink-0" style={{ width: (tableWidth - 56) / selectMeetingRooms.filter(el => el.selected === '1').length }}>
        <div className=" bg-white sticky top-0 h-6 z-[1] border-b border-r text-xs ">
            <p className="text-ellipsis overflow-hidden h-6 pt-1 text-center">{meetingRoom.label}</p>
        </div>
        {new Array(24).fill(0).map((_, idx) => {
            return (
                <div className="h-20 text-xs border-b border-b-[#e0e1e0] border-r" key={`${idx}_meeting`}>
                    <div
                        onClick={e => getCurrentWorkTime(selectedDate, meetingRoom, e, idx)}
                        className={`h-10  transition-colors  ${
                            meetingRoom.start <= idx && meetingRoom.end > idx ? 'cursor-pointer hover:bg-slate-200 hover:rounded' : 'bg-gray-200/60'
                        }`}
                        data-time={`${idx}:00`}
                    ></div>
                    <div
                        onClick={e => getCurrentWorkTime(selectedDate, meetingRoom, e, idx)}
                        className={`h-10 border-b transition-colors  ${
                            meetingRoom.start <= idx && meetingRoom.end > idx ? 'cursor-pointer hover:bg-slate-200 hover:rounded' : 'bg-gray-200/60'
                        }`}
                        data-time={`${idx}:30`}
                    ></div>
                </div>
            );
        })}
    </div>
);
