import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tabs as tabs_state } from '../../../../redux/slices/hotelSlice';
import { getUnreadMessagesCount } from '../../../../redux/slices/chatSlice';
import { SidebarMenuHotel } from './SidebarMenuHotel';
// import { SidebarMenuItem } from './SidebarMenuItem';
import { access_token as access_token_state } from '../../../../redux/slices/loginSlice';

export const SidebarMenu = () => {
    const dispatch = useDispatch();

    const tabs = useSelector(tabs_state);
    const access_token = useSelector(access_token_state);

    useEffect(() => {
        dispatch(getUnreadMessagesCount({ access_token }));

        const interval = setInterval(async () => {
            await dispatch(getUnreadMessagesCount({ access_token }));
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="space-y-8">
            <ul className="mt-3">
                {!!tabs.length && tabs.map(hotel => <SidebarMenuHotel hotel={hotel} key={`hotel${hotel.id}`} />)}
                {/* скрыта таблица участников программы лояльности, так как ее пока в отеле нет */}
                {/* <SidebarMenuItem title="Таблица участников" link="/loyalty-table/" type="bold" /> */}
            </ul>
        </div>
    );
};
