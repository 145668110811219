export const ToggleWithoutOnChange = ({ children, checked = false, onClick }) => {
    return (
        <label className="relative flex items-center cursor-pointer select-none">
            <input type="checkbox" className="sr-only peer" checked={checked} onChange={() => {}} onClick={e => onClick(e)} />
            <div className="w-11 h-6 flex flex-row justify-around items-center rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-0.5 after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all bg-gray-400 peer-checked:bg-blue-500  ">
                {children}
            </div>
        </label>
    );
};
