import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { ContentWrapper } from '../common/ContentWrapper';
import { Header } from './Header';

import TemplatePic_1 from '../../source/images/template_info/template_info.jpg';
import TemplatePic_2 from '../../source/images/template_info/template_info_1.jpeg';
import TemplatePic_3 from '../../source/images/template_info/template_info_2.jpg';

export const TemplatePage = ({ tab_info }) => {
    return (
        <>
            <Header tab={tab_info} />

            <ContentWrapper>
                <div className="">
                    {/* Title */}
                    <div className="flex items-center border-b bg-white w-full">
                        <p className="pb-6 pt-6 pl-6 font-medium">Выберите один из двух шаблонов</p>
                        <div className="ml-auto mr-6" data-tip="Выбор шаблонов">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                    stroke="#1890FF"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M11.9502 8H12.0502V8.1H11.9502V8Z" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>

                    {/* Templates */}
                    <div className="bg-white w-full">
                        <ul className="flex justify-around p-6">
                            <li className="mr-4 pb-2 drop-shadow w-1/3">
                                <div>
                                    <img src={TemplatePic_1} alt="template" className="" />
                                </div>
                                <NavLink
                                    to={`/object/${tab_info.hotel_id}/information/template`}
                                    className="mt-3 pt-2 pb-2 rounded-sm font-medium text-sm px-14 h-10 bg-blue-500 text-white cursor-pointer block w-44 ml-auto mr-auto"
                                >
                                    Выбрать
                                </NavLink>
                            </li>
                            {/* <li className="mr-4 pb-2 drop-shadow">
                                <div>
                                    <img src={TemplatePic_3} alt="template" className="" />
                                </div>
                                <NavLink
                                    to={`/object/${tab_info.hotel_id}/information/template_info_2`}
                                    className="mt-3 pt-2 pb-2 rounded-sm font-medium text-sm px-14 h-10 bg-blue-500 text-white cursor-pointer block w-44 ml-auto mr-auto"
                                >
                                    Выбрать
                                </NavLink>
                            </li> */}
                            <li className=" pb-2 drop-shadow w-1/3 ">
                                <div>
                                    <img src={TemplatePic_2} alt="template" className="" />
                                </div>
                                <NavLink
                                    to={`/object/${tab_info.hotel_id}/information/template_info_3`}
                                    className="mt-3 pt-2 pb-2 rounded-sm font-medium text-sm px-14 h-10 bg-blue-500 text-white cursor-pointer block w-44 ml-auto mr-auto"
                                >
                                    Выбрать
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </ContentWrapper>
            <ReactTooltip textColor="#FCFCFC" backgroundColor="#8c8d9d" effect="solid" className="!rounded-md !py-2 !px-2.5" />
        </>
    );
};
