import { NavLink } from 'react-router-dom';

export const SidebarHeaderLogo = () => {
    return (
        <NavLink to="/" className="ml-2 flex items-center h-full py-1">
            <>
                <svg width="456" height="698" viewBox="0 0 456 698" fill="none" className="h-full w-auto">
                    <path
                        d="M284.331 467.477C272.876 495.48 238.511 512.025 199.691 512.025C134.143 512.025 85.1416 467.477 85.1416 398.752V195.742H167.872V386.661C167.872 425.48 185.691 445.203 220.693 445.203C258.239 445.203 284.331 420.385 284.331 386.661V195.742H367.061V517.119C367.061 605.574 303.423 657.12 223.238 657.12C131.598 657.12 85.1416 606.85 85.1416 526.026H162.144C162.144 562.936 183.781 585.844 227.056 585.844C256.966 585.844 284.331 559.758 284.331 518.388V467.477Z"
                        fill="#8F906E"
                    />
                    <path d="M167.872 40.2544H85.1416V119.161H167.872V40.2544Z" fill="#8F906E" />
                    <path d="M367.063 40.2476H284.333V119.161H367.063V40.2476Z" fill="#8F906E" />
                </svg>
                <svg width="120" height="60" viewBox="0 0 41 12" fill="none">
                    <path
                        d="M4.03131 0.918026V1.84815H1.8294L1.90529 3.24332V9.22262L1.8294 10.6178H4.03131V11.5479H0.709473V0.918026H4.03131Z"
                        fill="#8F906E"
                    />
                    <path
                        d="M7.89404 1.91462H9.81124L10.7508 6.72655H11.1495L12.0891 1.91462H14.0062V11.2158H12.8104V7.65664L13.1331 2.84475H12.7345L11.7664 7.65664H10.1435L9.17536 2.84475H8.77675L9.09941 7.65664V11.2158H7.90354V1.91462H7.89404Z"
                        fill="#8F906E"
                    />
                    <path
                        d="M15.3921 1.91466H16.4551V4.57212L16.3791 5.96729H17.5466C17.5845 4.47721 17.7743 3.40472 18.135 2.75934C18.4862 2.10446 18.9986 1.78177 19.6535 1.78177C20.3559 1.78177 20.8778 2.15194 21.2385 2.90173C21.5897 3.64202 21.7701 4.86634 21.7701 6.56523C21.7701 8.26411 21.5897 9.48846 21.2385 10.2288C20.8873 10.9785 20.3559 11.3487 19.6535 11.3487C18.9512 11.3487 18.4672 11.007 18.116 10.3142C17.7648 9.62133 17.575 8.48241 17.5466 6.89742H16.3886L16.4646 8.29259V11.2158H15.4016V1.91466H15.3921ZM18.8088 9.45996C18.9986 10.0579 19.2739 10.3616 19.644 10.3616C20.0142 10.3616 20.2989 10.0579 20.4792 9.45996C20.6691 8.86203 20.7545 7.90346 20.7545 6.57473C20.7545 5.24599 20.6596 4.2779 20.4792 3.68946C20.2894 3.09153 20.0142 2.78781 19.644 2.78781C19.2739 2.78781 18.9891 3.08204 18.8088 3.68946C18.619 4.28739 18.5336 5.24599 18.5336 6.57473C18.5336 7.90346 18.6285 8.87152 18.8088 9.45996Z"
                        fill="#8F906E"
                    />
                    <path d="M23.2598 3.63252H25.2529V5.62563H23.2598V3.63252ZM23.2598 8.28309H25.2529V10.2762H23.2598V8.28309Z" fill="#8F906E" />
                    <path
                        d="M32.9598 9.7257C33.197 9.3081 33.3204 8.60576 33.3204 7.63768H34.5447C34.5447 8.90947 34.3075 9.84907 33.8329 10.4565C33.3584 11.0544 32.6465 11.3581 31.6879 11.3581C30.6629 11.3581 29.8847 10.988 29.3532 10.2382C28.8217 9.4979 28.5654 8.27358 28.5654 6.57469C28.5654 4.87581 28.8312 3.65146 29.3532 2.91116C29.8752 2.17087 30.6534 1.79124 31.6879 1.79124C32.6275 1.79124 33.3299 2.06649 33.8045 2.60747C34.279 3.14846 34.5163 3.93618 34.5163 4.95172H33.2919C33.2919 4.2304 33.1685 3.67044 32.9313 3.2908C32.694 2.91116 32.2764 2.72136 31.6784 2.72136C31.2229 2.72136 30.8622 2.84473 30.587 3.09149C30.3118 3.33826 30.1124 3.74637 29.9796 4.30634C29.8467 4.8663 29.7802 5.6256 29.7802 6.57469C29.7802 7.52379 29.8467 8.27357 29.9796 8.82405C30.1124 9.37452 30.3118 9.76365 30.587 10.0104C30.8622 10.2572 31.2229 10.3711 31.6784 10.3711C32.2859 10.3711 32.713 10.1623 32.9503 9.73517L32.9598 9.7257Z"
                        fill="#8F906E"
                    />
                    <path d="M37.4302 11.5574V10.6273H39.632L39.5561 9.23215V3.25285L39.632 1.85768H37.4302V0.927551H40.752V11.5574H37.4302Z" fill="#8F906E" />
                </svg>
            </>
        </NavLink>
    );
};
