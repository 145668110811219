import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api';
import { set_token_error } from './loginSlice';
import { getFileFromUrl, successNotification } from '../../api/functions';

export const foodSlice = createSlice({
    name: 'food',

    initialState: {
        food: [],
        food_fetching: false,
        edit_food_fetching: false,
        min_order_amount: null,
    },

    reducers: {
        set_food_fetching: (state, action) => {
            state.food_fetching = action.payload;
        },
        set_edit_food_fetching: (state, action) => {
            state.edit_food_fetching = action.payload;
        },
        set_food: (state, action) => {
            state.food = action.payload;
        },
        add_food: (state, action) => {
            state.food = state.food.map(cat => {
                if (cat.id === action.payload.category) {
                    if (cat.items) {
                        cat.items.unshift(action.payload);
                    } else {
                        cat.items = [action.payload];
                    }
                }
                return cat;
            });
        },
        update_food: (state, action) => {
            let item = null;
            for (const cat of state.food) {
                if (item) break;
                item = cat.items && cat.items.find(item => item.id === action.payload.id);
            }

            if (item.category === action.payload.category) {
                state.food = state.food.map(cat => {
                    if (cat.id === action.payload.category) {
                        return {
                            ...cat,
                            items: cat.items.map(el => {
                                if (el.id === action.payload.id) {
                                    return { ...el, ...action.payload };
                                }
                                return el;
                            }),
                        };
                    }
                    return cat;
                });
            } else {
                state.food = state.food.map(cat => {
                    if (cat.id === action.payload.category) {
                        return {
                            ...cat,
                            items: [{ ...item, ...action.payload }, ...cat.items],
                        };
                    } else if (cat.id === item.category) {
                        return {
                            ...cat,
                            items: cat.items.filter(el => el.id !== item.id),
                        };
                    }
                    return cat;
                });
            }
        },
        set_min_order_amount: (state, action) => {
            state.min_order_amount = action.payload;
        },
    },
});

export const { set_food_fetching, set_edit_food_fetching, set_food, add_food, update_food, set_min_order_amount } = foodSlice.actions;

export const getFood =
    ({ access_token, hotel_id }) =>
    async dispatch => {
        dispatch(set_food_fetching(true));

        try {
            if (access_token && hotel_id) {
                const response = await api.getFood.fetch(access_token, hotel_id);

                if (response.status === 200) {
                    const res = await response.json();
                    const food = [];
                    let min_amount;
                    res.map(el => (el.hasOwnProperty('food_min_price') ? (min_amount = el.food_min_price) : food.push(el)));
                    dispatch(set_food(food));
                    dispatch(set_min_order_amount(min_amount));
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                }
            }
        } catch (e) {
            console.log('getFood error: ', e);
        } finally {
            dispatch(set_food_fetching(false));
        }
    };

export const editFood =
    ({ access_token, data }) =>
    async dispatch => {
        dispatch(set_edit_food_fetching(true));
        try {
            if (access_token && data.id) {
                const files = data.hasOwnProperty('files') ? await Promise.all(data.files.map(async file => await getFileFromUrl(file))) : [];

                const response = await api.editFoodItem.fetch(access_token, { ...data, files });

                if (response.status === 200) {
                    const res = await response.json();
                    if (!res.error) {
                        if (data.hasOwnProperty('files')) {
                            dispatch(
                                update_food({
                                    ...data,
                                    img_path: data.files[0] || '',
                                })
                            );
                        } else {
                            dispatch(update_food(data));
                        }

                        return { status: 1 };
                    } else {
                        return { status: 0, error: res.error };
                    }
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));

                    return { status: 0 };
                }
            } else {
                return { status: 0, error: 'Что-то пошло не так. Обновите страницу и попробуйте еще раз' };
            }
        } catch (e) {
            console.log('editFood error: ', e);
            return { status: 0, error: e };
        } finally {
            dispatch(set_edit_food_fetching(false));
        }

        return { status: 0 };
    };

export const addFood =
    ({ access_token, data }) =>
    async dispatch => {
        dispatch(set_edit_food_fetching(true));
        try {
            if (access_token && data.hotel_id) {
                const files = await Promise.all(data.files.map(async file => await getFileFromUrl(file)));
                const response = await api.addFoodItem.fetch(access_token, { ...data, files });
                if (response.status === 200) {
                    const res = await response.json();
                    if (!res.error) {
                        dispatch(
                            add_food({
                                ...data,
                                id: res.id,
                                img_path: data.files[0] || '',
                            })
                        );
                        return { status: 1 };
                    } else {
                        return { status: 0, error: res.error };
                    }
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));

                    return { status: 0 };
                }
            }
        } catch (e) {
            console.log('addFood error: ', e);

            return { status: 0, error: e };
        } finally {
            dispatch(set_edit_food_fetching(false));
        }

        return { status: 0 };
    };

export const sortFood =
    ({ access_token, sortedFood, sortedData }) =>
    async dispatch => {
        dispatch(set_food(sortedFood));

        try {
            if (access_token && sortedData) {
                const sort_arr = sortedData.map(el => el.id);
                const response = await api.sortFood.fetch(access_token, JSON.stringify(sort_arr));
                if (response.status === 200) {
                    await response.json();
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                }
            }
        } catch (e) {
            console.log('sortFood error: ', e);
        }
    };

export const addMinOrderAmount =
    ({ access_token, data }) =>
    async dispatch => {
        try {
            const response = await api.addMinOrderAmount.fetch(access_token, data);
            if (response.status === 200) {
                const res = await response.json();
                if (res.code === 200) {
                    dispatch(set_min_order_amount(data.min_price));
                    successNotification();
                }
            }
        } catch (e) {
            console.log('addMinOrderAmount error: ', e);
        }
    };

export const food = state => state.food.food;
export const food_fetching = state => state.food.food_fetching;
export const min_order_amount = state => state.food.min_order_amount;

export default foodSlice.reducer;
