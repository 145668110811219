import { useEffect, useState, useRef } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { RotatingLines } from 'react-loader-spinner';

import { Header } from './Header';
import { ContentWrapper } from '../common/ContentWrapper';
import { SortablePicsLoader } from '../common/SortablePicsLoader';

import {
    editInformation,
    set_response_status,
    response_status as response_status_state,
    information_tabs as information_tabs_state,
    updateInfoFetch as updateInfoFetch_state,
} from '../../redux/slices/informationSlice';
import { access_token as access_token_state } from '../../redux/slices/loginSlice';
import { errorNotification, successNotification } from '../../api/functions';

const ContactSchema = Yup.object().shape({
    address: Yup.string().min(2, 'Поле "Адрес" слишком короткое').max(100, 'Поле "Адрес" слишком длинное').required('Поле "Адрес" обязательное'),
    address_en: Yup.string()
        .min(2, 'Поле "Адрес (на англ)" слишком короткое')
        .max(100, 'Поле "Адрес (на англ)" слишком длинное')
        .required('Поле "Адрес (на англ)" обязательное'),
    phone: Yup.string().min(3, 'Поле "Телефон" слишком короткое').max(30, 'Поле "Телефон" слишком длинное').required('Поле "Телефон" обязательное'),
    email: Yup.string()
        .email('Поле "Email" должно быть в формате example@domain.com')
        .min(2, 'Поле "Email" слишком короткое')
        .max(100, 'Поле "Email" слишком длинное')
        .required('Поле "Email" обязательное'),
});

export const HotelContacts = ({ tab_info }) => {
    const [initValues, setInitValues] = useState({ address: '', address_en: '', phone: '', email: '', files: [] });
    const [fetchAccessToken, setFetchAccessToken] = useState('');

    const formikRef = useRef();

    const dispatch = useDispatch();
    const access_token = useSelector(access_token_state);
    const response_status = useSelector(response_status_state);
    const information_tabs = useSelector(information_tabs_state);
    const updateInfoFetch = useSelector(updateInfoFetch_state);

    useEffect(() => {
        if (response_status) {
            successNotification();
            dispatch(set_response_status(false));
        }
    }, [response_status]);

    useEffect(() => {
        const tab = information_tabs?.find(el => el.id === tab_info.id);
        if (tab) {
            setInitValues({
                address: tab.address,
                address_en: tab.address_en,
                phone: tab.phone,
                email: tab.email,
                files: tab.files2 ? tab.files2 : [],
            });
        }
    }, [tab_info, information_tabs]);

    const formSubmit = async (values, { setSubmitting, resetForm }) => {
        setFetchAccessToken(access_token);

        let status;
        const data = {
            ...values,
            tab_item_id: tab_info.id,
            hotel_id: tab_info.hotel_id,
            section_link: tab_info.section_link,
        };

        status = await dispatch(editInformation({ access_token, data }));
        if (status === 200) {
            setSubmitting(false);
            resetForm({ values });
        }
    };
    useEffect(() => {
        (async () => {
            if (fetchAccessToken && fetchAccessToken !== access_token) {
                await formSubmit(formikRef.current.values, formikRef.current.setSubmitting);
            }
        })();
    }, [access_token]);

    const sortDispatch = data => {
        setInitValues(prevState => ({ ...prevState, files: data }));
    };

    return (
        <>
            <Header tab={tab_info} />

            <ContentWrapper>
                <Formik initialValues={initValues} validationSchema={ContactSchema} innerRef={formikRef} onSubmit={formSubmit} enableReinitialize={true}>
                    {({ values, errors, isSubmitting, isValidating, dirty, setFieldValue }) => {
                        if (isSubmitting && !isValidating) {
                            for (const err in errors) {
                                errorNotification(errors[err].toString());
                            }
                        }
                        return (
                            <Form>
                                <div className="sm:flex ">
                                    {/* main info */}
                                    <div className=" sm:w-1/2 bg-white">
                                        <div className="flex items-center border-b">
                                            <p className="pb-6 pt-6 pl-6 font-medium">Укажите основную информацию об отеле</p>
                                            <div className="ml-auto mr-6" data-tip="Контакты отеля">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path
                                                        d="M11.9502 8H12.0502V8.1H11.9502V8Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                        </div>

                                        {/* address */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-sm mb-2 text-gray-20 flex w-full">Адрес</p>
                                            <Field
                                                name="address"
                                                component="textarea"
                                                value={values.address}
                                                placeholder="Введите адрес отеля"
                                                className="box-border w-full h-8 sm:h-16 lg:h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2"
                                                disabled={isSubmitting}
                                            />
                                        </div>

                                        {/* address EN */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-sm mb-2 text-gray-20 flex w-full">Address</p>
                                            <Field
                                                name="address_en"
                                                component="textarea"
                                                value={values.address_en}
                                                placeholder="Enter the hotel address"
                                                className="box-border w-full h-8 sm:h-16 lg:h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2"
                                                disabled={isSubmitting}
                                            />
                                        </div>

                                        {/* phone */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-sm mb-2 text-gray-20 flex w-full">Телефон</p>
                                            <Field
                                                name="phone"
                                                component="textarea"
                                                value={values.phone}
                                                placeholder="Введите номер телефона отеля"
                                                className="box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2"
                                                disabled={isSubmitting}
                                            />
                                        </div>

                                        {/* email */}
                                        <div className="mt-3 pl-6 pr-6 mb-6">
                                            <p className="text-sm mb-2 text-gray-20 flex w-full">E-mail</p>

                                            <Field
                                                name="email"
                                                component="textarea"
                                                value={values.email}
                                                placeholder="Введите email отеля"
                                                className="box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2"
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    </div>

                                    {/* photo */}
                                    <div className="ml-0 pb-6 sm:w-1/2 bg-white sm:ml-6 ">
                                        <div className="flex items-center border-b">
                                            <p className="pb-6 pt-6 pl-6 font-medium">Загрузите фотографии</p>
                                            <div className="ml-auto mr-6" data-tip="Фото отеля">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path
                                                        d="M11.9502 8H12.0502V8.1H11.9502V8Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className=" ml-6 mt-6">
                                            <SortablePicsLoader setFieldValue={setFieldValue} files={values.files} sortDispatch={sortDispatch} />
                                        </div>
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className={` mt-3 rounded-sm font-medium text-sm px-14 h-10  ${
                                        dirty ? 'bg-blue-500 text-white cursor-pointer' : 'bg-slate-300 cursor-default text-gray-400'
                                    } `}
                                    disabled={(!isSubmitting && !dirty) || updateInfoFetch}
                                >
                                    {updateInfoFetch ? <RotatingLines width="20" strokeColor="white" /> : 'Сохранить'}
                                </button>
                            </Form>
                        );
                    }}
                </Formik>
            </ContentWrapper>

            <ReactTooltip textColor="#FCFCFC" backgroundColor="#8c8d9d" effect="solid" className="!rounded-md !py-2 !px-2.5" />
        </>
    );
};
