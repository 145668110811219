import { Field } from 'formik';

import { Activity } from './Activity';
import { Icons } from './Icons';
import { Photo } from './Photo';
import { Sort } from './Sort';

export const MainDetailAccordion = ({ values, isSubmitting, dataSvg, handleSvgClick, idSvg, setFieldValue, initValues, sortDispatch }) => (
    <div className=" sm:w-full bg-white">
        <div className="flex items-center border-b">
            <p className="pb-6 pt-6 pl-6 font-medium">Укажите основную информацию</p>
            <div className="ml-auto mr-6" data-tip="Информация">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                        stroke="#1890FF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.9502 8H12.0502V8.1H11.9502V8Z" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
        </div>

        <div className="flex pt-3">
            {/* name */}
            <div className="pl-6 pr-6 w-1/2">
                <p className="text-sm mb-2 text-gray-20 flex w-full">Название раздела</p>
                <Field
                    name="name"
                    component="textarea"
                    value={values.name}
                    placeholder="Введите название раздела"
                    className="box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2"
                    disabled={isSubmitting}
                />
            </div>

            {/* name EN */}
            <div className=" pl-6 pr-6 w-1/2">
                <p className="text-sm mb-2 text-gray-20 flex w-full">Section name</p>
                <Field
                    name="name_en"
                    component="textarea"
                    value={values.name_en}
                    placeholder="Enter section name"
                    className="box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2"
                    disabled={isSubmitting}
                />
            </div>
        </div>

        <div className="flex mt-3 ">
            {/* activity */}
            <div className="w-1/2">
                <Activity />
            </div>

            {/* sort */}
            <div className=" flex items-center w-1/2">
                <Sort values={values} isSubmitting={isSubmitting} />
            </div>
        </div>

        <div className="flex border-b pb-6">
            {/* icon */}
            <div className="sm:w-1/2">
                <Icons dataSvg={dataSvg} handleSvgClick={handleSvgClick} idSvg={idSvg} setFieldValue={setFieldValue} currentSvg={initValues?.svg_current} />
            </div>

            {/* photo */}
            <div className="sm:w-1/2 ">
                <Photo setFieldValue={setFieldValue} files={values.files} sortDispatch={sortDispatch} />
            </div>
        </div>
    </div>
);
