import { useDispatch, useSelector } from 'react-redux';

import { SubHeader } from '../common/SubHeader';
import { addMinOrderAmount, min_order_amount as min_order_amount_state } from '../../redux/slices/foodSlice';
import { access_token as access_token_state } from '../../redux/slices/loginSlice';

export const Header = ({ tab, setDetailId }) => {
    const dispatch = useDispatch();
    const access_token = useSelector(access_token_state);
    const min_order_amount = useSelector(min_order_amount_state);

    const handlerMinAmountSet = e => {
        if (e.target.value) {
            const data = {
                hotel_id: tab.hotel_id,
                min_price: e.target.value,
            };
            dispatch(addMinOrderAmount({ access_token, data }));
        }
    };
    return (
        <SubHeader>
            <div className="uppercase text-sm text-gray w-full flex flex-row text-xl font-semibold items-center text-gray-500">
                {tab?.hotel_name}
                <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                    <path d="M9.70711 4.29289C9.31658 3.90237 8.68342 3.90237 8.29289 4.29289C7.90237 4.68342 7.90237 5.31658 8.29289 5.70711L9.70711 4.29289ZM16 12L16.7071 12.7071C17.0976 12.3166 17.0976 11.6834 16.7071 11.2929L16 12ZM8.29289 18.2929C7.90237 18.6834 7.90237 19.3166 8.29289 19.7071C8.68342 20.0976 9.31658 20.0976 9.70711 19.7071L8.29289 18.2929ZM8.29289 5.70711L15.2929 12.7071L16.7071 11.2929L9.70711 4.29289L8.29289 5.70711ZM15.2929 11.2929L8.29289 18.2929L9.70711 19.7071L16.7071 12.7071L15.2929 11.2929Z" />
                </svg>
                Магазин
                <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                    <path d="M9.70711 4.29289C9.31658 3.90237 8.68342 3.90237 8.29289 4.29289C7.90237 4.68342 7.90237 5.31658 8.29289 5.70711L9.70711 4.29289ZM16 12L16.7071 12.7071C17.0976 12.3166 17.0976 11.6834 16.7071 11.2929L16 12ZM8.29289 18.2929C7.90237 18.6834 7.90237 19.3166 8.29289 19.7071C8.68342 20.0976 9.31658 20.0976 9.70711 19.7071L8.29289 18.2929ZM8.29289 5.70711L15.2929 12.7071L16.7071 11.2929L9.70711 4.29289L8.29289 5.70711ZM15.2929 11.2929L8.29289 18.2929L9.70711 19.7071L16.7071 12.7071L15.2929 11.2929Z" />
                </svg>
                <span className="text-black">{tab?.name}</span>
            </div>

            <div className="shrink-0 flex items-center">
                <label className="text-sm mr-6 ">
                    Минимальная сумма заказа(руб):
                    <input type="number" defaultValue={min_order_amount} className="w-20 h-8 ml-4 text-xs p-2" onBlur={e => handlerMinAmountSet(e)} />
                </label>
                <button
                    className="flex flex-row items-center text-white bg-blue-500 transition-colors hover:bg-blue-800 rounded-full py-2 px-6"
                    onClick={() => setDetailId('new')}
                >
                    <svg className="w-5 h-5 fill-current stroke-current mr-2" viewBox="0 0 20 20">
                        <path d="M5 10H15" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M10 15L10 5" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Добавить позицию
                </button>
            </div>
        </SubHeader>
    );
};
