import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BallTriangle } from 'react-loader-spinner';

import { listenForOutsideClick } from '../../api/functions';
import { editMerch, getMerch, merch as merch_state, merch_fetching as merch_fetching_state, sortMerch } from '../../redux/slices/merchSlice';
import { access_token as access_token_state } from '../../redux/slices/loginSlice';
import { ContentWrapper } from '../common/ContentWrapper';
import { SortableWrapper } from '../common/sortable/SortableWrapper';
import { SortableItem } from '../common/sortable/SortableItem';
import NoMerch from '../../source/images/no-photo-mys.png';

export const List = ({ tab, setDetailId }) => {
    const dispatch = useDispatch();
    const editBlockRef = useRef(null);
    const access_token = useSelector(access_token_state);
    const merch_fetching = useSelector(merch_fetching_state);
    const merch = useSelector(merch_state);

    const [editBlockIsOpen, setEditBlockIsOpen] = useState(null);
    const [listening, setListening] = useState(false);
    const [fetchData, setFetchData] = useState({ type: '', data: null });
    const [fetchAccessToken, setFetchAccessToken] = useState('');

    useEffect(listenForOutsideClick(listening, setListening, editBlockRef, setEditBlockIsOpen));

    useEffect(() => {
        if (tab && access_token) {
            dispatch(getMerch({ access_token, hotel_id: tab.hotel_id, tab_id: tab.id }));
        }
    }, [tab]);

    const editStopListHandler = async ({ id, active }) => {
        let res;
        if (access_token) {
            setFetchAccessToken(access_token);
            setFetchData({ type: 'edit', data: { id, active } });
            res = await dispatch(editMerch({ access_token, data: { id, active } }));
        }

        if (res.status) {
            setEditBlockIsOpen(false);
        }
    };

    const sortDispatch = data => {
        if (access_token) {
            setFetchAccessToken(access_token);
            setFetchData({ type: 'sort', data });
            dispatch(sortMerch({ access_token, data }));
        }
    };

    useEffect(() => {
        (async () => {
            if (fetchAccessToken && fetchAccessToken !== access_token) {
                if (fetchData.type === 'sort') {
                    sortDispatch(fetchData.data);
                } else if (fetchData.type === 'edit') {
                    await editStopListHandler(fetchData.data);
                }
            }
        })();
    }, [access_token]);

    if (merch_fetching) {
        return (
            <div className="w-full flex justify-center mt-8">
                <BallTriangle height="50" width="100" color="#3b82f6" ariaLabel="loading" />
            </div>
        );
    }

    if (merch && merch.length) {
        return (
            <ContentWrapper>
                <div className="flex flex-wrap gap-6">
                    <SortableWrapper items={merch} sortDispatch={sortDispatch}>
                        {merch.map(item => (
                            <SortableItem key={`item${item.id}`} id={item.id}>
                                <div className="w-64 h-72 flex flex-col bg-white rounded-2 drop-shadow overflow-hidden relative">
                                    {item.active === '0' && (
                                        <div className="w-full h-full absolute flex items-center justify-center bg-white bg-opacity-50">
                                            <div className="w-2/3 bg-green-500 text-white text-center py-2 text-sm">В стоп-листе</div>
                                        </div>
                                    )}
                                    <div className="absolute right-0 top-0" ref={editBlockIsOpen === item.id ? editBlockRef : null}>
                                        <button
                                            className="text-white flex items-center justify-center bg-gray-600 w-11 h-11 rounded-bl-2 transition-colors hover:bg-gray-800"
                                            onClick={() => (editBlockIsOpen === item.id ? setEditBlockIsOpen(null) : setEditBlockIsOpen(item.id))}
                                        >
                                            <svg className="w-6 h-6 fill-current stroke-current" viewBox="0 0 24 24">
                                                <path
                                                    d="M17 12C17 11.4477 17.4477 11 18 11C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13C17.4477 13 17 12.5523 17 12Z"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12Z"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M5 12C5 11.4477 5.44772 11 6 11C6.55228 11 7 11.4477 7 12C7 12.5523 6.55228 13 6 13C5.44772 13 5 12.5523 5 12Z"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </button>

                                        <div
                                            className={`absolute right-0 transition-opacity drop-shadow-2xl overflow-hidden rounded-lg bg-white  ${
                                                editBlockIsOpen === item.id ? 'opacity-100 showed' : 'hidden opacity-0'
                                            }`}
                                        >
                                            <button
                                                className="w-full flex flex-row items-center text-left p-3 hover:bg-gray-50 text-black font-normal border-0 cursor-pointer whitespace-nowrap text-sm"
                                                type="button"
                                                onClick={async () => {
                                                    await editStopListHandler({
                                                        id: item.id,
                                                        active: item.active === '0' ? '1' : '0',
                                                    });
                                                }}
                                            >
                                                <svg className="w-4 h-4 fill-transparent stroke-current mr-2" viewBox="0 0 16 16">
                                                    <path
                                                        d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
                                                        strokeOpacity="0.85"
                                                        strokeWidth="1.33333"
                                                        strokeMiterlimit="10"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M3.83325 3.8335L12.1666 12.1668"
                                                        stroke="black"
                                                        strokeOpacity="0.85"
                                                        strokeWidth="1.33333"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                                Стоп-лист
                                            </button>
                                        </div>
                                    </div>
                                    <div className="w-full h-36 mb-3 shrink-0">
                                        {item.img_path ? (
                                            <img
                                                className="w-full h-full object-cover"
                                                src={item.files2 ? item.files2[0].url : item.img_path}
                                                alt={item.name}
                                            />
                                        ) : (
                                            <img src={NoMerch} alt="no-merch" className="w-full h-full object-cover" />
                                        )}
                                    </div>
                                    <div className="w-full h-full px-3 pb-3 text-base flex flex-col z-10">
                                        <div className="w-full font-semibold text-gray-800 mb-3">{item.name}</div>
                                        <div className="w-full font-normal text-gray-500">
                                            {item.price}₽ {item.size}
                                        </div>

                                        <button
                                            className="w-full h-8 mt-auto border border-blue-500 bg-white text-blue-500 rounded text-sm transition-colors hover:bg-blue-500 hover:text-white"
                                            onClick={() => setDetailId(item.id)}
                                        >
                                            Редактировать
                                        </button>
                                    </div>
                                </div>
                            </SortableItem>
                        ))}
                    </SortableWrapper>
                </div>
            </ContentWrapper>
        );
    }
};
