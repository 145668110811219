import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { unread_messages_counter as unread_messages_counter_state } from '../../../../redux/slices/chatSlice';
import { SidebarMenuLinkGroupButton } from './SidebarMenuLinkGroupButton';
import { SidebarMenuItem } from './SidebarMenuItem';

export const SidebarMenuHotelTab = ({ hotel, tab }) => {
    const { pathname } = useLocation();

    const unread_messages_counter = useSelector(unread_messages_counter_state);

    const [isOpen, setIsOpen] = useState(pathname.includes(`object/${hotel.id}/${tab.link}`));

    if (tab.subitems) {
        const active = tab.subitems.find(el => +el.active2 !== 0);
        if (active) {
            return (
                <li className="mb-2 last:mb-0">
                    <SidebarMenuLinkGroupButton
                        icon={tab.link}
                        title={tab.name}
                        url={`object/${hotel.id}/${tab.link}/`}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                    />

                    <div className="bg-slate-50">
                        <ul className={`mt-1 ${!isOpen && 'hidden'}`}>
                            {tab.subitems
                                .filter(
                                    tab_lv_2 => tab_lv_2.link !== 'information_guest_book' && tab_lv_2.preview_name !== 'Пиццерия' && +tab_lv_2.active2 !== 0
                                )
                                .map(
                                    tab_lv_2 =>
                                        !Array.isArray(tab_lv_2) && (
                                            <SidebarMenuItem
                                                title={tab_lv_2.name}
                                                link={`/object/${tab_lv_2.hotel_id || tab.hotel_id}/${tab.link}/${
                                                    tab_lv_2.link ? tab_lv_2.link : tab_lv_2.id
                                                }/`}
                                                disabled={tab_lv_2.active === '0'}
                                                key={`tab${tab_lv_2.id}`}
                                                style="ml-8"
                                            />
                                        )
                                )}

                            {tab.link === 'information' && (
                                <SidebarMenuItem
                                    title="Добавить новый раздел"
                                    icon="add"
                                    link={`/object/${tab.hotel_id}/${tab.link}/template_info`}
                                    style="ml-7"
                                />
                            )}

                            {/* шаблон создания переговорки */}
                            {tab.link === 'meeting' && (
                                <>
                                    <SidebarMenuItem
                                        title="Добавить новое помещение"
                                        icon="add"
                                        link={`/object/${tab.hotel_id}/${tab.link}/template_meeting_room`}
                                        style="ml-7"
                                    />

                                    <SidebarMenuItem
                                        title="Календарь бронирования"
                                        icon="calendar"
                                        link={`/object/${tab.hotel_id}/${tab.link}/booking_calendar`}
                                        style="ml-7"
                                    />

                                    <SidebarMenuItem
                                        title="История бронирования"
                                        icon="history"
                                        link={`/object/${tab.hotel_id}/${tab.link}/booking_history`}
                                        style="ml-7"
                                    />
                                </>
                            )}
                        </ul>
                    </div>
                </li>
            );
        } else {
            return null;
        }
    }

    return (
        <SidebarMenuItem
            icon="chat"
            title={tab.name}
            link={`/object/${tab.hotel_id}/${tab.link}/`}
            newMessages={
                tab.name === 'чат' &&
                unread_messages_counter.find(el => +el.hotel_id === +tab.hotel_id) &&
                unread_messages_counter.find(el => +el.hotel_id === +tab.hotel_id).count
            }
        />
    );
};
