import { Field } from 'formik';

export const Sort = ({ values, isSubmitting }) => (
    <>
        <p className="pb-6 pt-6 pl-6 font-medium mr-4">Укажите сортировку</p>

        <Field
            name="sort"
            component="textarea"
            value={values.sort}
            placeholder="№"
            className="box-border w-12 h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2 mr-5"
            disabled={isSubmitting}
        />

        <div className="ml-auto mr-6" data-tip="Порядковый номер подраздела в разделе Информация">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                    d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                    stroke="#1890FF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.9502 8H12.0502V8.1H11.9502V8Z" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
    </>
);
