import { SubHeader } from '../common/SubHeader';

export const Header = ({ tab }) => {
    if (!tab) {
        return null;
    }
    return (
        <SubHeader>
            <div className="uppercase text-xs sm:text-sm text-gray w-full flex flex-row lg:text-xl font-semibold items-center text-gray-500">
                {tab.hotel_name}
                <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                    <path d="M9.70711 4.29289C9.31658 3.90237 8.68342 3.90237 8.29289 4.29289C7.90237 4.68342 7.90237 5.31658 8.29289 5.70711L9.70711 4.29289ZM16 12L16.7071 12.7071C17.0976 12.3166 17.0976 11.6834 16.7071 11.2929L16 12ZM8.29289 18.2929C7.90237 18.6834 7.90237 19.3166 8.29289 19.7071C8.68342 20.0976 9.31658 20.0976 9.70711 19.7071L8.29289 18.2929ZM8.29289 5.70711L15.2929 12.7071L16.7071 11.2929L9.70711 4.29289L8.29289 5.70711ZM15.2929 11.2929L8.29289 18.2929L9.70711 19.7071L16.7071 12.7071L15.2929 11.2929Z" />
                </svg>

                <span className="text-black"> Уведомления</span>
            </div>
        </SubHeader>
    );
};
