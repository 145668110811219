import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';

import { Popup } from '../../common/popup/Popup';
import { successNotification, errorNotification } from '../../../api/functions';
import { updateMachine } from '../../../redux/slices/informationSlice';

const initialValues = {
    price: '',
    type: '',
    working_status: {
        1: '',
        0: '',
    },
};
export const ChangePopup = ({ machine, isOpen, setIsOpen }) => {
    const dispatch = useDispatch();

    const [formValues, setFormValues] = useState(initialValues);

    const type_options = [
        { value: 1, label: 'Стиральная машина' },
        { value: 2, label: 'Сушильная машина' },
    ];

    useEffect(() => {
        const working_status = machine.working_status === '0' ? { 0: true, 1: false } : { 0: false, 1: true };
        const type = type_options.filter(el => el.label === machine.type)[0];
        setFormValues({ price: machine.price, type, working_status });
    }, [machine]);

    const handlerSubmit = async () => {
        const working_status = formValues.working_status[0] === true ? '0' : '1';
        const data = { ...formValues, working_status, type: formValues.type.label, id: machine.id };

        const status = await dispatch(updateMachine({ data }));

        if (status === 'ok') {
            successNotification('Информация сохранена');
            setIsOpen(false);
            setFormValues(initialValues);
        } else {
            errorNotification(status);
        }
    };

    const handleChange = e => {
        let { value, max } = e.target;
        const numberValue = Number(value);
        const numberMax = Number(max);

        if (numberValue <= 0) {
            errorNotification('Введите целое, большее нуля число');
            setFormValues({ ...formValues, price: value });
        } else if (!Number.isInteger(numberValue)) {
            errorNotification('Введите целое число');
        } else if (numberValue <= numberMax) {
            setFormValues({ ...formValues, price: value });
        } else {
            errorNotification('Поле стоимость принимает значения меньше 999 999');
        }
    };
    return (
        <Popup isOpen={isOpen} setIsOpen={setIsOpen}>
            <div>
                <label className="flex flex-col mt-2 relative">
                    <span className="text-xs font-medium">Стоимость</span>
                    <input
                        type="number"
                        max="999999"
                        value={formValues.price}
                        className="h-9 mt-2 text-xs rounded border-gray-300 px-3 py-1.5"
                        onChange={e => handleChange(e)}
                    />
                    <span className="absolute text-xs text-gray-400 right-1.5 bottom-2">₽/час</span>
                </label>
                <label className="flex flex-col mt-6 relative">
                    <span className="text-xs font-medium">Тип машины</span>
                    <Select
                        name="type"
                        value={formValues.type}
                        options={type_options}
                        onChange={val => setFormValues({ ...formValues, type: val })}
                        placeholder="Выберите тип машинки"
                        className=" mt-2 text-xs rounded border-gray-300"
                        isSearchable={false}
                    />
                </label>
                <div className="flex flex-col mt-6">
                    <span className="text-xs mb-3 font-medium">Статус работы</span>
                    <label className="w-full bg-gray-100 p-4 rounded-tr rounded-tl border-b border-b-gray-200">
                        <input
                            type="checkbox"
                            name="working_status"
                            className="mr-3"
                            checked={formValues.working_status[1]}
                            onChange={e => setFormValues({ ...formValues, working_status: { 1: e.target.checked, 0: !e.target.checked } })}
                        />
                        <span className="text-sm">Работает</span>
                    </label>
                    <label className="w-full bg-gray-100 p-4 rounded-br rounded-bl">
                        <input
                            type="checkbox"
                            checked={formValues.working_status[0]}
                            name="working_status"
                            className="mr-3"
                            onChange={e => setFormValues({ ...formValues, working_status: { 0: e.target.checked, 1: !e.target.checked } })}
                        />
                        <span className="text-sm">Не работает</span>
                    </label>
                </div>

                <div className="mt-6">
                    <button
                        disabled={Number(formValues.price) <= 0}
                        className="text-sm text-white transition-colors bg-blue-400 hover:bg-blue-600 px-2 py-1 mr-2 rounded"
                        type="button"
                        onClick={() => {
                            handlerSubmit();
                        }}
                    >
                        Сохранить изменения
                    </button>
                    <button
                        className="text-sm text-gray-500 transition-colors hover:bg-gray-100 px-2 py-1 rounded"
                        type="button"
                        onClick={() => {
                            setIsOpen(false);
                            setFormValues(initialValues);
                        }}
                    >
                        Отменить
                    </button>
                </div>
            </div>
        </Popup>
    );
};
