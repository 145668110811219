import * as Yup from 'yup';

export const AccordionSchema = Yup.object().shape({
    name: Yup.string().min(3, 'Поле "Название" слишком короткое').max(45, 'Поле "Название" слишком длинное').required('Поле "Название" обязательное'),
    name_en: Yup.string()
        .min(3, 'Поле "Название (на англ)" слишком короткое')
        .max(45, 'Поле "Название (на англ)" слишком длинное')
        .required('Поле "Название (на англ)" обязательное'),
    sort: Yup.number().typeError('В поле "Сортировка" для ввода доступны только числа').positive('В поле сортировки должно быть положительное число'),
    files: Yup.array().min(1, 'Добавьте фото раздела').required('Добавьте фото раздела'),
    svg_current: Yup.string().required('Выберите иконку для раздела').nullable(),
    json: Yup.array().of(
        Yup.object().shape({
            sectionName: Yup.string()
                .min(2, 'Поле Название секции слишком короткое')
                .max(90, 'Поле Название секции слишком длинное')
                .required('Поле Название секции обязательное'),
            sectionName_en: Yup.string()
                .min(2, 'Поле Название секции слишком короткое')
                .max(90, 'Поле Название секции на англ слишком длинное')
                .required('Поле Название секции на англ обязательное'),
            isTable: Yup.boolean(),
            isDesc: Yup.boolean(),
            sectionDesc: Yup.array().when('isDesc', {
                is: true,
                then: Yup.array(Yup.string().required('Поля Описания обязательны на обоих языках').min(12, 'Поле Описание слишком короткое')),
                otherwise: Yup.array(),
            }),
            sectionTable: Yup.object().shape({
                isTable: Yup.boolean(),
                head: Yup.object().shape({
                    isTable: Yup.boolean(),
                    first: Yup.array().when('isTable', {
                        is: true,
                        then: Yup.array(
                            Yup.string()
                                .required('Поля Название первого столбца обязательны на обоих языках')
                                .min(2, 'Поле Название первого столбца слишком короткое')
                        ),
                        otherwise: Yup.array(),
                    }),
                    second: Yup.array().when('isTable', {
                        is: true,
                        then: Yup.array(
                            Yup.string()
                                .required('Поля Название второго столбца обязательны на обоих языках')
                                .min(2, 'Поле Название второго столбца слишком короткое')
                        ),
                        otherwise: Yup.array(),
                    }),
                }),
                body: Yup.array().when('isTable', {
                    is: true,
                    then: Yup.array().of(
                        Yup.object().shape({
                            cellFirst: Yup.string()
                                .min(2, 'Поле Контент ячейки первого столбца короткое')
                                .required('Поле Контент ячейки первого столбца обязательное')
                                .max(100, 'Поле Контент ячейки первого столбца слишком большое'),
                            cellFirst_en: Yup.string()
                                .min(2, 'Поле Контент ячейки первого столбца на англ короткое')
                                .required('Поле Контент ячейки первого столбца на англ обязательное')
                                .max(100, 'Поле Контент ячейки первого столбца на англ слишком большое'),
                            cellSecond: Yup.string()
                                .min(2, 'Поле Контент ячейки второго столбца короткое')
                                .required('Поле Контент ячейки второго столбца обязательное')
                                .max(100, 'Поле Контент ячейки второго столбца слишком большое'),
                            cellSecond_en: Yup.string()
                                .min(2, 'Поле Контент ячейки второго столбца на англ короткое')
                                .required('Поле Контент ячейки второго столбца на англ обязательное')
                                .max(100, 'Поле Контент ячейки второго столбца на англ слишком большое'),
                        })
                    ),

                    otherwise: Yup.array(),
                }),
            }),
        })
    ),
});
