import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api';
import { set_token_error } from './loginSlice';
import { upd_loyalty } from './hotelSlice';
import { add_section, set_sort_info_sections, update_hotel_tab, update_manage_info } from './hotelSlice';
import { errorNotification, getFileFromUrl } from '../../api/functions';

export const informationSlice = createSlice({
    name: 'information',

    initialState: {
        info_fetch: false,
        update_info_fetch: false,
        response_status: false,
        url_info_pic: '',
        tab_info: {},
        information_tabs: [],
        hotel_info_pics: [],
        icons: [],
        machines: [],
        machines_fetching: false,
    },

    reducers: {
        set_info_fetch_start: state => {
            state.info_fetch = true;
        },
        set_info_fetch_end: state => {
            state.info_fetch = false;
        },
        set_update_info_fetch: (state, action) => {
            state.update_info_fetch = action.payload;
        },
        set_response_status: (state, action) => {
            state.response_status = action.payload;
        },
        set_url_info_pic: (state, action) => {
            state.url_info_pic = action.payload;
        },
        set_info_tab: (state, action) => {
            state.information_tabs = action.payload;
        },
        upd_info_tab: (state, action) => {
            state.information_tabs = state.information_tabs.map(el => {
                if (el.id === action.payload.tab_item_id) {
                    return { ...el, ...action.payload };
                }
                return el;
            });
        },
        upd_info_sub_tab: (state, action) => {
            state.information_tabs = state.information_tabs.map(el_tab => {
                if (el_tab.id === action.payload.sub_tab_id) {
                    const tab_subitems = el_tab.subitems.map(el => {
                        if (el.id === action.payload.tab_item_id) {
                            return { ...el, ...action.payload };
                        }
                        return el;
                    });
                    const subitems = { subitems: tab_subitems };
                    return { ...el_tab, ...subitems };
                }
                return el_tab;
            });
        },
        set_sort_photo: (state, action) => {
            state.information_tabs = state.information_tabs.map(el_tab => {
                if (el_tab.id === action.payload.sub_tab_id) {
                    const tab_subitems = el_tab.subitems.map(el => {
                        if (el.id === action.payload.tab_item_id) {
                            return { ...el, files2: action.payload.files2 };
                        }
                        return el;
                    });
                    const subitems = { subitems: tab_subitems };
                    return { ...el_tab, ...subitems };
                }
                return el_tab;
            });
        },
        set_hotel_info_pics: (state, action) => {
            state.hotel_info_pics = action.payload;
        },
        delete_hotel_info_pics: (state, action) => {
            state.hotel_info_pics = state.hotel_info_pics.filter(el => el.url !== action.payload);
        },
        set_tab_info: (state, action) => {
            state.tab_info = action.payload;
        },
        add_new_section: (state, action) => {
            state.information_tabs.push(action.payload);
        },
        set_icons: (state, action) => {
            state.icons = action.payload;
        },
        set_machines: (state, action) => {
            state.machines = action.payload;
        },
        set_machines_fetching: (state, action) => {
            state.machines_fetching = action.payload;
        },
        set_start_machine: (state, action) => {
            state.machines = state.machines.map(el => (el.id === action.payload.id ? { ...el, on_off_status: '1', stage_status: '1' } : el));
        },
        set_end_machine: (state, action) => {
            state.machines = state.machines.map(el => (el.id === action.payload.id ? { ...el, on_off_status: '0', stage_status: '0' } : el));
        },
        change_machine_info: (state, action) => {
            state.machines = state.machines.map(el => (el.id === action.payload.id ? { ...el, ...action.payload } : el));
        },
        create_room: (state, action) => {
            state.information_tabs = state.information_tabs.map(el =>
                el.id === action.payload.parent_id ? { ...el, subitems: el.subitems ? [...el.subitems, action.payload] : [action.payload] } : el
            );
        },
    },
});

export const {
    create_room,
    change_machine_info,
    set_end_machine,
    set_start_machine,
    set_machines_fetching,
    set_machines,
    set_icons,
    add_new_section,
    set_tab_info,
    set_sort_photo,
    set_update_info_fetch,
    upd_info_sub_tab,
    upd_info_tab,
    set_info_tab,
    set_url_info_pic,
    set_response_status,
    set_info_fetch_start,
    set_info_fetch_end,
    set_hotel_info_pics,
    delete_hotel_info_pics,
} = informationSlice.actions;

export const getHotelInformationFetch =
    ({ access_token, hotel_id }) =>
    async dispatch => {
        await dispatch(set_info_fetch_start());
        try {
            if (access_token) {
                const response = await api.getHotelInformation.fetch(access_token, hotel_id);
                if (response.status === 200) {
                    const res = await response.json();
                    const data = res[0].subitems.find(el => el.link === 'information');
                    await dispatch(set_info_tab(data.subitems));
                    await dispatch(
                        set_tab_info({
                            hotel_name: res[0].Name,
                            name: 'информация',
                            hotel_id: res[0].id,
                            link: data.link,
                        })
                    );
                } else if (response.status === 401) {
                    await dispatch(set_token_error(true));
                }
                return response.status;
            }
        } catch (e) {
            console.log('getHotelInformationFetch error: ', e);
        } finally {
            await dispatch(set_info_fetch_end());
        }
        return 0;
    };

export const editInformation =
    ({ access_token, data }) =>
    async dispatch => {
        await dispatch(set_update_info_fetch(true));
        try {
            if (access_token) {
                const files =
                    data.hasOwnProperty('files') && data.files
                        ? await Promise.all(data.files.map(async file => await getFileFromUrl(file.url, `${Date.now()}.jpg`)))
                        : [];
                const allSize = files.reduce((acc, cur) => acc + cur.size, 0);
                if (allSize > 20971520) {
                    return errorNotification('Общий объем всех фото не должен превышать 20МБ');
                }
                const response = await api.editInformationItem.fetch(access_token, { ...data, files });

                if (response.status === 200) {
                    const res = await response.json();
                    if (res.message === 'ok') {
                        const files = res.files.map((file, id) => ({ id: id + 1, url: file }));

                        await dispatch(upd_info_tab({ ...data, files2: files }));
                        await dispatch(update_hotel_tab({ ...data, files2: files }));
                        await dispatch(set_sort_info_sections({ hotel_id: data.hotel_id }));
                        await dispatch(set_response_status(true));
                    }
                } else if (response.status === 401) {
                    await dispatch(set_token_error(true));
                }
                return response.status;
            }
        } catch (e) {
            console.log('editInformation error: ', e);
        } finally {
            await dispatch(set_update_info_fetch(false));
        }
        return 0;
    };

export const editSubInformation =
    ({ access_token, data }) =>
    async dispatch => {
        await dispatch(set_update_info_fetch(true));
        try {
            if (access_token) {
                let files = [];
                if (data.hasOwnProperty('files')) {
                    files = data.files.length ? await Promise.all(data.files.map(async file => await getFileFromUrl(file.url, `${Date.now()}.jpg`))) : [];
                }
                const allSize = files.reduce((acc, cur) => acc + cur.size, 0);
                if (allSize > 20971520) {
                    return errorNotification('Общий объем всех фото не должен превышать 20МБ');
                }
                const response = await api.editInformationItem.fetch(access_token, { ...data, files });

                if (response.status === 200) {
                    const res = await response.json();
                    if (res.message === 'ok') {
                        const files = res.files.map((file, id) => ({ id: id + 1, url: file }));

                        await dispatch(set_response_status(true));
                        await dispatch(upd_info_sub_tab({ ...data, files2: files, files: res.files }));
                    }
                } else if (response.status === 401) {
                    await dispatch(set_token_error(true));
                }
                return response.status;
            }
        } catch (e) {
            console.log('editSubInformation error: ', e);
        } finally {
            await dispatch(set_update_info_fetch(false));
        }
        return 0;
    };

export const getUrlInfoPicFetch = () => async dispatch => {
    try {
        const response = await fetch('https://guest.becar.ru/?r=api/getserverurl');
        if (response.status === 200) {
            const res = await response.text();
            await dispatch(set_url_info_pic(res));
        } else if (response.status === 401) {
            await dispatch(set_token_error(true));
        }
    } catch (e) {
        console.log('getUrlInfoPic error:', e);
    }
};

export const updateLoyaltyList =
    ({ access_token, data }) =>
    async dispatch => {
        try {
            if (access_token) {
                const response = await api.updateLoyaltyListFetch.fetch(access_token, data);

                if (response.status === 200) {
                    await dispatch(set_response_status(true));
                    await dispatch(upd_loyalty(data));
                } else if (response.status === 401) {
                    await dispatch(set_token_error(true));
                }
                return response.status;
            }
        } catch (e) {
            console.log('updateLoyaltyList error: ', e);
        }
        return 0;
    };

export const updManagmentInfo =
    ({ access_token, data }) =>
    async dispatch => {
        await dispatch(set_update_info_fetch(true));
        try {
            if (access_token) {
                const response = await api.updateManageInfoFetch.fetch(access_token, data);

                if (response.status === 200) {
                    await dispatch(set_response_status(true));
                    await dispatch(update_manage_info(data));
                } else if (response.status === 401) {
                    await dispatch(set_token_error(true));
                }
                return response.status;
            }
        } catch (e) {
            console.log('updateLoyaltyList error: ', e);
        } finally {
            await dispatch(set_update_info_fetch(false));
        }
        return 0;
    };

export const getHotelInfoPics =
    ({ access_token, hotel_id }) =>
    async dispatch => {
        try {
            const response = await api.getHotelInfoPics.fetch(access_token, hotel_id);

            if (response.status === 200) {
                const res = await response.json();

                if (res && res.hasOwnProperty('result')) {
                    dispatch(set_hotel_info_pics(res.files2));
                }
            } else if (response.status === 401) {
                await dispatch(set_token_error(true));
            }
        } catch (e) {
            console.log('getHotelInfoPics Error: ', e);
        }
    };

export const addHotelInfoPics =
    ({ access_token, hotel_id, files }) =>
    async dispatch => {
        try {
            const filesArr = files.length ? await Promise.all(files.map(async file => await getFileFromUrl(file.url, `${Date.now()}.jpg`))) : [];

            const response = await api.addHotelInfoPics.fetch(access_token, hotel_id, filesArr);

            if (response.status === 200) {
                const res = await response.json();

                if (res.result) {
                    const files = res.result.file_path.map((file, id) => ({ id: id + 1, url: file }));

                    await dispatch(set_hotel_info_pics(files));
                    return res.result;
                }
            } else if (response.status === 401) {
                await dispatch(set_token_error(true));
                return {
                    error: 401,
                    message: 'error token',
                };
            }
        } catch (e) {
            console.log('addHotelInfoPics error: ', e);
            return {
                error: 1,
                message: e,
            };
        }

        return {
            error: 1,
            message: 'something wrong:(',
        };
    };

export const deleteHotelInfoPics =
    ({ access_token, file }) =>
    async dispatch => {
        try {
            const response = await api.deleteHotelInfoPics.fetch(access_token, file);

            if (response.status === 200) {
                const res = await response.json();

                if (res.result.error === 0) {
                    await dispatch(delete_hotel_info_pics(file));
                    return res.result;
                }
            } else if (response.status === 401) {
                await dispatch(set_token_error(true));
            }
        } catch (e) {
            console.log('deleteHotelInfoPics error: ', e);
        }
    };

export const createNewInfoSection =
    ({ access_token, data }) =>
    async dispatch => {
        await dispatch(set_update_info_fetch(true));

        try {
            if (access_token) {
                const files = data.hasOwnProperty('files')
                    ? await Promise.all(data.files.map(async file => await getFileFromUrl(file, `${Date.now()}.jpg`)))
                    : [];

                const response = await api.editInformationItem.fetch(access_token, { ...data, files });

                if (response.status === 200) {
                    const res = await response.json();
                    if (res.message === 'ok') {
                        const newData = {
                            ...res.added_object,
                            files: res.files,
                            hotel_id: data.hotel_id,
                            section_link: data.section_link,
                        };
                        await dispatch(add_section(newData));
                        await dispatch(add_new_section(newData));
                        await dispatch(set_sort_info_sections({ hotel_id: data.hotel_id }));
                        await dispatch(set_response_status(true));
                    }
                    if (res.code === 20) {
                        return res.code;
                    }
                } else if (response.status === 401) {
                    await dispatch(set_token_error(true));
                }
                return response.status;
            }
        } catch (e) {
            console.log('addNewInfoSection error: ', e);
        } finally {
            await dispatch(set_update_info_fetch(false));
        }
        return 0;
    };

export const createRoom =
    ({ data }) =>
    async (dispatch, getState) => {
        const access_token = getState().login.access_token;

        try {
            if (access_token) {
                const response = await api.createRoom.fetch(access_token, { hotel_id: data.hotel_id });

                if (response.status === 200) {
                    const result = await response.json();
                    if (result.message === 'ok') {
                        dispatch(create_room({ ...data, id: result.id }));
                        return result.id;
                    }
                } else if (response.status === 401) {
                    await dispatch(set_token_error(true));
                }
                return response.status;
            }
        } catch (e) {
            console.log('createRoom error: ', e);
        } finally {
            await dispatch(set_update_info_fetch(false));
        }
        return 0;
    };

export const getInfoIcons = () => async dispatch => {
    try {
        const response = await api.getIcons.fetch();
        if (response.status === 200) {
            const res = await response.json();
            dispatch(set_icons(res));
        }
    } catch (e) {
        console.log('getInfoIcons error: ', e);
    }
};

export const getLaundryMachines =
    ({ hotel_id }) =>
    async (dispatch, getState) => {
        const access_token = getState().login.access_token;

        try {
            const response = await api.getLaundryMachines.fetch(access_token, hotel_id);

            if (response.status === 200) {
                const result = await response.json();
                dispatch(set_machines(result));
            }
        } catch (e) {
            console.log('getLaundryMachines error: ', e);
        }
    };

export const updateMachine =
    ({ data }) =>
    async (dispatch, getState) => {
        const access_token = getState().login.access_token;
        try {
            const response = await api.updateMachine.fetch(access_token, data.id, data);

            if (response.status === 200) {
                const result = await response.json();

                if (result.status === 'ok') {
                    dispatch(change_machine_info(data));
                    return 'ok';
                } else {
                    return result.error;
                }
            }
        } catch (e) {
            console.log('updateMachine error: ', e);
        }
    };

export const machineControl =
    ({ data }) =>
    async (dispatch, getState) => {
        const access_token = getState().login.access_token;
        try {
            const response = await api.machineControl.fetch(access_token, data);

            if (response.status === 200) {
                const result = await response.json();

                if (result.status === 'ok') {
                    if (data.command === 'on') {
                        dispatch(set_start_machine(data));
                    } else {
                        dispatch(set_end_machine(data));
                    }
                    return 'ok';
                } else {
                    return result.error;
                }
            }
        } catch (e) {
            console.log('updateMachine error: ', e);
        }
    };

export const response_status = state => state.information.response_status;
export const url_info_pic = state => state.information.url_info_pic;
export const information_tabs = state => state.information.information_tabs;
export const infoFetch = state => state.information.info_fetch;
export const updateInfoFetch = state => state.information.update_info_fetch;
export const hotel_info_pics = state => state.information.hotel_info_pics;
export const tab_info = state => state.information.tab_info;
export const information_icons = state => state.information.icons;
export const machines = state => state.information.machines;
export const machines_fetching = state => state.information.machines_fetching;

export default informationSlice.reducer;
