import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { tabs as tabs_state } from '../redux/slices/hotelSlice';
import { Header } from '../components/chat/Header';
import { List } from '../components/chat/List';
import { Detail } from '../components/chat/Detail';

export const ChatPage = () => {
    const [tab, setTab] = useState(null);
    const [bookingId, setBookingId] = useState('');
    const tabs = useSelector(tabs_state);
    const { hotel_id } = useParams();

    useEffect(() => {
        if (tabs.length && hotel_id) {
            const hotel = tabs.find(hotel => hotel.id === hotel_id);
            if (hotel) {
                setTab({
                    ...hotel.subitems.find(tab => tab.name === 'Чат'),
                    hotel_name: hotel.Name,
                });
            }
            setBookingId('');
        }
    }, [tabs, hotel_id]);

    return (
        <>
            <Header tab={tab} />

            <div className="w-full bg-white flex flex-col md:flex-row overflow-hidden h-[calc(100vh-4rem)] md:h-[calc(100vh-9rem)]">
                <List setBookingId={setBookingId} bookingId={bookingId} tab={tab} />
                <Detail setBookingId={setBookingId} bookingId={bookingId} tab={tab} />
            </div>
        </>
    );
};
