import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api';
import { set_token_error } from './loginSlice';

export const loyaltySlice = createSlice({
    name: 'loyalty',

    initialState: {
        users: [],
        users_fetching: false,
        page: 1,
        total_count: null,
        filter: '',
    },

    reducers: {
        set_users: (state, action) => {
            state.users = state.page === 1 ? action.payload.data : [...state.users, ...action.payload.data];
        },
        update_users: (state, action) => {
            state.users = state.users.map(user => {
                if (user.id === action.payload.guest_id) {
                    return { ...user, ...action.payload };
                }

                return user;
            });
        },
        set_users_fetching: (state, action) => {
            state.users_fetching = action.payload;
        },
        set_page: (state, action) => {
            state.page = action.payload;
        },
        set_user_count: (state, action) => {
            state.total_count = action.payload;
        },
        set_filter: (state, action) => {
            state.filter = action.payload;
            state.page = 1;
            state.total_count = null;
        },
    },
});

export const { set_filter, set_user_count, set_page, set_users, update_users, set_users_fetching } = loyaltySlice.actions;

export const getUsers =
    ({ access_token, page, userFilter }) =>
    async dispatch => {
        dispatch(set_users_fetching(true));
        try {
            if (access_token) {
                const response = await api.getUsersList.fetch(access_token, page, userFilter);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.hasOwnProperty('result')) {
                        dispatch(set_user_count(res.conut));
                        dispatch(
                            set_users({
                                data: res.result.map(el => ({
                                    name: el.Name,
                                    loyalty_days: el.days,
                                    loyalty_discount: el.percent_discont,
                                    loyalty_status_name: el.status_name,
                                    loyalty_status_id: el.status_id,
                                    black_list: el.black_list,
                                    phone: el.clean_phone ? `+${el.clean_phone}` : '',
                                    email: el.email,
                                    id: el.guest_id,
                                    comment: el.comment || '',
                                    loyalty_days_detail: el.days_detail.map(item => ({
                                        hotel: item.hotel_name,
                                        room: item.room_num,
                                        date_from: new Date(item.date_from).toLocaleString('ru', {
                                            month: 'numeric',
                                            day: 'numeric',
                                            year: 'numeric',
                                        }),
                                        date_to: new Date(item.date_to).toLocaleString('ru', {
                                            month: 'numeric',
                                            day: 'numeric',
                                            year: 'numeric',
                                        }),
                                    })),
                                })),
                            })
                        );
                    }
                } else {
                    dispatch(set_token_error(true));
                }
            }
        } catch (e) {
            console.log('setUsers error: ', e);
        } finally {
            dispatch(set_users_fetching(false));
        }
    };

export const editUser =
    ({ access_token, data }) =>
    async dispatch => {
        try {
            if (access_token) {
                const response = await api.editUser.fetch(access_token, data);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.result === 'guest updated') {
                        dispatch(update_users({ ...data }));
                    }
                } else {
                    dispatch(set_token_error(true));
                }

                return response.status;
            }
        } catch (e) {
            console.log('editUser error: ', e);
        }

        return 0;
    };

export const users = state => state.loyalty.users;
export const users_fetching = state => state.loyalty.users_fetching;
export const page = state => state.loyalty.page;
export const total_count = state => state.loyalty.total_count;
export const userFilter = state => state.loyalty.filter;

export default loyaltySlice.reducer;
