import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { errorNotification, successNotification } from '../../api/functions';
import { RotatingLines } from 'react-loader-spinner';
import { ContentWrapper } from '../common/ContentWrapper';

import { access_token as access_token_state, login as login_state } from '../../redux/slices/loginSlice';
import {
    sendPushNotification,
    push_send_fetching as push_send_fetching_state,
    push_status as push_status_state,
    set_push_status,
} from '../../redux/slices/chatSlice';

const Schema = Yup.object().shape({
    title: Yup.string().min(2, 'Поле заголовок слишком короткое').max(100, 'Поле заголовок слишком длинное').required('Поле заголовок обязательное'),
    body: Yup.string().min(2, 'Поле текст слишком короткое').max(300, 'Поле текст более 300 символов').required('Поле текст обязательное'),
});

const initialValues = { title: '', body: '' };
export const PushForm = ({ tab }) => {
    const [fetchAccessToken, setFetchAccessToken] = useState('');

    const formikRef = useRef();

    const dispatch = useDispatch();
    const access_token = useSelector(access_token_state);
    const push_send_fetching = useSelector(push_send_fetching_state);
    const push_status = useSelector(push_status_state);
    const login = useSelector(login_state);

    useEffect(() => {
        if (push_status === 200) {
            successNotification('Пуш отправлен');
            dispatch(set_push_status(false));
        }
        if (push_status === 403) {
            errorNotification('У вас недостаточно прав для отправки пуш уведомлений, обратитесь к администратору');
            dispatch(set_push_status(false));
        }
    }, [push_status]);

    const formSubmit = async (values, { setSubmitting, resetForm }) => {
        setFetchAccessToken(access_token);

        let status = '';
        const data = {
            hotel_id: tab.hotel_id,
            title: values.title,
            body: values.body,
            login,
        };

        status = await dispatch(sendPushNotification({ access_token, data }));

        if (status === 200) {
            setSubmitting(false);
            resetForm();
        }
    };
    useEffect(() => {
        (async () => {
            if (fetchAccessToken && fetchAccessToken !== access_token) {
                await formSubmit(formikRef.current.values, formikRef.current);
            }
        })();
    }, [access_token]);

    return (
        <>
            <ContentWrapper>
                <Formik initialValues={initialValues} validationSchema={Schema} innerRef={formikRef} onSubmit={formSubmit} enableReinitialize={true}>
                    {({ values, errors, isValidating, isSubmitting, dirty }) => {
                        if (isSubmitting && !isValidating) {
                            for (const err in errors) {
                                errorNotification(errors[err]);
                            }
                        }
                        return (
                            <Form>
                                <div className=" sm:w-2/3 bg-white ">
                                    <div className="flex items-center border-b">
                                        <p className="pb-6 pt-6 pl-6 font-medium">Создайте пуш уведомление</p>
                                    </div>

                                    <div className=" sm:w-full bg-white mt-3 pb-3">
                                        {/* title */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-sm mb-2 text-gray-20 flex w-full">Заголовок</p>
                                            <Field
                                                name="title"
                                                component="textarea"
                                                value={values.title}
                                                placeholder="Введите заголовок"
                                                className="box-border w-full h-8 lg:h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2"
                                                disabled={isSubmitting}
                                            />
                                        </div>

                                        {/*  body */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-sm mb-2 text-gray-20 flex w-full">Текст</p>
                                            <Field
                                                name="body"
                                                component="textarea"
                                                value={values.body}
                                                placeholder="Введите текст"
                                                className="box-border w-full h-8 sm:h-24 text-sm resize-none rounded border-gray-10 p-1 pl-2"
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className={` mt-3 rounded-sm font-medium text-sm px-14 h-10  ${
                                        dirty ? 'bg-blue-500 text-white cursor-pointer' : 'bg-slate-300 cursor-default text-gray-400'
                                    } `}
                                    disabled={!dirty || push_send_fetching}
                                >
                                    {push_send_fetching ? <RotatingLines width="20" strokeColor="white" /> : 'Отправить'}
                                </button>
                            </Form>
                        );
                    }}
                </Formik>
            </ContentWrapper>
        </>
    );
};
