import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { Header } from './Header';
import { information_tabs as informations_tabs_state } from '../../redux/slices/informationSlice';

export const HotelRooms = ({ tab }) => {
    const information_tabs = useSelector(informations_tabs_state);

    const params = useParams();
    const rooms = information_tabs?.find(el => el.code === params.tab_code);

    return (
        <>
            <Header tab={tab} />
            <div className="sm:flex sm:flex-wrap sm:justify-center xl:gap-3 pt-6 pb-6 ">
                {rooms.subitems
                    ? rooms.subitems.map(room => {
                          return (
                              <div
                                  className="bg-white last:mb-0 mb-3 mr-2 ml-2 sm:w-[47%] sm:mb-2 sm:last:mb-2 sm:odd:mr-2 xl:w-1/4 xl:last:mb-0 xl:odd:m-0 xl:m-0 flex-col rounded-2 drop-shadow  "
                                  key={room.id}
                              >
                                  {room.active === '0' && (
                                      <div className="w-full h-full absolute flex items-center justify-center bg-white bg-opacity-50">
                                          <div className="w-2/3 bg-green-500 text-white text-center py-2 text-sm">Номер не активен</div>
                                      </div>
                                  )}
                                  <div className="w-full h-44 xl:h-56 shrink-0">
                                      <img src={room.files?.length ? room.files[0] : ''} alt={room.name} className=" w-full h-full object-cover " />
                                  </div>
                                  <div className="pl-4 pr-4">
                                      <p className="font-bold mb-2 mt-4 text-base sm:text-base whitespace-nowrap truncate  ">{room.name}</p>
                                      <p className=" text-sm mb-3">
                                          <span className="font-medium">Размеры: </span>
                                          <span className="opacity-80">{room.preview_description?.replace('Площадь', '').replace('номера', '')}</span>
                                      </p>
                                  </div>
                                  <Link to={`?roomId=${room.id}`} className="z-10 relative">
                                      <div className="flex justify-center">
                                          <button
                                              type="button"
                                              className="w-4/5 text-center border rounded border-blue-500 text-blue-500 py-1 text-sm mb-4 transition-colors hover:bg-blue-500 hover:text-white "
                                          >
                                              Редактировать
                                          </button>
                                      </div>
                                  </Link>
                              </div>
                          );
                      })
                    : 'Номеров нет'}
            </div>
        </>
    );
};
