import { useEffect, useState, useRef } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { RotatingLines } from 'react-loader-spinner';

import { Header } from './Header';
import { ContentWrapper } from '../common/ContentWrapper';
import { Icons } from '../informations/templatesPages/templatePart/Icons';
import { Activity } from '../informations//templatesPages/templatePart/Activity';
import { Sort } from '../informations//templatesPages/templatePart/Sort';
import { CustomReactQuill } from '../common/CustomReactQuill';
import { getUpdateString, successNotification, errorNotification } from '../../api/functions';

import {
    editInformation,
    set_update_info_fetch,
    set_response_status,
    response_status as response_status_state,
    information_tabs as information_tabs_state,
    updateInfoFetch as updateInfoFetch_state,
    information_icons as information_icons_state,
} from '../../redux/slices/informationSlice';
import { access_token as access_token_state, set_token_error } from '../../redux/slices/loginSlice';

const EventsSchema = Yup.object().shape({
    html: Yup.string().min(12, 'Поле "Описание" слишком короткое').required('Поле "Описание" обязательное'),
    html_en: Yup.string().min(12, 'Поле "Описание (на англ)" слишком короткое').required('Поле "Описание (на англ)" обязательное'),
});

export const HotelEvents = ({ tab }) => {
    const [initValues, setInitValues] = useState({
        html: '',
        html_en: '',
    });
    const [fetchAccessToken, setFetchAccessToken] = useState('');
    const [idSvg, setIdSvg] = useState('');
    const [currentSvg, setCurrentSvg] = useState(null);

    const formikRef = useRef();

    const dispatch = useDispatch();
    const access_token = useSelector(access_token_state);
    const response_status = useSelector(response_status_state);
    const information_tabs = useSelector(information_tabs_state);
    const updateInfoFetch = useSelector(updateInfoFetch_state);
    const information_icons = useSelector(information_icons_state);

    useEffect(() => {
        if (response_status) {
            successNotification();
            dispatch(set_response_status(false));
        }
    }, [response_status]);

    useEffect(() => {
        const tab_info = information_tabs?.find(el => el.id === tab.id);

        if (tab_info) {
            setInitValues({
                html: tab_info?.html ? tab_info?.html : '',
                html_en: tab_info?.html_en ? tab_info?.html_en : '',
                sort: tab.sort,
                active: tab.active === '1' ? ['active'] : [],
                svg_current: tab.svg_current,
            });
            setCurrentSvg(tab.svg_current);
            setIdSvg('');
        }
    }, [information_tabs]);

    const formSubmit = async (values, { setSubmitting, resetForm }) => {
        setFetchAccessToken(access_token);

        dispatch(set_update_info_fetch(true));
        let status;
        const detail_en = await getUpdateString(values.html_en, tab, access_token);
        const detail_ru = await getUpdateString(values.html, tab, access_token);

        if (detail_en === 401 || detail_ru === 401) {
            return dispatch(set_token_error(true));
        }

        if (detail_ru.length > 10000 || detail_en.length > 10000) {
            dispatch(set_update_info_fetch(false));
            return errorNotification('Уменьшите описание');
        }
        const data = {
            ...values,
            html: detail_ru,
            html_en: detail_en,
            tab_item_id: tab.id,
            hotel_id: tab.hotel_id,
            active: values.active[0] === 'active' ? '1' : '0',
            section_link: tab_info.section_link,
        };

        status = await dispatch(editInformation({ access_token, data }));

        if (status === 200) {
            setSubmitting(false);
            resetForm();
            setIdSvg('');
        }
    };
    useEffect(() => {
        (async () => {
            if (fetchAccessToken && fetchAccessToken !== access_token) {
                await formSubmit(formikRef.current.values, formikRef.current);
            }
        })();
    }, [access_token]);

    const handleSvgClick = (e, setFieldValue) => {
        const ev = e.target.closest('div').dataset.svg;
        if (ev) {
            const icon = information_icons.find(el => el.id === Number(ev));
            setIdSvg(Number(ev));
            setFieldValue('svg_current', icon.icon);
        }
    };

    return (
        <>
            <Header tab={tab} />
            <ContentWrapper>
                <Formik initialValues={initValues} validationSchema={EventsSchema} onSubmit={formSubmit} innerRef={formikRef} enableReinitialize={true}>
                    {({ values, errors, isSubmitting, isValidating, dirty, setFieldValue }) => {
                        if (isSubmitting && !isValidating) {
                            for (const err in errors) {
                                errorNotification(errors[err].toString());
                            }
                        }
                        return (
                            <Form>
                                <div className="sm:flex ">
                                    {/* main info */}
                                    <div className=" sm:w-1/2 bg-white">
                                        <div className="flex items-center border-b">
                                            <p className="pb-6 pt-6 pl-6 font-medium">Укажите основную информацию</p>
                                            <div className="ml-auto mr-6" data-tip="Информация ">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path
                                                        d="M11.9502 8H12.0502V8.1H11.9502V8Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                        </div>

                                        {/* events */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-sm mb-2 text-gray-20 flex w-full"> {tab.name}</p>
                                            <Field
                                                name="html"
                                                value={values.html}
                                                placeholder="Укажите актуальную информацию"
                                                className="box-border w-full h-80 text-sm rounded border-gray-10 p-1 pl-2"
                                                disabled={isSubmitting}
                                            >
                                                {({ field, imageHandler }) => (
                                                    <CustomReactQuill
                                                        imageHandler={imageHandler}
                                                        value={field.value}
                                                        onChange={field.onChange(field.name)}
                                                        placeholder="Описание..."
                                                    />
                                                )}
                                            </Field>
                                        </div>

                                        {/* events en */}
                                        <div className="mt-3 pl-6 pr-6 mb-3">
                                            <p className="text-sm mb-2 text-gray-20 flex w-full"> {tab.name_en}</p>
                                            <Field
                                                name="html_en"
                                                value={values.html_en}
                                                placeholder="Provide up-to-date information"
                                                className="box-border w-full h-80 text-sm  rounded border-gray-10 p-1 pl-2"
                                                disabled={isSubmitting}
                                            >
                                                {({ field, imageHandler }) => (
                                                    <CustomReactQuill
                                                        imageHandler={imageHandler}
                                                        value={field.value}
                                                        onChange={field.onChange(field.name)}
                                                        placeholder="Description..."
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    </div>

                                    {/* settings */}
                                    <div className="pb-6 mt-3 sm:w-1/2 bg-white sm:ml-6 sm:mt-0">
                                        {/* icon */}
                                        <div>
                                            <Icons
                                                dataSvg={information_icons}
                                                handleSvgClick={handleSvgClick}
                                                idSvg={idSvg}
                                                setFieldValue={setFieldValue}
                                                currentSvg={currentSvg}
                                            />
                                        </div>

                                        {/* activity */}
                                        <div className=" border-b border-t mt-6">
                                            <Activity />
                                        </div>

                                        {/* sort */}
                                        <div className="flex items-center border-b ">
                                            <Sort values={values} isSubmitting={isSubmitting} />
                                        </div>
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className={` mt-3 rounded-sm font-medium text-sm px-14 h-10 mb-6  ${
                                        dirty ? 'bg-blue-500 text-white cursor-pointer' : 'bg-slate-300 cursor-default text-gray-400'
                                    } `}
                                    disabled={!dirty || updateInfoFetch}
                                >
                                    {updateInfoFetch ? <RotatingLines width="20" strokeColor="white" /> : 'Сохранить'}
                                </button>
                            </Form>
                        );
                    }}
                </Formik>
            </ContentWrapper>
            <ReactTooltip textColor="#FCFCFC" backgroundColor="#8c8d9d" effect="solid" className="!rounded-md !py-2 !px-2.5" />
        </>
    );
};
