import ReactQuill from 'react-quill';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

export const CustomReactQuill = ({ value, onChange, imageHandler, disabledBtn, placeholder }) => {
    const [fildSize, setFildSize] = useState(new Blob([value]).size);

    let newFildSize = new Blob([value]).size;
    useEffect(() => {
        if (newFildSize - fildSize > 10485760) {
            toast.error(`Добавленный файл превышает максимальный размер файла в 10МБ.`, {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
            // disabledBtn(true);
        } else {
            setFildSize(newFildSize);
            // disabledBtn(false);
        }
    }, [value]);

    return (
        <ReactQuill
            value={value}
            onChange={onChange}
            modules={{
                toolbar: {
                    container: [
                        [{ header: '1' }, { header: '2' }, { header: [3, 4, 5, 6] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        ['link', 'image', 'video'],
                        ['clean'],
                        [{ color: ['red', 'green', 'blue', 'black', 'yellow'] }],
                    ],
                    handlers: {
                        image: imageHandler,
                    },
                },
            }}
            placeholder={placeholder}
        />
    );
};
