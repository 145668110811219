export const MachineInfo = ({ machine }) => {
    return (
        <div className="mt-12 relative">
            <p className="font-semibold ">{machine.name}</p>
            <p className="font-semibold ">{machine.type}</p>
            <p>{machine.price}₽</p>
            {machine.working_status === '0' && (
                <div className="absolute bottom-0 right-0 rounded-full bg-red-400 p-2">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <mask id="mask0_1026_4827" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                            <rect width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_1026_4827)">
                            <path
                                d="M12 21C11.45 21 10.9792 20.8042 10.5875 20.4125C10.1958 20.0208 10 19.55 10 19C10 18.45 10.1958 17.9792 10.5875 17.5875C10.9792 17.1958 11.45 17 12 17C12.55 17 13.0208 17.1958 13.4125 17.5875C13.8042 17.9792 14 18.45 14 19C14 19.55 13.8042 20.0208 13.4125 20.4125C13.0208 20.8042 12.55 21 12 21ZM10 15V3H14V15H10Z"
                                fill="white"
                            />
                        </g>
                    </svg>
                </div>
            )}
        </div>
    );
};
