import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Header } from '../components/roomManagment/Header';
import { ManageForm } from '../components/roomManagment/ManageForm';

import { tabs as tabs_state } from '../redux/slices/hotelSlice';

export const RoomManagementInfo = () => {
    const [tab, setTab] = useState(null);

    const tabs = useSelector(tabs_state);

    const { hotel_id } = useParams();

    useEffect(() => {
        if (tabs.length && hotel_id) {
            const hotel = tabs.find(hotel => hotel.id === hotel_id);
            if (hotel) {
                setTab({
                    hotel_name: hotel.Name,
                    hotel_id: hotel.id,
                    managment_info: hotel.manage_additional_html ? hotel.manage_additional_html : {},
                });
            }
        }
    }, [tabs, hotel_id]);

    return (
        <>
            <Header tab={tab} />

            <ManageForm tab={tab} />
        </>
    );
};
