import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { ContentWrapper } from '../common/ContentWrapper';
import { errorNotification, successNotification } from '../../api/functions';
import { RotatingLines } from 'react-loader-spinner';
import {
    updManagmentInfo,
    set_response_status,
    updateInfoFetch as updateInfoFetch_state,
    response_status as response_status_state,
} from '../../redux/slices/informationSlice';
import { access_token as access_token_state } from '../../redux/slices/loginSlice';

const Schema = Yup.object().shape({
    title_ru: Yup.string().min(2, 'Поле "Заголовок" слишком короткое').max(100, 'Поле "Заголовок" слишком длинное').required('Поле "Заголовок" обязательное'),
    title_en: Yup.string()
        .min(2, 'Поле "Заголовок (на англ)" слишком короткое')
        .max(100, 'Поле "Заголовок (на англ)" слишком длинное')
        .required('Поле "Заголовок (на англ)" обязательное'),
    info_ru: Yup.string()
        .min(3, 'Поле "Информация" слишком короткое')
        .max(1000, 'Поле "Информация" слишком длинное')
        .required('Поле "Информация" обязательное'),
    info_en: Yup.string()
        .min(3, 'Поле "Информация (на англ)" слишком короткое')
        .max(1000, 'Поле "Информация (на англ)" слишком длинное')
        .required('Поле "Информация (на англ)" обязательное'),
});

export const ManageForm = ({ tab }) => {
    const [initialValues, setInitialValues] = useState({ title_ru: '', title_en: '', info_ru: '', info_en: '' });
    const [fetchAccessToken, setFetchAccessToken] = useState('');

    const formikRef = useRef();

    const dispatch = useDispatch();
    const updateInfoFetch = useSelector(updateInfoFetch_state);
    const access_token = useSelector(access_token_state);
    const response_status = useSelector(response_status_state);

    useEffect(() => {
        if (response_status) {
            successNotification();
            dispatch(set_response_status(false));
        }
    }, [response_status]);

    useEffect(() => {
        if (tab) {
            setInitialValues(tab.managment_info.title_ru ? tab.managment_info : { title_ru: '', title_en: '', info_ru: '', info_en: '' });
        }
    }, [tab]);

    const formSubmit = async (values, { setSubmitting, resetForm }) => {
        setFetchAccessToken(access_token);

        let status = '';
        const data = {
            hotel_id: tab.hotel_id,
            manage_additional_html: values,
        };

        status = await dispatch(updManagmentInfo({ access_token, data }));

        if (status === 200) {
            setSubmitting(false);
            resetForm();
        }
    };

    useEffect(() => {
        (async () => {
            if (fetchAccessToken && fetchAccessToken !== access_token) {
                await formSubmit(formikRef.current.values, formikRef.current);
            }
        })();
    }, [access_token]);

    return (
        <>
            <ContentWrapper>
                <Formik initialValues={initialValues} validationSchema={Schema} innerRef={formikRef} onSubmit={formSubmit} enableReinitialize={true}>
                    {({ values, errors, isValidating, isSubmitting, dirty }) => {
                        if (isSubmitting && !isValidating) {
                            for (const err in errors) {
                                errorNotification(errors[err]);
                            }
                        }
                        return (
                            <Form>
                                <div className=" sm:w-2/3 bg-white ">
                                    <div className="flex items-center border-b">
                                        <p className="pb-6 pt-6 pl-6 font-medium">Укажите информацию для раздела управления номером</p>
                                    </div>

                                    <div className=" sm:w-full bg-white mt-3 pb-3">
                                        {/* title */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-sm mb-2 text-gray-20 flex w-full">Заголовок</p>
                                            <Field
                                                name="title_ru"
                                                component="textarea"
                                                value={values.title_ru}
                                                placeholder="Введите заголовок"
                                                className="box-border w-full h-8 lg:h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2"
                                                disabled={isSubmitting}
                                            />
                                        </div>

                                        {/* title EN */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-sm mb-2 text-gray-20 flex w-full">Title</p>
                                            <Field
                                                name="title_en"
                                                component="textarea"
                                                value={values.title_en}
                                                placeholder="Enter title"
                                                className="box-border w-full h-8 lg:h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2"
                                                disabled={isSubmitting}
                                            />
                                        </div>

                                        {/* info */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-sm mb-2 text-gray-20 flex w-full">Информация</p>
                                            <Field
                                                name="info_ru"
                                                component="textarea"
                                                value={values.info_ru}
                                                placeholder="Введите информацию"
                                                className="box-border w-full h-8 sm:h-24 text-sm resize-none rounded border-gray-10 p-1 pl-2"
                                                disabled={isSubmitting}
                                            />
                                        </div>

                                        {/* info EN */}
                                        <div className="mt-3 pl-6 pr-6 mb-6">
                                            <p className="text-sm mb-2 text-gray-20 flex w-full">Information</p>

                                            <Field
                                                name="info_en"
                                                component="textarea"
                                                value={values.info_en}
                                                placeholder="Enter information"
                                                className="box-border w-full h-8 sm:h-24 text-sm resize-none rounded border-gray-10 p-1 pl-2"
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className={` mt-3 rounded-sm font-medium text-sm px-14 h-10  ${
                                        dirty ? 'bg-blue-500 text-white cursor-pointer' : 'bg-slate-300 cursor-default text-gray-400'
                                    } `}
                                    disabled={!dirty || updateInfoFetch}
                                >
                                    {updateInfoFetch ? <RotatingLines width="20" strokeColor="white" /> : 'Сохранить'}
                                </button>
                            </Form>
                        );
                    }}
                </Formik>
            </ContentWrapper>
        </>
    );
};
