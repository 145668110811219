import { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { TimeColumn } from './TimeColumn';
import { MeetingRoomColumn } from './MeetingRoomColumn';
import { BookingBlock } from './BookingBlock';

import { selected_meetingRooms as selected_meetingRooms_state } from '../../../../redux/slices/meetingRoomSlice';

export const BookingTable = ({ setCreateMeetingPaneIsOpen, setBookingData, selectedDate }) => {
    const sizeRef = useRef();
    const selected_meetingRooms = useSelector(selected_meetingRooms_state);

    const [tableWidth, setTableWidth] = useState(null);
    const [size, setSize] = useState(null);

    useEffect(() => {
        if (sizeRef.current) {
            setTableWidth(sizeRef.current.getBoundingClientRect().width);
        }
    }, [size]);

    const resizeListener = e => setSize(e);

    useEffect(() => {
        window.addEventListener('resize', resizeListener);
        return () => window.removeEventListener('resize', resizeListener);
    }, []);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [selected_meetingRooms]);

    const handlerUpdateBooking = booking => {
        setCreateMeetingPaneIsOpen(true);
        setBookingData(booking);
    };
    const handlerSetMeeting = (id, e) => {
        setCreateMeetingPaneIsOpen(true);
        setBookingData({ meeting_id: id, start: e.target.dataset.time, selectedDate });
    };

    const getCurrentWorkTime = (selectedDate, meetingRoom, e, idx) => {
        if (
            (selectedDate.getDate() > new Date().getDate() &&
                selectedDate.getMonth() >= new Date().getMonth() &&
                selectedDate.getYear() <= new Date().getYear()) ||
            selectedDate.getYear() > new Date().getYear()
        ) {
            return meetingRoom.start <= idx && meetingRoom.end > idx && handlerSetMeeting(meetingRoom.value, e);
        } else if (selectedDate.getMonth() < new Date().getMonth() || selectedDate.getDate() < new Date().getDate()) {
            return null;
        } else {
            return (
                new Date().getTime() < new Date().setHours(e.target.dataset.time.split(':')[0], e.target.dataset.time.split(':')[1]) &&
                meetingRoom.start <= idx &&
                meetingRoom.end > idx &&
                handlerSetMeeting(meetingRoom.value, e)
            );
        }
    };

    return (
        <div className="overflow-auto relative w-full h-[calc(100vh-128px)]">
            <div className="flex" ref={sizeRef}>
                {/* time column */}
                <TimeColumn />

                {/* meetings column */}
                {selected_meetingRooms &&
                    selected_meetingRooms
                        .filter(el => el.selected === '1')
                        .map(meetingRoom => (
                            <div key={meetingRoom.value}>
                                <MeetingRoomColumn
                                    tableWidth={tableWidth}
                                    selectMeetingRooms={selected_meetingRooms}
                                    meetingRoom={meetingRoom}
                                    selectedDate={selectedDate}
                                    getCurrentWorkTime={getCurrentWorkTime}
                                />
                                <div>
                                    {/* meeting block */}
                                    {meetingRoom.bookings &&
                                        meetingRoom.bookings
                                            .filter(el => el.active === '1')
                                            .map(el => (
                                                <BookingBlock
                                                    el={el}
                                                    key={el.id}
                                                    handlerUpdateBooking={handlerUpdateBooking}
                                                    meetingRoom={meetingRoom}
                                                    selectMeetingRooms={selected_meetingRooms}
                                                    tableWidth={tableWidth}
                                                />
                                            ))}
                                </div>
                            </div>
                        ))}
            </div>
            <ReactTooltip textColor="#FCFCFC" backgroundColor="#8c8d9d" effect="solid" className="!rounded-md !py-2 !px-2.5" />
        </div>
    );
};
