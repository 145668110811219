import { useSelector } from 'react-redux';

import { Header } from '../Header';
import { ContentWrapper } from '../../common/ContentWrapper';

import { tab_info as tab_info_state } from '../../../redux/slices/informationSlice';

export const TemplateInfo2 = () => {
    const tab_info = useSelector(tab_info_state);

    return (
        <>
            <Header tab={{ ...tab_info, name: 'Шаблон 2' }} />
            <ContentWrapper>В разработке</ContentWrapper>
        </>
    );
};
