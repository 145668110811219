export const Icons = ({ dataSvg, handleSvgClick, idSvg, setFieldValue, currentSvg }) => {
    return (
        <>
            <div className="flex items-center ">
                <p className="pb-6 pt-6 pl-6 font-medium">Выберите иконку</p>
                <div className="ml-auto mr-6" data-tip="Иконка для раздела в мобильном приложении">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                            stroke="#1890FF"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M11.9502 8H12.0502V8.1H11.9502V8Z" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
            {currentSvg && (
                <div className="flex items-center">
                    <p className="pb-6 pt-6 pl-6 mr-4">Текущая иконка</p>
                    <div className="iconSize" dangerouslySetInnerHTML={{ __html: currentSvg }} />
                </div>
            )}
            <div className=" ml-6 mr-6">
                <div onClick={e => handleSvgClick(e, setFieldValue)} className="flex items-center gap-1 flex-wrap">
                    {dataSvg.map(svg => (
                        <div
                            key={svg.id}
                            data-svg={svg.id}
                            className={`${svg.id === idSvg ? 'border rounded-xl border-red-700' : 'border-transparent border'} cursor-pointer iconSize`}
                            dangerouslySetInnerHTML={{ __html: svg.icon }}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};
