import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { ToastContainer } from 'react-toastify';
import { BallTriangle } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';

// import { requestForToken } from './firebase';

import './source/styles/index.scss';

import {
    access_token as access_token_state,
    check_auth_fetching as check_auth_fetching_state,
    checkAuth,
    login as login_state,
    refresh_token as refresh_token_state,
    refreshToken,
    token_error as token_error_state,
} from './redux/slices/loginSlice';
import { getUrlInfoPicFetch } from './redux/slices/informationSlice';

import { MainLayout } from './components/common/MainLayout';
import { RequireAuth } from './components/common/RequireAuth';
import { HomePage } from './pages/HomePage';
import { AuthPage } from './pages/AuthPage';
import { MerchPage } from './pages/MerchPage';
import { ChatPage } from './pages/ChatPage';
import { RoomManagementPage } from './pages/RoomManagementPage';
import { RoomManagementInfo } from './pages/RoomManagementInfo';
import { FoodPage } from './pages/FoodPage';
import { InformationPage } from './pages/InformationPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { DesignPage } from './pages/DesignPage';
import { LoyaltyPage } from './pages/LoyaltyPage';
import { LoyaltyFormPage } from './pages/LoyaltyFormPage';
import { LoyaltyArrivingGuestsPage } from './pages/LoyaltyArrivingGuestsPage';
import { TemplateInfo2 } from './components/informations/templatesPages/TemplateInfo2';
import { TemplateInfo3 } from './components/informations/templatesPages/TemplateInfo3';
import { PushNotificationPage } from './pages/PushNotificationPage';
import { MeetingRoomPage } from './pages/MeetingRoomPage';
import { ShopPage } from './pages/ShopPage';

export const App = () => {
    const location = useLocation();
    const [cookies, setCookie] = useCookies();
    const dispatch = useDispatch();
    const check_auth_fetching = useSelector(check_auth_fetching_state);
    const access_token = useSelector(access_token_state);
    const refresh_token = useSelector(refresh_token_state);
    const login = useSelector(login_state);
    const token_error = useSelector(token_error_state);

    // requestForToken();

    useEffect(() => {
        document.querySelector('html').style.scrollBehavior = 'auto';
        window.scroll({ top: 0 });
        document.querySelector('html').style.scrollBehavior = '';
    }, [location.pathname]);

    //check auth
    useEffect(() => {
        dispatch(
            checkAuth({
                access_token: cookies.access_token,
                refresh_token: cookies.refresh_token,
                login: cookies.login,
            })
        );
        dispatch(getUrlInfoPicFetch());
    }, []);

    //refresh token
    useEffect(() => {
        if (token_error) {
            dispatch(
                refreshToken({
                    access_token: cookies.access_token,
                    refresh_token: cookies.refresh_token,
                    login: cookies.login,
                })
            );
        }
    }, [token_error]);

    //set cookie
    useEffect(() => {
        if (access_token) {
            setCookie('access_token', access_token, { path: '/' });
        }
    }, [access_token]);
    useEffect(() => {
        if (refresh_token) {
            setCookie('refresh_token', refresh_token, { path: '/' });
        }
    }, [refresh_token]);

    useEffect(() => {
        if (login) {
            setCookie('login', login, { path: '/' });
        }
    }, [login]);

    if (check_auth_fetching) {
        return (
            <div className="fixed w-full h-full flex items-center justify-center bg-gray-100 z-60">
                <BallTriangle height="100" width="100" color="#3b82f6" ariaLabel="loading" />
            </div>
        );
    }

    return (
        <>
            <Routes>
                <Route element={<RequireAuth />}>
                    <Route element={<MainLayout />}>
                        <Route exact path="/" element={<HomePage />} />
                        <Route path="/object/:hotel_id/room-management/" element={<RoomManagementPage />} />
                        <Route path="/object/:hotel_id/manage_additional" element={<RoomManagementInfo />} />
                        <Route path="/object/:hotel_id/push_notification" element={<PushNotificationPage />} />
                        <Route path="/object/:hotel_id/chat/" element={<ChatPage />} />
                        <Route path="/object/:hotel_id/shop/food/" element={<FoodPage />} />
                        <Route path="/object/:hotel_id/shop/shop/" element={<MerchPage />} />
                        <Route path="/object/:hotel_id/information/:tab_code/" element={<InformationPage />} />
                        <Route path="/object/:hotel_id/information/template_info_2/" element={<TemplateInfo2 />} />
                        <Route path="/object/:hotel_id/information/template_info_3/" element={<TemplateInfo3 />} />
                        <Route path="/object/:hotel_id/shop_2/:category_id/" element={<ShopPage />} />
                        <Route path="/loyalty/" element={<LoyaltyPage />} />
                        <Route path="/object/:hotel_id/loyalty/arriving" element={<LoyaltyArrivingGuestsPage />} />
                        <Route path="/object/:hotel_id/loyalty/form" element={<LoyaltyFormPage />} />
                        <Route path="/loyalty-table/" element={<LoyaltyPage />} />
                        <Route path="/design/" element={<DesignPage />} />
                        <Route path="/object/:hotel_id/meeting/:tab_code/" element={<MeetingRoomPage />} />
                    </Route>
                </Route>
                <Route path="/login" element={<AuthPage />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>

            <ToastContainer />
        </>
    );
};
