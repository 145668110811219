export const CreateBookingButton = ({ setPaneIsOpen }) => {
    return (
        <>
            <button
                onClick={() => setPaneIsOpen(true)}
                type="button"
                className="flex items-center text-sm rounded-3xl py-2 px-6 transition-colors hover:bg-blue-500 bg-blue-400 text-white"
            >
                <svg viewBox="0 0 14 14" className="fill-current mr-2 w-2.5 h-2.5">
                    <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" />
                </svg>
                <span>Добавить событие</span>
            </button>
        </>
    );
};
