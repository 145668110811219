import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

import { tabs as tabs_state } from '../redux/slices/hotelSlice';
import { Header } from '../components/food/Header';
import { List } from '../components/food/List';
import { Detail } from '../components/food/Detail';

export const FoodPage = () => {
    const [detailId, setDetailId] = useState('');
    const [tab, setTab] = useState(null);
    const tabs = useSelector(tabs_state);
    const { hotel_id } = useParams();

    useEffect(() => {
        if (tabs.length && hotel_id) {
            const hotel = tabs.find(hotel => hotel.id === hotel_id);
            if (hotel) {
                setTab({
                    ...hotel.subitems.find(tab => tab.name === 'еда в номер').subitems.find(tab => tab.link === 'food'),
                    hotel_name: hotel.Name,
                });
            }
        }
    }, [tabs, hotel_id]);

    if (tab && tab.active2 === '0') {
        return <Navigate to="/" />;
    }

    return (
        <>
            {/*Header*/}
            <Header tab={tab} setDetailId={setDetailId} />

            {/*Content*/}
            <List tab={tab} setDetailId={setDetailId} />

            {/*Detail*/}
            <Detail tab={tab} detailId={detailId} setDetailId={setDetailId} />
        </>
    );
};
