import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import AuthImage from '../source/images/auth-image.svg';
import { useDispatch, useSelector } from 'react-redux';
import { auth_error as auth_error_state, authFetching, login as login_state, set_auth_error } from '../redux/slices/loginSlice';

export const AuthPage = () => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const auth_error = useSelector(auth_error_state);
    const location = useLocation();
    const email = useSelector(login_state);

    const formSubmit = () => {
        dispatch(authFetching({ login, password }));
    };

    useEffect(() => {
        if (auth_error) {
            toast.error(auth_error.toString(), {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
            dispatch(set_auth_error(null));
        }
    }, [auth_error]);

    if (email) {
        return <Navigate to={location.state?.from.pathname || '/'} />;
    }

    return (
        <div className="relative w-full h-full flex flex-row items-center md:items-start">
            {/* Image */}
            <div className="hidden w-1/2 h-full md:flex md:items-center" aria-hidden="true">
                <img className="w-full" src={AuthImage} alt="Authentication" />
            </div>

            {/* Content */}
            <div className="w-full md:w-1/2">
                <div className="max-w-sm mx-auto md:min-h-screen flex flex-col justify-center px-4 py-8">
                    <div className="w-full flex flex-col items-center ">
                        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" className="mb-2">
                            <g clipPath="url(#clip0_4210_1997)">
                                <path
                                    d="M26.4864 30.7478C25.75 32.5478 23.5409 33.6114 21.0455 33.6114C16.8318 33.6114 13.6818 30.7478 13.6818 26.3296V13.2796H19V25.5523C19 28.0478 20.1455 29.316 22.3955 29.316C24.8091 29.316 26.4864 27.7205 26.4864 25.5523V13.2796H31.8045V33.9387C31.8045 39.6251 27.7136 42.9387 22.5591 42.9387C16.6682 42.9387 13.6818 39.7069 13.6818 34.5114H18.6318C18.6318 36.8841 20.0227 38.3569 22.8046 38.3569C24.7273 38.3569 26.4864 36.6796 26.4864 34.0205V30.7478Z"
                                    fill="#02211B"
                                />
                                <path d="M42.175 13.2796H37.1023V18.5978H42.175V13.2796Z" fill="#02211B" />
                                <path d="M42.175 26.0842H37.1023V31.4023H42.175V26.0842Z" fill="#02211B" />
                            </g>
                            <rect x="0.5" y="0.5" width="55" height="55" rx="15.5" stroke="black" strokeOpacity="0.1" />
                            <defs>
                                <clipPath id="clip0_4210_1997">
                                    <rect width="28.6364" height="30" fill="white" transform="translate(13.6818 13)" />
                                </clipPath>
                            </defs>
                        </svg>

                        <h1 className="text-4xl font-semibold">MYS Hotel</h1>
                        <p className="text-lg mb-12">Административная панель</p>

                        {/* Form */}
                        <form className="w-full">
                            <div className="space-y-4">
                                <input
                                    className="w-full rounded-lg h-14 bg-blue-100 text-blue-500"
                                    type="text"
                                    value={login}
                                    onChange={e => setLogin(e.target.value)}
                                />
                                <input
                                    className="w-full rounded-lg h-14 bg-blue-100 text-blue-500"
                                    type="password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="flex items-center justify-between mt-6">
                                {/*<div className='mr-1'>*/}
                                {/*	<Link className='text-sm underline hover:no-underline' to='#'>Забыли пароль?</Link>*/}
                                {/*</div>*/}

                                <button
                                    type="button"
                                    name="submit"
                                    className="w-full rounded-lg h-14 bg-blue-500 text-white px-12 transition-colors hover:bg-blue-800"
                                    onClick={formSubmit}
                                >
                                    Войти
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
