import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { MultiSelect } from '../../../common/widgets/MultiSelect';
import { set_selected_meetingRooms, meeting_room_options as meeting_room_options_state } from '../../../../redux/slices/meetingRoomSlice';

export const Filter = () => {
    const dispatch = useDispatch();
    const meeting_room_options = useSelector(meeting_room_options_state);

    const [selectedDate, setSelectedDate] = useState([]);

    useEffect(() => {
        setSelectedDate(meeting_room_options);
    }, [meeting_room_options]);

    return (
        <div className="relative">
            <div className="flex items-center">
                <span className="z-10 relative text-sm px-2 mt-[5px] text-blue-500">Фильтр по переговорным</span>

                <svg width="8" height="8" viewBox="0 0 8 8" className="z-10 mr-1 relative mt-1.5">
                    <circle cx="4" cy="4" r="4" fill="#BDFFDB" />
                </svg>
                <svg width="8" height="8" viewBox="0 0 8 8" className="z-10 mr-1 relative mt-1.5">
                    <circle cx="4" cy="4" r="4" fill="#FFDDDD" />
                </svg>
                <svg width="8" height="8" viewBox="0 0 8 8" className="z-10 mr-1 relative mt-1.5">
                    <circle cx="4" cy="4" r="4" fill="#D6C8FF" />
                </svg>
                <svg width="8" height="8" viewBox="0 0 8 8" className="z-10 mr-1 relative mt-1.5">
                    <circle cx="4" cy="4" r="4" fill="#FFEBB7" />
                </svg>
            </div>
            <MultiSelect
                options={meeting_room_options}
                placeholder=""
                value={selectedDate}
                callback={val => {
                    const unSelected = meeting_room_options.filter(opt => val.every(el => el.value !== opt.value));
                    const unSelectedWithSelected = unSelected.map(el => ({ ...el, selected: '0' }));
                    const unSelectedArr = [...unSelectedWithSelected, ...val];
                    const newOptions = [...meeting_room_options];
                    const currentOptions = newOptions.map(el => {
                        for (const i of unSelectedArr) {
                            if (el.value === i.value) {
                                return { ...i };
                            }
                        }
                    });
                    dispatch(set_selected_meetingRooms(currentOptions));
                    setSelectedDate(val);
                }}
                className="w-72 h-8 meeting-select z-20"
                getOptionLabel={e => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="w-2 h-2 rounded-full " style={{ backgroundColor: e.color }}></div>
                        <span className="w-52 text-ellipsis overflow-hidden" style={{ marginLeft: 8 }}>
                            {e.label}
                        </span>
                    </div>
                )}
            />
        </div>
    );
};
