import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { Header } from './Header';
import { ContentWrapper } from '../common/ContentWrapper';
import { information_tabs as informations_tabs_state } from '../../redux/slices/informationSlice';

export const HotelHearUs = ({ tab }) => {
    const information_tabs = useSelector(informations_tabs_state);

    const params = useParams();
    const locations = information_tabs?.find(el => el.code === params.tab_code);

    return (
        <>
            <Header tab={tab} />
            <ContentWrapper>
                <div className=" md:flex gap-4">
                    {locations?.subitems?.map(location => {
                        return (
                            <div className="drop-shadow bg-white mb-3 md:mb-0 md:w-1/4 " key={location.id}>
                                <img src={location.files[0]} alt={location.name} className="mb-4 h-40 w-full md:w-96 object-cover" />
                                <p className="font-bold ml-4 mb-3">{location.name}</p>
                                <Link to={`?location=${location.name_en.toLowerCase()}`}>
                                    <div className="flex justify-center	">
                                        <button
                                            type="button"
                                            className="w-4/5 text-center border rounded border-blue-500 text-blue-500 py-1 text-sm mb-4 transition-colors hover:bg-blue-500 hover:text-white"
                                        >
                                            Редактировать
                                        </button>
                                    </div>
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </ContentWrapper>
        </>
    );
};
