import { useEffect, useState, useRef } from 'react';
import { Field, Form, Formik, FieldArray } from 'formik';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { RotatingLines } from 'react-loader-spinner';

import { Header } from '../Header';
import { MainDetailAccordion } from './templatePart/MainDetailAccordion';
import { ContentWrapper } from '../../common/ContentWrapper';
import { CustomReactQuill } from '../../common/CustomReactQuill';
import { getUpdateString, errorNotification, successNotification } from '../../../api/functions';
import { AccordionSchema } from '../schemas/AccordionSchema';

import {
    set_response_status,
    response_status as response_status_state,
    information_tabs as information_tabs_state,
    updateInfoFetch as updateInfoFetch_state,
    tab_info as tab_info_state,
    getHotelInformationFetch,
    createNewInfoSection,
    information_icons as information_icons_state,
} from '../../../redux/slices/informationSlice';
import { access_token as access_token_state } from '../../../redux/slices/loginSlice';

const initialValues = {
    name: '',
    name_en: '',
    sort: '',
    files: [],
    active: null,
    svg_current: null,
    json: [],
};

export const TemplateInfo3 = () => {
    const [fetchAccessToken, setFetchAccessToken] = useState('');
    const [idSvg, setIdSvg] = useState('');
    const [initValues, setInitValues] = useState(initialValues);

    const formikRef = useRef();

    const dispatch = useDispatch();
    const access_token = useSelector(access_token_state);
    const response_status = useSelector(response_status_state);
    const information_tabs = useSelector(information_tabs_state);
    const updateInfoFetch = useSelector(updateInfoFetch_state);
    const tab_info = useSelector(tab_info_state);
    const information_icons = useSelector(information_icons_state);

    const params = useParams();

    useEffect(() => {
        if (response_status) {
            successNotification();
            dispatch(set_response_status(false));
        }
    }, [response_status]);

    useEffect(() => {
        if (!information_tabs.length) {
            dispatch(getHotelInformationFetch({ access_token, hotel_id: params.hotel_id }));
        }
    }, []);

    const formSubmit = async (values, { setSubmitting, resetForm }) => {
        setFetchAccessToken(access_token);
        let error;

        const json = await Promise.all(
            values.json.map(async rebro => {
                if (rebro.sectionDesc) {
                    const description_ru = await getUpdateString(rebro.sectionDesc[0], tab_info, access_token);
                    const description_en = await getUpdateString(rebro.sectionDesc[1], tab_info, access_token);

                    if (description_ru.length > 10000 || description_en.length > 10000) {
                        error = true;
                        dispatch(set_update_info_fetch(false));
                        return errorNotification('Уменьшите описание');
                    } else {
                        return { ...rebro, sectionDesc: [description_ru, description_en] };
                    }
                } else {
                    return rebro;
                }
            })
        );

        if (error) return;

        let status;
        const active = values.active ? (values.active[0] === 'active' ? '1' : '0') : '0';
        const data = {
            ...values,
            hotel_id: tab_info.hotel_id,
            sort: values.sort ? values.sort : '100',
            active,
            json: JSON.stringify(json),
            section_link: tab_info.link,
        };

        status = await dispatch(createNewInfoSection({ access_token, data }));

        if (status === 20) {
            errorNotification('Нельзя создать больше 20 разделов');
        }

        if (status === 200) {
            setSubmitting(false);
            resetForm();
            setIdSvg('');
        }
    };

    useEffect(() => {
        (async () => {
            if (fetchAccessToken && fetchAccessToken !== access_token) {
                await formSubmit(formikRef.current.values, formikRef.current.setSubmitting);
            }
        })();
    }, [access_token]);

    const handleSvgClick = (e, setFieldValue) => {
        const ev = e.target.closest('div').dataset.svg;
        if (ev) {
            const icon = information_icons.find(el => el.id === Number(ev));
            setIdSvg(Number(ev));
            setFieldValue('svg_current', icon.icon);
        }
    };

    const sortDispatch = data => {
        setInitValues(prevState => ({ ...prevState, files: data }));
    };

    return (
        <>
            <Header tab={{ ...tab_info, name: 'Шаблон 3' }} />
            <ContentWrapper>
                <Formik initialValues={initValues} validationSchema={AccordionSchema} innerRef={formikRef} onSubmit={formSubmit} enableReinitialize={true}>
                    {({ values, errors, isValidating, isSubmitting, dirty, setFieldValue }) => {
                        if (isSubmitting && !isValidating) {
                            for (const err in errors) {
                                if (err === 'json') {
                                    errors[err].map(el => {
                                        for (const err in el) {
                                            if (Array.isArray(el[err])) {
                                                el[err].map(el => errorNotification(el));
                                            } else if (typeof el[err] === 'object') {
                                                el[err].head?.first?.map(el => errorNotification(el));
                                                el[err].head?.second?.map(el => errorNotification(el));
                                                el[err].body?.map(el => {
                                                    for (const err in el) {
                                                        errorNotification(el[err]);
                                                    }
                                                });
                                            } else {
                                                errorNotification(el[err]);
                                            }
                                        }
                                    });
                                } else {
                                    errorNotification(errors[err].toString());
                                }
                            }
                        }
                        return (
                            <Form>
                                {/* main info */}
                                <MainDetailAccordion
                                    values={values}
                                    isSubmitting={isSubmitting}
                                    dataSvg={information_icons}
                                    handleSvgClick={handleSvgClick}
                                    idSvg={idSvg}
                                    setFieldValue={setFieldValue}
                                    sortDispatch={sortDispatch}
                                />

                                {/* content */}
                                <div className=" sm:w-full bg-white mt-3">
                                    {values.json && (
                                        <FieldArray name="json">
                                            {({ remove, push }) => (
                                                <div>
                                                    <div className="flex items-center justify-evenly border-b p-6">
                                                        <button
                                                            type="button"
                                                            className="flex flex-row items-center text-white bg-blue-500 transition-colors hover:bg-blue-800 rounded-full py-2 px-6 w-1/3"
                                                            onClick={() =>
                                                                push({
                                                                    sectionName: '',
                                                                    sectionName_en: '',
                                                                    sectionDesc: ['', ''],
                                                                    id: Date.now(),
                                                                    isDesc: true,
                                                                })
                                                            }
                                                        >
                                                            <svg className="w-10 h-10 fill-current stroke-current mr-2" viewBox="0 0 20 20">
                                                                <path d="M5 10H15" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M10 15L10 5" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                            Добавить раскрывающуюся секцию с текстом
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="flex flex-row items-center text-white bg-blue-500 transition-colors hover:bg-blue-800 rounded-full py-2 px-6 w-1/3"
                                                            onClick={() =>
                                                                push({
                                                                    sectionName: '',
                                                                    sectionName_en: '',
                                                                    sectionTable: {
                                                                        isTable: true,
                                                                        head: { isTable: true, first: ['', ''], second: ['', ''] },
                                                                        body: [
                                                                            {
                                                                                cellFirst: '',
                                                                                cellFirst_en: '',
                                                                                cellSecond: '',
                                                                                cellSecond_en: '',
                                                                                id: Date.now(),
                                                                            },
                                                                        ],
                                                                        payment: [],
                                                                    },
                                                                    id: Date.now(),
                                                                    isDesc: false,
                                                                })
                                                            }
                                                        >
                                                            <svg className="w-10 h-10 fill-current stroke-current mr-2" viewBox="0 0 20 20">
                                                                <path d="M5 10H15" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M10 15L10 5" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                            Добавить раскрывающуюся секцию с таблицей
                                                        </button>
                                                    </div>
                                                    {values.json.map((section, id) => (
                                                        <div className="pb-8 pt-8 border-b" key={section.id}>
                                                            {/* section naming */}
                                                            <p className="pl-6 pr-6 text-gray-20 text-center">Название секции: {section?.sectionName} </p>
                                                            <div className="flex mt-3">
                                                                <div className="pl-6 pr-6 w-1/2">
                                                                    <p className="text-sm mb-2 text-gray-20 flex w-full">Название секции</p>
                                                                    <Field
                                                                        name={`json.${id}.sectionName`}
                                                                        component="textarea"
                                                                        placeholder="Название списка"
                                                                        className={`box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2 ${
                                                                            values?.json[id]?.sectionName === '' ? 'border-red-500' : ''
                                                                        }`}
                                                                        disabled={isSubmitting}
                                                                    />
                                                                </div>
                                                                <div className="pl-6 pr-6 w-1/2">
                                                                    <p className="text-sm mb-2 text-gray-20 flex w-full">Section name</p>
                                                                    <Field
                                                                        name={`json.${id}.sectionName_en`}
                                                                        component="textarea"
                                                                        placeholder="Section name"
                                                                        className={`box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2 ${
                                                                            values?.json[id]?.sectionName_en === '' ? 'border-red-500' : ''
                                                                        }`}
                                                                        disabled={isSubmitting}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="">
                                                                {/* text description */}
                                                                {values.json[id]?.sectionDesc && (
                                                                    <div className="flex">
                                                                        <div className="w-1/2 mt-3 pl-6 pr-6 pb-6">
                                                                            <p className="text-sm mb-2 text-gray-20 flex w-full">Описание</p>
                                                                            <Field
                                                                                name={`json.${id}.sectionDesc[0]`}
                                                                                className={`box-border w-1/2 h-24 text-sm rounded border-gray-10 p-1 pl-2 ${
                                                                                    values?.json[id]?.sectionDesc[0] === '' ? 'border-red-500' : ''
                                                                                }`}
                                                                                disabled={isSubmitting}
                                                                            >
                                                                                {({ field, imageHandler }) => (
                                                                                    <CustomReactQuill
                                                                                        imageHandler={imageHandler}
                                                                                        value={field.value}
                                                                                        onChange={field.onChange(field.name)}
                                                                                        placeholder="Описание..."
                                                                                        className={`box-border w-full h-24 text-sm rounded border-gray-10 p-1 pl-2 ${
                                                                                            values?.json[id]?.sectionDesc[0] === '' ? 'border-red-500' : ''
                                                                                        }`}
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                        </div>
                                                                        <div className="w-1/2 mt-3 pl-6 pr-6 pb-6">
                                                                            <p className="text-sm mb-2 text-gray-20 flex w-full">Description</p>
                                                                            <Field
                                                                                name={`json.${id}.sectionDesc[1]`}
                                                                                className="box-border w-1/2 h-24 text-sm rounded border-gray-10 p-1 pl-2"
                                                                                disabled={isSubmitting}
                                                                            >
                                                                                {({ field, imageHandler }) => (
                                                                                    <CustomReactQuill
                                                                                        imageHandler={imageHandler}
                                                                                        value={field.value}
                                                                                        onChange={field.onChange(field.name)}
                                                                                        placeholder="Description..."
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {/* table description */}
                                                                {values.json[id].sectionTable && (
                                                                    <>
                                                                        <div className="flex items-center ml-6 mt-6 mb-2">
                                                                            <p className="text-xs mr-3">Будет ли производиться оплата через эту секцию</p>
                                                                            <Field name={`json.${id}.sectionTable.payment`} type="checkbox" value="true" />
                                                                        </div>
                                                                        {/* table header */}
                                                                        <div className="flex">
                                                                            <div className="pl-6 pr-6 w-1/2">
                                                                                <p className="text-sm mb-2 text-gray-20 flex w-full">
                                                                                    Название первого столбца
                                                                                </p>
                                                                                <Field
                                                                                    name={`json.${id}.sectionTable.head.first[0]`}
                                                                                    component="textarea"
                                                                                    placeholder="Название столбца"
                                                                                    className={`box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2 ${
                                                                                        values?.json[id]?.sectionTable?.head?.first[0] === ''
                                                                                            ? 'border-red-500'
                                                                                            : ''
                                                                                    }`}
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                                <p className="text-sm mb-2 text-gray-20 flex w-full">
                                                                                    Название второго столбца
                                                                                </p>
                                                                                <Field
                                                                                    name={`json.${id}.sectionTable.head.second[0]`}
                                                                                    component="textarea"
                                                                                    placeholder="Название столбца"
                                                                                    className={`box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2 ${
                                                                                        values?.json[id]?.sectionTable?.head?.second[0] === ''
                                                                                            ? 'border-red-500'
                                                                                            : ''
                                                                                    }`}
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                            </div>
                                                                            <div className="pl-6 pr-6 w-1/2">
                                                                                <p className="text-sm mb-2 text-gray-20 flex w-full">
                                                                                    Name of the first column
                                                                                </p>
                                                                                <Field
                                                                                    name={`json.${id}.sectionTable.head.first[1]`}
                                                                                    component="textarea"
                                                                                    placeholder="Column name"
                                                                                    className={`box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2 ${
                                                                                        values?.json[id]?.sectionTable?.head?.first[1] === ''
                                                                                            ? 'border-red-500'
                                                                                            : ''
                                                                                    }`}
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                                <p className="text-sm mb-2 text-gray-20 flex w-full">
                                                                                    Name of the second column
                                                                                </p>
                                                                                <Field
                                                                                    name={`json.${id}.sectionTable.head.second[1]`}
                                                                                    component="textarea"
                                                                                    placeholder="Column name"
                                                                                    className={`box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2 ${
                                                                                        values?.json[id]?.sectionTable?.head?.second[1] === ''
                                                                                            ? 'border-red-500'
                                                                                            : ''
                                                                                    }`}
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <FieldArray name={`json[${id}].sectionTable.body`}>
                                                                            {({ remove, push }) => (
                                                                                <>
                                                                                    <button
                                                                                        className="w-19 pl-2 pr-2 mt-3 ml-6 mb-3 border border-blue-500 bg-white text-blue-500 rounded text-xs transition-colors hover:bg-blue-500 hover:text-white"
                                                                                        type="button"
                                                                                        onClick={() =>
                                                                                            push({
                                                                                                cellFirst: '',
                                                                                                cellFirst_en: '',
                                                                                                cellSecond: '',
                                                                                                cellSecond_en: '',
                                                                                                id: Date.now(),
                                                                                            })
                                                                                        }
                                                                                    >
                                                                                        Добавить строку таблицы
                                                                                    </button>
                                                                                    {/* table rows */}
                                                                                    {values.json[id].sectionTable.body.length > 0 &&
                                                                                        values.json[id].sectionTable.body.map((cell, idx) => (
                                                                                            <div className="flex border-b mt-3 pb-3" key={cell.id}>
                                                                                                <div className="pl-6 pr-6 w-1/2">
                                                                                                    <p className="text-sm mb-2 text-gray-20 flex w-full">
                                                                                                        Контент ячейки первого столбца
                                                                                                    </p>
                                                                                                    <Field
                                                                                                        name={`json.${id}.sectionTable.body.${idx}.cellFirst`}
                                                                                                        component="textarea"
                                                                                                        placeholder="Контент ячейки"
                                                                                                        className={`box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2 ${
                                                                                                            values?.json[id]?.sectionTable?.body[idx]
                                                                                                                .cellFirst === ''
                                                                                                                ? 'border-red-500'
                                                                                                                : ''
                                                                                                        }`}
                                                                                                        disabled={isSubmitting}
                                                                                                    />

                                                                                                    <p className="text-sm mb-2 text-gray-20 flex w-full">
                                                                                                        Контент ячейки второго столбца
                                                                                                    </p>
                                                                                                    <Field
                                                                                                        name={`json.${id}.sectionTable.body.${idx}.cellSecond`}
                                                                                                        component="textarea"
                                                                                                        placeholder="Контент ячейки"
                                                                                                        className={`box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2 ${
                                                                                                            values?.json[id]?.sectionTable?.body[idx]
                                                                                                                .cellSecond === ''
                                                                                                                ? 'border-red-500'
                                                                                                                : ''
                                                                                                        }`}
                                                                                                        disabled={isSubmitting}
                                                                                                    />
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        onClick={() => remove(idx)}
                                                                                                        className="w-40 pl-1 pr-1 mt-3 border border-red-500 bg-white text-red-500 rounded text-xs transition-colors hover:bg-red-500 hover:text-white"
                                                                                                    >
                                                                                                        Удалить строку таблицы
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="pl-6 pr-6 w-1/2">
                                                                                                    <p className="text-sm mb-2 text-gray-20 flex w-full">
                                                                                                        First column cell content
                                                                                                    </p>
                                                                                                    <Field
                                                                                                        name={`json.${id}.sectionTable.body.${idx}.cellFirst_en]`}
                                                                                                        component="textarea"
                                                                                                        placeholder="Cell content"
                                                                                                        className={`box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2 ${
                                                                                                            values?.json[id]?.sectionTable?.body[idx]
                                                                                                                .cellFirst_en === ''
                                                                                                                ? 'border-red-500'
                                                                                                                : ''
                                                                                                        }`}
                                                                                                        disabled={isSubmitting}
                                                                                                    />
                                                                                                    <p className="text-sm mb-2 text-gray-20 flex w-full">
                                                                                                        Second column cell content
                                                                                                    </p>
                                                                                                    <Field
                                                                                                        name={`json.${id}.sectionTable.body.${idx}.cellSecond_en`}
                                                                                                        component="textarea"
                                                                                                        placeholder="Cell content"
                                                                                                        className={`box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2 ${
                                                                                                            values?.json[id]?.sectionTable?.body[idx]
                                                                                                                .cellSecond_en === ''
                                                                                                                ? 'border-red-500'
                                                                                                                : ''
                                                                                                        }`}
                                                                                                        disabled={isSubmitting}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        ))}
                                                                                </>
                                                                            )}
                                                                        </FieldArray>
                                                                    </>
                                                                )}
                                                            </div>
                                                            <button
                                                                type="button"
                                                                onClick={() => remove(id)}
                                                                className="w-40 pl-1 pr-1 mt-3 ml-6 border border-red-700 bg-red-700 text-white rounded text-xs transition-colors hover:bg-red-500 hover:text-white"
                                                            >
                                                                Удалить секцию
                                                            </button>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </FieldArray>
                                    )}
                                </div>

                                <button
                                    type="submit"
                                    className={` mt-3 rounded-sm font-medium text-sm px-14 h-10  ${
                                        dirty ? 'bg-blue-500 text-white cursor-pointer' : 'bg-slate-300 cursor-default text-gray-400'
                                    } `}
                                    disabled={!dirty || updateInfoFetch}
                                >
                                    {updateInfoFetch ? <RotatingLines width="20" strokeColor="white" /> : 'Сохранить'}
                                </button>
                            </Form>
                        );
                    }}
                </Formik>
            </ContentWrapper>
            <ReactTooltip textColor="#FCFCFC" backgroundColor="#8c8d9d" effect="solid" className="!rounded-md !py-2 !px-2.5" />
        </>
    );
};
