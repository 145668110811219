import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RotatingLines } from 'react-loader-spinner';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';

import { errorNotification } from '../../api/functions';
import { addShopCategory, editShopCategory, shopState } from '../../redux/slices/shopSlice';

const initState = {
    name: '',
    name_en: '',
    type: '',
    id: '',
    active: '',
};

const SignupSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Поле "Название" слишком короткое').max(100, 'Поле "Название" слишком длинное').required('Поле "Название" обязательное'),
    name_en: Yup.string()
        .min(2, 'Поле "Название (на англ)" слишком короткое')
        .max(100, 'Поле "Название (на англ)" слишком длинное')
        .required('Поле "Название (на англ)" обязательное'),
    type: Yup.string().required('Поле "Категория" обязательное'),
});

export const EditCategory = ({ category, setEditCategory, hotelId }) => {
    const dispatch = useDispatch();
    const formikRef = useRef();
    const navigate = useNavigate();

    const shop = useSelector(shopState);

    const [initialState, setInitialState] = useState(initState);
    const { hotel_id, category_id } = useParams();

    useEffect(() => {
        if (category && category.id !== 'new') {
            (async () => {
                setInitialState({
                    name: category.name || '',
                    name_en: category.name_en || '',
                    id: category.id,
                    type: category.type,
                    active: category.active === '1' ? true : false,
                });
            })();
        }
    }, [category]);

    const onSubmitHandler = async (values, resetForm) => {
        let res;

        if (category.id !== 'new') {
            res = await dispatch(
                editShopCategory({ data: { ...values, hotel_id: hotelId, shop_link: shop.link, active: values.active === true ? '1' : '0' } })
            );
        } else {
            res = await dispatch(addShopCategory({ data: { ...values, hotel_id: hotelId, shop_link: shop.link, active: values.active === true ? '1' : '0' } }));
        }

        if (res.status) {
            closeHandler(resetForm);
        } else {
            if (res.error) {
                errorNotification(res.error.toString());
            }
        }

        if (res.id) {
            navigate(`/object/${hotel_id}/shop_2/${res.id}/`);
        }
    };

    const closeHandler = resetForm => {
        setEditCategory(null);
        resetForm();
        setInitialState(initState);

        category_id !== 'new' ? navigate(`/object/${hotel_id}/shop_2/${category_id}/`) : navigate(`/`);
    };

    return (
        <Formik
            initialValues={initialState}
            innerRef={formikRef}
            validationSchema={SignupSchema}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => await onSubmitHandler(values, resetForm)}
        >
            {({ errors, isValidating, isSubmitting, resetForm }) => {
                if (isSubmitting && !isValidating) {
                    for (const err in errors) {
                        errorNotification(errors[err].toString());
                    }
                }

                return (
                    <Form className="flex items-center justify-center fixed inset-0 bg-slate-900 bg-opacity-30 z-40 transition-opacity duration-200 opacity-100">
                        <div className="flex flex-col bg-white w-[770px] max-w-full p-6 max-h-[97vh] overflow-auto">
                            <div className="w-full flex flex-row border border-gray-300 mb-6">
                                <div className="p-4 w-full flex flex-row flex-wrap justify-between content-start">
                                    <div className="mb-5 flex flex-col w-full">
                                        <label htmlFor="name" className="font-semibold text-base text-black mb-2 truncate">
                                            Название
                                        </label>
                                        <Field id="name" name="name" type="text" className="border border-gray-300 p-2" />
                                    </div>
                                    <div className="mb-5 flex flex-col w-full">
                                        <label htmlFor="name_en" className="font-semibold text-base text-black mb-2 truncate">
                                            Название (на англ)
                                        </label>
                                        <Field id="name_en" name="name_en" type="text" className="border border-gray-300 p-2" />
                                    </div>
                                    <div className="mb-5 flex flex-col w-full">
                                        <label htmlFor="type" className="font-semibold text-base text-black mb-2 truncate">
                                            Категория
                                        </label>
                                        <Field as="select" name="type" className="border-gray-300">
                                            <option value="" disabled>
                                                Не выбрано
                                            </option>
                                            <option value="1">Магазин</option>
                                            <option value="2">Услуги</option>
                                        </Field>
                                    </div>
                                    <div className="mb-5 flex items-center w-full">
                                        <label htmlFor="name_en" className="font-semibold text-base text-black mr-2 truncate">
                                            Активность раздела
                                        </label>
                                        <Field id="active" name="active" type="checkbox" className="border border-gray-300 p-2" />
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex flex-row items-center justify-end">
                                <button
                                    type="button"
                                    className=" h-10 bg-transparent text-gray-700 px-6 transition-colors hover:text-black mr-4"
                                    onClick={() => closeHandler(resetForm)}
                                >
                                    Отмена
                                </button>
                                <button
                                    type="submit"
                                    className={`w-[135px] h-10 flex items-center justify-center text-white px-6 ${
                                        isSubmitting ? 'bg-gray-500' : 'bg-green-500 transition-colors hover:bg-green-600'
                                    }`}
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? <RotatingLines width="20" strokeColor="white" /> : 'Сохранить'}
                                </button>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
