import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BallTriangle } from 'react-loader-spinner';

import {
    chat_list as chat_list_state,
    getChatListFirstRender,
    getChatListInBackground,
    chat_list_fetching as chat_list_fetching_state,
    chat_filter as chat_filter_state,
    chat_list_fetching as chat_list_fetchin_state,
} from '../../redux/slices/chatSlice';
import { access_token as access_token_state } from '../../redux/slices/loginSlice';

export const List = ({ setBookingId, bookingId, tab }) => {
    const [changeHotel, setChangeHotel] = useState(false);

    const dispatch = useDispatch();
    const access_token = useSelector(access_token_state);
    const chat_list = useSelector(chat_list_state);
    const chat_list_fetching = useSelector(chat_list_fetching_state);
    const chat_filter = useSelector(chat_filter_state);
    const chat_list_fetchin = useSelector(chat_list_fetchin_state);

    const { hotel_id } = useParams();

    useEffect(() => {
        if (!changeHotel) {
            setChangeHotel(true);
        }
    }, [hotel_id]);

    useEffect(() => {
        if (!chat_list_fetching && changeHotel) {
            setChangeHotel(false);
        }
    }, [chat_list_fetching]);

    useEffect(() => {
        if (access_token && tab) {
            dispatch(getChatListFirstRender({ access_token, hotel_id: tab.hotel_id, search_str: chat_filter }));
            const interval = setInterval(async () => {
                await dispatch(getChatListInBackground({ access_token, hotel_id: tab.hotel_id, search_str: chat_filter }));
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [tab, access_token, chat_filter]);

    return (
        <div className={`w-full md:w-1/3 h-full overflow-auto shrink-0 md:border-r md:border-r-gray-10 ${bookingId ? 'hidden md:block' : 'block'}`}>
            {changeHotel || chat_list_fetchin ? (
                <div className="w-full h-full flex justify-center items-center">
                    <BallTriangle height="50" width="100" color="#3b82f6" ariaLabel="loading" />
                </div>
            ) : chat_list.length > 0 ? (
                chat_list.map(chat_item => (
                    <div
                        key={chat_item.booking_id}
                        className={`cursor-pointer relative w-full flex flex-col border-b border-b-gray-10 transition-colors ${
                            bookingId === chat_item.booking_id ? 'bg-blue-500 text-white' : 'bg-white hover:bg-blue-50'
                        }`}
                        onClick={changeHotel ? null : () => setBookingId(chat_item.booking_id)}
                    >
                        {/* guest status */}
                        {chat_item.status_color && (
                            <div
                                className={`w-2 h-2 absolute top-1 left-1 rounded rounded-br ${
                                    chat_item.status_color === 1 ? 'bg-green-500' : chat_item.status_color === 2 ? 'bg-yellow-300' : 'bg-gray-300'
                                }`}
                            ></div>
                        )}

                        {/* room number  */}
                        <div className={`font-semibold text-lg px-6 pt-4 `}>
                            {chat_item.date_to ? '' : 'Номер'} {chat_item.room_number}
                        </div>

                        {/* guest name */}
                        <div className={`text-sm truncate px-6 ${chat_item.status_color ? '' : 'pb-4'}`}>{chat_item.name}</div>

                        {/* dates of stay */}
                        {chat_item.date_from && (
                            <div className="text-xs px-6 flex items-center pb-4">
                                <span>{chat_item.date_from}-</span>

                                <span> {chat_item.date_to}</span>
                            </div>
                        )}

                        {/* contract status */}
                        {chat_item.podpislon === 'НЕ ПОДПИСАН' && (
                            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" className="absolute right-1 top-1">
                                <path
                                    d="M14.5 11.5L12 14M12 14L9.5 16.5M12 14L9.50002 11.5M12 14L14.5 16.5M13 3H5V21H19V9M13 3H14L19 8V9M13 3V7C13 8 14 9 15 9H19"
                                    stroke="#9ca3af"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        )}

                        {/* unread messages */}
                        {chat_item.unreadCount > 0 && (
                            <div className="absolute h-6 min-w-[1.5rem] text-center right-1 top-[calc(50%-0.75rem)] bg-red-600 text-white rounded-full p-0.5 text-xs">
                                {chat_item.unreadCount}
                            </div>
                        )}
                    </div>
                ))
            ) : (
                <div className="p-3 text-center">По вашему запросу чатов не найдено</div>
            )}
        </div>
    );
};
