import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RotatingLines } from 'react-loader-spinner';

import { sendMessage, message_send_fetch } from '../../redux/slices/chatSlice';
import { access_token as access_token_state } from '../../redux/slices/loginSlice';
import { useAutosizeTextArea } from '../common/useAutosizeTextArea';
import { getOs } from '../../api/functions';
import { errorNotification, getJpgFromHeic } from '../../api/functions';

export const MessageForm = ({ booking_id, tab }) => {
    const [message, setMessage] = useState('');
    const [blob, setBlob] = useState(null);
    const [file, setFile] = useState(null);
    const [blobFetch, setBlobFetch] = useState(false);

    const dispatch = useDispatch();
    const access_token = useSelector(access_token_state);
    const messageSendFetch = useSelector(message_send_fetch);

    const textAreaRef = useRef(null);
    const fileRef = useRef(null);

    useEffect(() => {
        if (booking_id) {
            setMessage('');
        }
    }, [booking_id]);

    const buttonHandler = async () => {
        if (booking_id && access_token && tab && !messageSendFetch && (message || file)) {
            await dispatch(sendMessage({ access_token, text: message, booking_id, hotel_id: tab.hotel_id, file }));
            setMessage('');
            setBlob(null);
            setFile(null);
            fileRef.current.value = '';
        }
    };

    const hotKeyHandler = async e => {
        if (e.keyCode === 13 && (e.ctrlKey || e.metaKey)) {
            await buttonHandler();
        }
    };

    const handleFile = async e => {
        const photo = e.target.files[0];
        const regex = /^image\/(jpg|jpeg|png|jfif|heic|webp)$/i;

        if (photo && regex.test(photo.type) && photo.size < 10485760) {
            if (photo.type.includes('heic')) {
                setBlobFetch(true);

                const photoJpg = await getJpgFromHeic(photo);

                setBlob(photoJpg);
                setFile(photo);

                setBlobFetch(false);
            } else {
                setBlob(URL.createObjectURL(photo));
                setFile(photo);
            }
        } else {
            errorNotification('Для отправки доступны только файлы с расширениями jpg, jpeg, png, jfif, heic, webp и размером менее 10 МБ');
        }
    };

    const handleRemoveImg = () => {
        fileRef.current.value = '';
        setBlob(null);
        setFile(null);
    };

    useAutosizeTextArea(textAreaRef.current, message);

    return (
        <div>
            {blob && (
                <div className="my-2 pr-6 relative">
                    <img src={blob} width="160" alt="add chat photo" className="rounded-lg ml-auto" />
                    <button className="absolute right-7 top-1" type="button" onClick={handleRemoveImg}>
                        <svg width="24" height="24" viewBox="0 0 32 32" fill="none">
                            <rect width="32" height="32" rx="16" fill="white" fillOpacity="0.7" />
                            <path d="M20 20L12 12" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M20 12L12 20" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                </div>
            )}
            <div className="shrink-0 h-auto flex flex-row items-end border-t border-t-gray-10 sticky bottom-0 bg-white z-20">
                {!blobFetch && <input ref={fileRef} type="file" name="qrСod" id="chatFile" onChange={e => handleFile(e)} className="hidden" />}
                <label htmlFor="chatFile" className={`${blobFetch ? '' : 'cursor-pointer'} absolute left-3.5 mb-3.5`}>
                    {blobFetch ? (
                        <RotatingLines width="28" strokeColor="blue" />
                    ) : (
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M14 2C20.6274 2 26 7.37258 26 14C26 20.6274 20.6274 26 14 26C7.37258 26 2 20.6274 2 14C2 7.37258 7.37258 2 14 2ZM14 4C8.47715 4 4 8.47715 4 14C4 19.5228 8.47715 24 14 24C19.5228 24 24 19.5228 24 14C24 8.47715 19.5228 4 14 4ZM14 8C14.5523 8 15 8.44772 15 9V13H19C19.5523 13 20 13.4477 20 14C20 14.5523 19.5523 15 19 15H15V19C15 19.5523 14.5523 20 14 20C13.4477 20 13 19.5523 13 19V15H9C8.44772 15 8 14.5523 8 14C8 13.4477 8.44772 13 9 13H13V9C13 8.44772 13.4477 8 14 8Z"
                                fill="#3482F6"
                            />
                        </svg>
                    )}
                </label>
                <textarea
                    name="message"
                    placeholder="Сообщение..."
                    className="w-full h-full resize-none pl-14 pr-14 md:pl-14 py-4 border-none focus:ring-0 max-h-32"
                    onChange={e => setMessage(e.target.value)}
                    onKeyDown={hotKeyHandler}
                    value={message}
                    ref={textAreaRef}
                ></textarea>
                <div className="absolute right-3.5 w-11 h-11 flex flex-col items-center mb-1">
                    <button
                        type="button"
                        className="shrink-0 h-8 w-8 flex items-center justify-center self-center rounded-full transition-colors bg-gray-200 hover:bg-gray-100"
                        onClick={buttonHandler}
                        disabled={messageSendFetch}
                    >
                        {messageSendFetch ? (
                            <RotatingLines width="20" strokeColor="blue" />
                        ) : (
                            <svg className="w-4 h-4 fill-black" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12.2929 20.0207L0.979187 8.70703L20.0711 0.928857L12.2929 20.0207ZM11.5858 16.4852L16.4295 4.57046L4.51472 9.41414L6.98959 11.889L12.2929 8.70703L9.11091 14.0103L11.5858 16.4852ZM11.5858 16.4852L4.51472 9.41414L9.11091 14.0103L11.5858 16.4852Z"
                                    fillOpacity="0.45"
                                />
                            </svg>
                        )}
                    </button>
                    <div className="text-[0.55rem] text-gray-400">{getOs() === 'Mac' ? '⌘' : 'Ctrl'}+Enter</div>
                </div>
            </div>
        </div>
    );
};
