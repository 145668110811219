import { useEffect, useState, useRef } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { RotatingLines } from 'react-loader-spinner';

import { Header } from './Header';
import { ContentWrapper } from '../common/ContentWrapper';
import { Icons } from './templatesPages/templatePart/Icons';
import { Photo } from './templatesPages/templatePart/Photo';
import { Activity } from './templatesPages/templatePart/Activity';
import { Sort } from './templatesPages/templatePart/Sort';
import { CustomReactQuill } from '../common/CustomReactQuill';
import { getUpdateString, successNotification, errorNotification } from '../../api/functions';

import {
    editInformation,
    set_update_info_fetch,
    set_response_status,
    response_status as response_status_state,
    information_tabs as information_tabs_state,
    updateInfoFetch as updateInfoFetch_state,
    information_icons as information_icons_state,
} from '../../redux/slices/informationSlice';
import { access_token as access_token_state } from '../../redux/slices/loginSlice';
import { HotelServicesAccord } from './HotelServicesAccord';

const ServicesSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Поле "Название" слишком короткое').required('Поле "Название" обязательное'),
    name_en: Yup.string().min(2, 'Поле "Название (на англ)" слишком короткое').required('Поле "Название (на англ)" обязательное'),
    preview_description: Yup.string().nullable().min(2, 'Поле "Время работы" слишком короткое'),
    preview_description_en: Yup.string().nullable().min(2, 'Поле "Время работы (на англ)" слишком короткое'),
    preview_description2: Yup.string().nullable().min(2, 'Поле "Локация" слишком короткое').max(20, 'Поле "Локация" слишком длинное'),
    preview_description2_en: Yup.string().nullable().min(2, 'Поле "Локация (на англ)" слишком короткое').max(20, 'Поле "Локация (на англ)" слишком длинное'),
    detail_description: Yup.string().nullable().min(12, 'Поле "Описание" слишком короткое').required('Поле "Описание" обязательное'),
    detail_description_en: Yup.string().nullable().min(12, 'Поле "Описание (на англ)" слишком короткое').required('Поле "Описание (на англ)" обязательное'),
    sort: Yup.number().typeError('В поле "Сортировка" для ввода доступны только числа').positive('В поле сортировки должно быть положительное число'),
    files: Yup.array().min(1, 'Добавьте фото раздела').required('Добавьте фото раздела'),
    svg_current: Yup.string().required('Выберите иконку для раздела'),
});

export const HotelServices = ({ tab_info }) => {
    const [initValues, setInitValues] = useState({
        name: '',
        name_en: '',
        detail_description: '',
        detail_description_en: '',
        preview_description: '',
        preview_description_en: '',
        preview_description2: '',
        preview_description2_en: '',
        sort: '',
        files: [],
        active: null,
    });
    const [fetchAccessToken, setFetchAccessToken] = useState('');
    const [template, setTemplate] = useState(null);
    const [jsonInfo, setJsonInfo] = useState(null);

    const [idSvg, setIdSvg] = useState('');
    const [currentSvg, setCurrentSvg] = useState(null);

    const formikRef = useRef();

    const dispatch = useDispatch();
    const access_token = useSelector(access_token_state);
    const response_status = useSelector(response_status_state);
    const information_tabs = useSelector(information_tabs_state);
    const updateInfoFetch = useSelector(updateInfoFetch_state);
    const information_icons = useSelector(information_icons_state);

    useEffect(() => {
        if (response_status) {
            successNotification();
            dispatch(set_response_status(false));
        }
    }, [response_status]);

    useEffect(() => {
        const tab = information_tabs?.find(el => el.id === tab_info.id);
        if (tab) {
            const json = tab.json ? JSON.parse(tab.json) : null;
            json ? setTemplate('templ3') : setTemplate('templ1');
            setJsonInfo(json);
            setInitValues({
                name: tab.name,
                name_en: tab.name_en,
                detail_description: tab.detail_description,
                detail_description_en: tab.detail_description_en,
                preview_description: tab.preview_description ? tab.preview_description : '',
                preview_description_en: tab.preview_description_en ? tab.preview_description_en : '',
                preview_description2: tab.preview_description2 ? tab.preview_description2 : '',
                preview_description2_en: tab.preview_description2_en ? tab.preview_description2_en : '',
                files: tab.files2 ? tab.files2 : [],
                active: tab.active === '1' ? ['active'] : [],
                sort: tab.sort,
                svg_current: tab.svg_current,
            });
            setCurrentSvg(tab.svg_current);
            setIdSvg('');
        }
    }, [tab_info]);

    const formSubmit = async (values, { setSubmitting, resetForm }) => {
        setFetchAccessToken(access_token);
        dispatch(set_update_info_fetch(true));

        let status;
        const detail_en = await getUpdateString(values.detail_description_en, tab_info, access_token);
        const detail_ru = await getUpdateString(values.detail_description, tab_info, access_token);

        if (detail_ru.length > 10000 || detail_en.length > 10000) {
            dispatch(set_update_info_fetch(false));
            return errorNotification('Уменьшите описание');
        }

        const data = {
            ...values,
            detail_description: detail_ru,
            detail_description_en: detail_en,
            tab_item_id: tab_info.id,
            hotel_id: tab_info.hotel_id,
            active: values.active[0] === 'active' ? '1' : '0',
            section_link: tab_info.section_link,
        };
        status = await dispatch(editInformation({ access_token, data }));

        if (status === 200) {
            setSubmitting(false);
            resetForm();
            setIdSvg('');
        }
    };

    useEffect(() => {
        (async () => {
            if (fetchAccessToken && fetchAccessToken !== access_token) {
                await formSubmit(formikRef.current.values, formikRef.current);
            }
        })();
    }, [access_token]);

    const handleSvgClick = (e, setFieldValue) => {
        const ev = e.target.closest('div').dataset.svg;
        if (ev) {
            const icon = information_icons.find(el => el.id === Number(ev));
            setIdSvg(Number(ev));
            setFieldValue('svg_current', icon.icon);
        }
    };

    const sortDispatch = data => {
        setInitValues(prevState => ({ ...prevState, files: data }));
    };

    return (
        <>
            <Header tab={tab_info} />

            {template === 'templ1' ? (
                <ContentWrapper>
                    <Formik initialValues={initValues} validationSchema={ServicesSchema} innerRef={formikRef} onSubmit={formSubmit} enableReinitialize={true}>
                        {({ values, errors, isValidating, isSubmitting, dirty, setFieldValue }) => {
                            if (isSubmitting && !isValidating) {
                                for (const err in errors) {
                                    errorNotification(errors[err].toString());
                                }
                            }
                            return (
                                <Form>
                                    <div className="sm:flex ">
                                        {/* main info */}
                                        <div className=" sm:w-1/2 bg-white">
                                            <div className="flex items-center border-b">
                                                <p className="pb-6 pt-6 pl-6 font-medium">Укажите основную информацию</p>
                                                <div className="ml-auto mr-6" data-tip="Информация">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path
                                                            d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                            stroke="#1890FF"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path
                                                            d="M11.9502 8H12.0502V8.1H11.9502V8Z"
                                                            stroke="#1890FF"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>

                                            {/* name */}
                                            <div className="mt-3 pl-6 pr-6">
                                                <p className="text-sm mb-2 text-gray-20 flex w-full">Название раздела</p>
                                                <Field
                                                    name="name"
                                                    component="textarea"
                                                    value={values.name}
                                                    placeholder="Введите название раздела"
                                                    className="box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2"
                                                    disabled={isSubmitting}
                                                />
                                            </div>

                                            {/* name EN */}
                                            <div className="mt-3 pl-6 pr-6">
                                                <p className="text-sm mb-2 text-gray-20 flex w-full">Section name</p>
                                                <Field
                                                    name="name_en"
                                                    component="textarea"
                                                    value={values.name_en}
                                                    placeholder="Enter section name"
                                                    className="box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2"
                                                    disabled={isSubmitting}
                                                />
                                            </div>

                                            {/* working hours */}
                                            <div className="mt-3 pl-6 pr-6">
                                                <p className="text-sm mb-2 text-gray-20 flex w-full">Время работы</p>
                                                <Field
                                                    name="preview_description"
                                                    component="textarea"
                                                    value={values.preview_description}
                                                    placeholder="Введите время работы"
                                                    className="box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2"
                                                    disabled={isSubmitting}
                                                />
                                            </div>

                                            {/* working hours EN */}
                                            <div className="mt-3 pl-6 pr-6">
                                                <p className="text-sm mb-2 text-gray-20 flex w-full">Working hours</p>
                                                <Field
                                                    name="preview_description_en"
                                                    component="textarea"
                                                    value={values.preview_description_en}
                                                    placeholder="Enter the hotel address"
                                                    className="box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2"
                                                    disabled={isSubmitting}
                                                />
                                            </div>

                                            {/* location */}
                                            <div className="mt-3 pl-6 pr-6">
                                                <p className="text-sm mb-2 text-gray-20 flex w-full">Локация</p>
                                                <Field
                                                    name="preview_description2"
                                                    component="textarea"
                                                    value={values.preview_description2}
                                                    placeholder="Локация"
                                                    className="box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2"
                                                    disabled={isSubmitting}
                                                />
                                            </div>

                                            {/* location en */}
                                            <div className="mt-3 pl-6 pr-6 ">
                                                <p className="text-sm mb-2 text-gray-20 flex w-full">Location</p>
                                                <Field
                                                    name="preview_description2_en"
                                                    component="textarea"
                                                    value={values.preview_description2_en}
                                                    placeholder="Location"
                                                    className="box-border w-full h-8 text-sm resize-none rounded border-gray-10 p-1 pl-2"
                                                    disabled={isSubmitting}
                                                />
                                            </div>

                                            {/* description */}
                                            <div className="mt-3 pl-6 pr-6">
                                                <p className="text-sm mb-2 text-gray-20 flex w-full">Описание</p>
                                                <Field
                                                    name="detail_description"
                                                    value={values.detail_description}
                                                    placeholder="Описание"
                                                    className="box-border w-full h-24 text-sm  rounded border-gray-10 p-1 pl-2"
                                                    disabled={isSubmitting}
                                                >
                                                    {({ field, imageHandler }) => (
                                                        <CustomReactQuill
                                                            imageHandler={imageHandler}
                                                            value={field.value}
                                                            onChange={field.onChange(field.name)}
                                                            placeholder="Описание..."
                                                        />
                                                    )}
                                                </Field>
                                            </div>

                                            {/* description EN */}
                                            <div className="mt-3 pl-6 pr-6 pb-6">
                                                <p className="text-sm mb-2 text-gray-20 flex w-full">Description</p>
                                                <Field
                                                    name="detail_description_en"
                                                    value={values.detail_description_en}
                                                    placeholder="description"
                                                    className="box-border w-full h-24 text-sm rounded border-gray-10 p-1 pl-2"
                                                    disabled={isSubmitting}
                                                >
                                                    {({ field, imageHandler }) => (
                                                        <CustomReactQuill
                                                            imageHandler={imageHandler}
                                                            value={field.value}
                                                            onChange={field.onChange(field.name)}
                                                            placeholder="Description..."
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                        </div>

                                        <div className="pb-6 mt-3 sm:w-1/2 bg-white sm:ml-6 sm:mt-0">
                                            {/* photo */}
                                            <Photo setFieldValue={setFieldValue} files={values.files} sortDispatch={sortDispatch} />

                                            {/* activity */}
                                            <div className=" border-b border-t mt-6">
                                                <Activity />
                                            </div>

                                            {/* sort */}
                                            <div className="flex items-center border-b ">
                                                <Sort values={values} isSubmitting={isSubmitting} />
                                            </div>

                                            {/* icon */}
                                            <div>
                                                <Icons
                                                    dataSvg={information_icons}
                                                    handleSvgClick={handleSvgClick}
                                                    idSvg={idSvg}
                                                    setFieldValue={setFieldValue}
                                                    currentSvg={currentSvg}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <button
                                        type="submit"
                                        className={` mt-3 rounded-sm font-medium text-sm px-14 h-10  ${
                                            dirty ? 'bg-blue-500 text-white cursor-pointer' : 'bg-slate-300 cursor-default text-gray-400'
                                        } `}
                                        disabled={!dirty || updateInfoFetch}
                                    >
                                        {updateInfoFetch ? <RotatingLines width="20" strokeColor="white" /> : 'Сохранить'}
                                    </button>
                                </Form>
                            );
                        }}
                    </Formik>
                </ContentWrapper>
            ) : (
                <HotelServicesAccord tab_info={tab_info} initValuesProp={initValues} jsonInfo={jsonInfo} />
            )}

            <ReactTooltip textColor="#FCFCFC" backgroundColor="#8c8d9d" effect="solid" className="!rounded-md !py-2 !px-2.5" />
        </>
    );
};
