import { useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTable } from 'react-table';
import { BallTriangle } from 'react-loader-spinner';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
    booking_history as booking_history_state,
    history_page as history_page_state,
    set_history_page,
    history_count as history_count_state,
    getBookingHistory,
} from '../../../redux/slices/meetingRoomSlice';

export const Table = () => {
    const dispatch = useDispatch();
    const booking_history = useSelector(booking_history_state);
    const history_page = useSelector(history_page_state);
    const history_count = useSelector(history_count_state);

    useEffect(() => {
        dispatch(getBookingHistory({ page: history_page }));
    }, [history_page]);

    const columns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'ФИО',
                accessor: 'fio',
            },
            {
                Header: 'Переговорная',
                accessor: 'name',
            },
            {
                Header: 'Статус',
                accessor: 'active',
            },
            {
                Header: 'Начало',
                accessor: 'start',
            },
            {
                Header: 'Конец',
                accessor: 'end',
            },
            {
                Header: 'Срок',
                accessor: 'period',
            },
            {
                Header: 'Дата создания',
                accessor: 'date_create',
            },
            {
                Header: 'Дата проведения',
                accessor: 'date',
            },
            {
                Header: 'Комментарий',
                accessor: 'comment',
            },
        ],
        []
    );

    const data = useMemo(() => booking_history || [], [booking_history]);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

    const count = history_count - booking_history.length;
    return (
        <div className=" p-4 sm:p-6 w-full max-w-9xl mx-auto pb-4 overflow-x-auto">
            <InfiniteScroll
                next={() => dispatch(set_history_page(history_page + 1))}
                hasMore={count > 0 && history_count > 100}
                loader={
                    <div className="w-full h-20 flex items-center justify-center">
                        {count > 0 && <BallTriangle height="50" width="100" color="#3b82f6" ariaLabel="loading" />}
                    </div>
                }
                dataLength={rows.length}
                scrollableTarget="wrapper"
            >
                <table {...getTableProps()} className={`w-full min-w-[800px] bg-white `}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        {...column.getHeaderProps({
                                            style: { width: column.width },
                                        })}
                                        className="border border-gray-100 px-4 py-3 "
                                    >
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        if (cell.column.id === 'active') {
                                            return (
                                                <td
                                                    {...cell.getCellProps({
                                                        style: {
                                                            width: cell.column.width,
                                                        },
                                                    })}
                                                    className={` border border-gray-100 px-4 py-3 text-sm`}
                                                >
                                                    {cell.value === '1' ? 'Состоялось' : 'Отменено'}
                                                </td>
                                            );
                                        }
                                        if (cell.column.id === 'comment') {
                                            return (
                                                <td
                                                    {...cell.getCellProps({
                                                        style: {
                                                            width: cell.column.width,
                                                        },
                                                    })}
                                                    className={` border border-gray-100 px-4 py-3 text-sm`}
                                                >
                                                    <span className="block max-h-32 overflow-y-auto break-all"> {cell.value}</span>
                                                </td>
                                            );
                                        }
                                        if (cell.column.id === 'fio') {
                                            return (
                                                <td
                                                    {...cell.getCellProps({
                                                        style: {
                                                            width: cell.column.width,
                                                        },
                                                    })}
                                                    className={` border border-gray-100 px-4 py-3 text-sm`}
                                                >
                                                    <span className="block max-h-32 overflow-y-auto "> {cell.value}</span>
                                                </td>
                                            );
                                        }
                                        if (cell.column.id === 'date_create') {
                                            const date = cell.value.split(':');
                                            const correctDateFormat = [date[0], date[1]];
                                            return (
                                                <td
                                                    {...cell.getCellProps({
                                                        style: {
                                                            width: cell.column.width,
                                                        },
                                                    })}
                                                    className={` border border-gray-100 px-4 py-3 text-sm`}
                                                >
                                                    {correctDateFormat.join(':')}
                                                </td>
                                            );
                                        }
                                        return (
                                            <td
                                                {...cell.getCellProps({
                                                    style: {
                                                        width: cell.column.width,
                                                    },
                                                })}
                                                data-label={cell.column.Header}
                                                className="border border-gray-100 px-4 py-3 text-sm"
                                            >
                                                <span className="block max-h-32">{cell.render('Cell')}</span>
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </InfiniteScroll>
        </div>
    );
};
