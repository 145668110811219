import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { RotatingLines } from 'react-loader-spinner';
import { editUser, users as users_state } from '../../../redux/slices/loyaltySlice';
import { access_token as access_token_state } from '../../../redux/slices/loginSlice';

const initState = {
    loyalty_status_id: '',
    comment: '',
};

const statusArr = [
    {
        id: '1',
        name: 'Участник',
    },
    {
        id: '2',
        name: 'Серебряный статус',
    },
    {
        id: '3',
        name: 'Золотой статус',
    },
    {
        id: '4',
        name: 'Платиновый статус',
    },
];

export const Edit = ({ userId, setUserId }) => {
    const dispatch = useDispatch();
    const formikRef = useRef();
    const users = useSelector(users_state);
    const access_token = useSelector(access_token_state);

    const [initialState, setInitialState] = useState(initState);
    const [user, setUser] = useState(null);
    const [fetchAccessToken, setFetchAccessToken] = useState('');

    const onSubmitHandler = async (values, resetForm) => {
        setFetchAccessToken(access_token);
        let status;
        status = await dispatch(
            editUser({
                access_token,
                data: {
                    ...values,
                    guest_id: userId,
                    loyalty_status_name: statusArr.find(el => el.id === values.loyalty_status_id).name,
                },
            })
        );

        if (status === 200) {
            closeHandler(resetForm);
        }
    };

    useEffect(() => {
        (async () => {
            if (fetchAccessToken && fetchAccessToken !== access_token) {
                await onSubmitHandler(formikRef.current.values, formikRef.current.resetForm);
            }
        })();
    }, [access_token]);

    const closeHandler = resetForm => {
        setUserId(null);
        setUser(null);
        resetForm();
        setInitialState(initState);
    };

    useEffect(() => {
        if (userId) {
            const user = users.find(el => el.id === userId);
            if (user) {
                setUser(user);
            }
        }
    }, [userId]);

    useEffect(() => {
        if (user && user.hasOwnProperty('loyalty_status_id')) {
            setInitialState({
                loyalty_status_id: user.loyalty_status_id,
                comment: user.comment,
            });
        }
    }, [user]);

    return (
        <Formik
            initialValues={initialState}
            innerRef={formikRef}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => await onSubmitHandler(values, resetForm)}
        >
            {({ values, errors, isSubmitting, setFieldValue, resetForm }) => {
                return (
                    <Form
                        className={`${
                            userId ? 'flex' : 'hidden'
                        } items-center justify-center fixed inset-0 bg-slate-900 bg-opacity-30 z-40 transition-opacity duration-200 opacity-100`}
                    >
                        <div className="flex flex-col bg-white w-[400px] max-w-full p-6 max-h-[97vh] overflow-auto">
                            <div>Пользователь {user && user.hasOwnProperty('name') && user.name}</div>
                            <div className="p-4 w-full flex flex-row flex-wrap justify-between content-start">
                                <div className="mb-5 flex flex-col w-full">
                                    <label htmlFor="loyalty_status_id" className="font-semibold text-base text-black mb-2 truncate">
                                        Статус
                                    </label>
                                    <Field as="select" name="loyalty_status_id" className="border-gray-300">
                                        {statusArr.map(status => (
                                            <option key={`status${status.id}`} value={status.id}>
                                                {status.name}
                                            </option>
                                        ))}
                                    </Field>
                                </div>

                                <div className="mb-5 flex flex-col w-full">
                                    <label htmlFor="comment" className="font-semibold text-base text-black mb-2 truncate">
                                        Комментарий
                                    </label>
                                    <Field id="comment" name="comment" component="textarea" className="border border-gray-300 p-2" />
                                </div>
                            </div>

                            <div className="w-full flex flex-row items-center justify-end">
                                <button
                                    type="button"
                                    className=" h-10 bg-transparent text-gray-700 px-6 transition-colors hover:text-black mr-4"
                                    onClick={() => closeHandler(resetForm)}
                                >
                                    Отмена
                                </button>
                                <button
                                    type="submit"
                                    className={`w-[135px] h-10 flex items-center justify-center text-white px-6 ${
                                        isSubmitting ? 'bg-gray-500' : 'bg-green-500 transition-colors hover:bg-green-600'
                                    }`}
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? <RotatingLines width="20" strokeColor="white" /> : 'Сохранить'}
                                </button>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
