import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SidebarMenuLinkGroupButton } from './SidebarMenuLinkGroupButton';
import { SidebarMenuHotelTab } from './SidebarMenuHotelTab';
import { SidebarMenuItem } from './SidebarMenuItem';
import { SidebarMenuGroup } from './SidebarMenuGroup';

export const SidebarMenuHotel = ({ hotel }) => {
    const { pathname } = useLocation();

    const [isOpen, setIsOpen] = useState(pathname.includes(`object/${hotel.id}`));

    return (
        <li className="mb-2 last:mb-0">
            <SidebarMenuLinkGroupButton title={hotel.Name} isOpen={isOpen} setIsOpen={setIsOpen} type="bold" />

            <div className="bg-slate-50">
                <ul className={`mt-1 ${!isOpen && 'hidden'}`}>
                    {hotel.manage_additional_html && (
                        <SidebarMenuItem title="Уведомления" icon="notification" link={`/object/${hotel.id}/manage_additional/`} />
                    )}

                    <SidebarMenuItem title="Пуш уведомления" icon="push" link={`/object/${hotel.id}/push_notification/`} />

                    {hotel.subitems &&
                        hotel.subitems
                            .filter(tab => tab.link !== 'room-management')
                            .map(tab => <SidebarMenuHotelTab hotel={hotel} tab={tab} key={`${tab.code}${tab.sort}`} />)}

                    {/* скрыта программа лояльности, так как у отеля ее пока нет */}
                    {/* <SidebarMenuGroup title="Программа лояльности" icon="loyalty" url={`/object/${hotel.id}/loyalty`}>
                        <SidebarMenuItem title="Заезжающие гости" style="ml-8" link={`/object/${hotel.id}/loyalty/arriving/`} />
                        <SidebarMenuItem title="Форма редактирования" style="ml-8" link={`/object/${hotel.id}/loyalty/form/`} />
                    </SidebarMenuGroup> */}

                    {+hotel.id === 702 && (
                        <SidebarMenuGroup title="Услуги" icon="services" url={`/object/${hotel.id}/services`}>
                            <SidebarMenuItem title="Тарифы" style="ml-8" link={`/object/${hotel.id}/services/tariffs/`} />
                        </SidebarMenuGroup>
                    )}
                </ul>
            </div>
        </li>
    );
};
