import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { chat_list as chat_list_state, setMessagesAsRead, chat_list_fetching as chat_list_fetchin_state } from '../../redux/slices/chatSlice';
import { MessageForm } from './MessageForm';
import { Fancybox } from '../common/Fancybox';
import { access_token as access_token_state } from '../../redux/slices/loginSlice';

export const Detail = ({ setBookingId, bookingId, tab }) => {
    const [roomNumber, setRoomNumber] = useState('');
    const [messageList, setMessageList] = useState([]);

    const dispatch = useDispatch();
    const chat_list = useSelector(chat_list_state);
    const access_token = useSelector(access_token_state);
    const chat_list_fetchin = useSelector(chat_list_fetchin_state);

    useEffect(() => {
        if (bookingId) {
            setRoomNumber(chat_list.find(el => el.booking_id === bookingId)?.room_number);
            setMessageList(chat_list.find(el => el.booking_id === bookingId)?.messages);
            const unreadCount = chat_list.find(el => el.booking_id === bookingId)?.unreadCount;
            if (unreadCount) {
                dispatch(setMessagesAsRead({ access_token, booking_id: bookingId }));
            }
        }
    }, [bookingId, chat_list]);

    useEffect(() => {
        setRoomNumber('');
        setMessageList([]);
        setBookingId('');
    }, [chat_list_fetchin]);

    if (!bookingId) {
        return (
            <div className="w-full h-full hidden md:flex items-center justify-center">
                <div className="px-6 py-3 rounded-[100px] bg-gray-200 font-semibold">Выберите, кому хотите написать</div>
            </div>
        );
    }

    return (
        <div className="flex flex-col w-full h-full md:h-auto">
            {/*Mobile nav*/}
            <div className="sticky top-0 bg-white w-full flex justify-center items-center md:hidden p-3 border-b border-b-gray-10">
                <button type="button" onClick={() => setBookingId('')} className="flex items-center gap-2 mr-auto w-20">
                    <svg className="w-3 h-5 fill-blue-500" viewBox="0 0 12 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 9.93896C0 10.3208 0.145996 10.6465 0.449219 10.9385L9.20898 19.5073C9.44482 19.7544 9.75928 19.8779 10.1187 19.8779C10.8486 19.8779 11.4214 19.3164 11.4214 18.5752C11.4214 18.2158 11.2754 17.8901 11.0283 17.6431L3.1333 9.93896L11.0283 2.23486C11.2754 1.97656 11.4214 1.65088 11.4214 1.2915C11.4214 0.561523 10.8486 0 10.1187 0C9.75928 0 9.44482 0.123535 9.20898 0.370605L0.449219 8.93945C0.145996 9.23145 0.0112305 9.55713 0 9.93896Z" />
                    </svg>
                    <span className="text-blue-500">Назад</span>
                </button>

                <span className="font-bold truncate">{roomNumber ? `Номер ${roomNumber}` : ''}</span>
                <span className="ml-auto w-20"></span>
            </div>

            {/*Messages list*/}
            <div className="flex flex-col-reverse h-full overflow-auto p-3 md:p-6">
                {messageList.length > 0
                    ? messageList.map((message, index) => {
                          let dateJXS = '';

                          if (messageList.length === index + 1 || message.date_add.slice(0, 10) !== messageList[index + 1].date_add.slice(0, 10)) {
                              dateJXS = (
                                  <div className="text-sm text-center my-6">
                                      {new Date(message.date_add)
                                          .toLocaleString('ru', {
                                              month: 'long',
                                              day: 'numeric',
                                              year: 'numeric',
                                          })
                                          .slice(0, -3)}
                                  </div>
                              );
                          }

                          return (
                              <React.Fragment key={message.id}>
                                  <div
                                      className={`relative flex flex-wrap items-end mb-3 rounded-[18px] w-64 max-w-[45%] text-sm pt-3 pb-1.5 px-3 after:block after:w-4 after:h-4 after:absolute ${
                                          message.from_admin === '1'
                                              ? 'bg-blue-500 text-white ml-auto after:content-bubbleSent after:-right-[3px] after:bottom-[3px]'
                                              : 'bg-gray-200 after:content-bubbleReceived after:-left-[3px] after:bottom-[3px]'
                                      }`}
                                  >
                                      {message.files &&
                                          message.files.map(pic => (
                                              <img
                                                  key={pic.id}
                                                  src={`${pic.base64 ? pic.base64 : pic.link}`}
                                                  alt="photo"
                                                  className="rounded-lg mb-3 cursor-pointer"
                                                  data-fancybox="gallery"
                                              />
                                          ))}
                                      <Fancybox />
                                      {message.message !== 'undefined' && (
                                          <span className="break-words w-full" dangerouslySetInnerHTML={{ __html: message.message }}></span>
                                      )}
                                      <span className={`text-xs z-10 ml-auto ${message.from_admin === '1' ? 'text-gray-100' : 'text-gray-400'}`}>
                                          {message.date_add.slice(11, 16)}
                                      </span>
                                      {message.is_user_read === '1' && message.from_admin && (
                                          <span className="ml-1 mb-1">
                                              <svg viewBox="0 0 16 11" height="11" width="16" fill="none">
                                                  <path
                                                      d="M11.0714 0.652832C10.991 0.585124 10.8894 0.55127 10.7667 0.55127C10.6186 0.55127 10.4916 0.610514 10.3858 0.729004L4.19688 8.36523L1.79112 6.09277C1.7488 6.04622 1.69802 6.01025 1.63877 5.98486C1.57953 5.95947 1.51817 5.94678 1.45469 5.94678C1.32351 5.94678 1.20925 5.99544 1.11192 6.09277L0.800883 6.40381C0.707784 6.49268 0.661235 6.60482 0.661235 6.74023C0.661235 6.87565 0.707784 6.98991 0.800883 7.08301L3.79698 10.0791C3.94509 10.2145 4.11224 10.2822 4.29844 10.2822C4.40424 10.2822 4.5058 10.259 4.60313 10.2124C4.70046 10.1659 4.78086 10.1003 4.84434 10.0156L11.4903 1.59863C11.5623 1.5013 11.5982 1.40186 11.5982 1.30029C11.5982 1.14372 11.5348 1.01888 11.4078 0.925781L11.0714 0.652832ZM8.6212 8.32715C8.43077 8.20866 8.2488 8.09017 8.0753 7.97168C7.99489 7.89128 7.8891 7.85107 7.75791 7.85107C7.6098 7.85107 7.4892 7.90397 7.3961 8.00977L7.10411 8.33984C7.01947 8.43717 6.97715 8.54508 6.97715 8.66357C6.97715 8.79476 7.0237 8.90902 7.1168 9.00635L8.1959 10.0791C8.33132 10.2145 8.49636 10.2822 8.69102 10.2822C8.79681 10.2822 8.89838 10.259 8.99571 10.2124C9.09304 10.1659 9.17556 10.1003 9.24327 10.0156L15.8639 1.62402C15.9358 1.53939 15.9718 1.43994 15.9718 1.32568C15.9718 1.1818 15.9125 1.05697 15.794 0.951172L15.4386 0.678223C15.3582 0.610514 15.2587 0.57666 15.1402 0.57666C14.9964 0.57666 14.8715 0.635905 14.7657 0.754395L8.6212 8.32715Z"
                                                      fill="currentColor"
                                                  ></path>
                                              </svg>
                                          </span>
                                      )}
                                  </div>
                                  {dateJXS}
                              </React.Fragment>
                          );
                      })
                    : 'Сообщений не найдено'}
            </div>

            {/*Message form*/}
            <MessageForm booking_id={bookingId} tab={tab} />
        </div>
    );
};
