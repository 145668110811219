import React from 'react';
import AuthImage from '../source/images/auth-image.svg';
import { Link } from 'react-router-dom';

export const NotFoundPage = () => {
    return (
        <div className="relative h-screen w-full md:flex flex-row">
            {/* Image */}
            <div className="hidden w-1/2 h-full md:flex md:items-center" aria-hidden="true">
                <img className="w-full" src={AuthImage} alt="Authentication" />
            </div>

            {/* Content */}
            <div className="w-full md:w-1/2">
                <div className="max-w-sm mx-auto min-h-screen flex flex-col justify-center px-4 py-8">
                    <div className="w-full flex flex-col items-center">
                        <h1 className="text-4xl font-semibold">Ничего не найдено</h1>
                        <Link to="/" className="text-lg mt-4 text-blue-500 hover:underline">
                            вернуться на главную
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};
