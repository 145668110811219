import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { BallTriangle } from 'react-loader-spinner';

import { tabs as tabs_state, tab_fetching as tab_fetching_state } from '../redux/slices/hotelSlice';
import {
    information_tabs as information_tabs_state,
    getHotelInformationFetch,
    infoFetch as infoFetch_state,
    getInfoIcons,
    information_icons as information_icons_state,
} from '../redux/slices/informationSlice';
import { access_token as access_token_state } from '../redux/slices/loginSlice';

import { HotelContacts } from '../components/informations/HotelContacts';
import { HotelAbout } from '../components/informations/HotelAbout';
import { HotelServices } from '../components/informations/HotelServices';
import { HotelWiFi } from '../components/informations/HotelWiFi';
import { HotelHearUs } from '../components/informations/HotelNearUs';
import { HotelNearLocation } from '../components/informations/HotelNearLocation';
import { HotelEvents } from '../components/informations/HotelEvents';
import { HotelRooms } from '../components/informations/HotelRooms';
import { HotelRoom } from '../components/informations/HotelRoom';
import { HotelGuestBook } from '../components/informations/HotelGuestBook';
import { HotelMain } from '../components/informations/HotelMain';
import { TemplatePage } from '../components/informations/TemplatePage';
import { TemplateInfo1 } from '../components/informations/templatesPages/TemplateInfo1';
import { HotelLaundry } from '../components/informations/laundry/HotelLaundry';
import { RoomsTemplate } from '../components/informations/templatesPages/RoomsTemplate';

export const InformationPage = () => {
    const [tab, setTab] = useState(null);
    const [infoTabs, setInfoTabs] = useState(null);

    const tabs = useSelector(tabs_state);

    const { hotel_id } = useParams();
    const { tab_code } = useParams();

    const location = useLocation();
    const dispatch = useDispatch();
    const information_tabs = useSelector(information_tabs_state);
    const access_token = useSelector(access_token_state);
    const infoFetch = useSelector(infoFetch_state);
    const tab_fetching = useSelector(tab_fetching_state);
    const information_icons = useSelector(information_icons_state);

    useEffect(() => {
        dispatch(getHotelInformationFetch({ access_token, hotel_id }));
    }, [hotel_id]);

    useEffect(() => {
        if (information_icons.length === 0) {
            dispatch(getInfoIcons());
        }
    }, []);

    useEffect(() => {
        if (tabs.length && hotel_id) {
            const hotel = tabs.find(hotel => {
                return hotel.id === hotel_id;
            });

            if (hotel) {
                setTab({
                    ...hotel.subitems.find(tab => tab.link === 'information'),
                    hotel_name: hotel.Name,
                });
            }
        }
    }, [tabs, hotel_id]);

    useEffect(() => {
        setInfoTabs(information_tabs);
    }, [information_tabs]);

    if (infoFetch || tab_fetching) {
        return (
            <div className="w-full flex justify-center mt-8">
                <BallTriangle height="50" width="100" color="#3b82f6" ariaLabel="loading" />
            </div>
        );
    }

    if (infoTabs && infoTabs.length !== 0) {
        if (tab_code === 'information_hotel_rooms' && tab !== null) {
            const roomsTab = {
                ...tab.subitems.find(el => el.link === tab_code),
                hotel_name: tab.hotel_name,
                hotel_id,
            };

            // страница номеров
            if (location.search === '') {
                return <HotelRooms tab={roomsTab} />;
            }

            // страница создания номера
            if (location.search.includes('new')) {
                const sub_tab_id = tab.subitems.find(tab => tab.code === 'information_hotel_rooms').id;
                const tab_info = {
                    hotel_name: tab.hotel_name,
                    hotel_id,
                    name: 'Добавить новый номер',
                    section_link: tab.link,
                    sub_tab_id,
                    sub_section_name: roomsTab.name,
                    // tab_code: 'information_hotel_rooms',
                };
                return <RoomsTemplate tab_info={tab_info} />;

                // страница номера
            } else {
                const locationName = location.search.split('=')[1];
                const sub_tab_info = infoTabs.find(el => el.link === tab_code);
                const tab_info = sub_tab_info.subitems.find(el => el.id.toLowerCase() === locationName);
                const roomTab = {
                    ...tab_info,
                    sub_tab_id: sub_tab_info.id,
                    hotel_name: tab.hotel_name,
                    hotel_id,
                    section_link: tab.link,
                    sub_section_name: roomsTab.name,
                };
                return <HotelRoom tab_info={roomTab} />;
            }
        }

        if (tab_code === 'information_contacts' && tab !== null) {
            const tab_info = infoTabs.find(el => el.link === tab_code);
            const contactsTab = {
                id: tab_info.id,
                hotel_name: tab.hotel_name,
                hotel_id,
                name: tab_info.name,
                section_link: tab.link,
            };
            return <HotelContacts tab_info={contactsTab} />;
        }

        if (tab_code === 'information_about' && tab !== null) {
            const tab_info = infoTabs.find(el => el.link === tab_code);
            const aboutTab = {
                id: tab_info.id,
                hotel_name: tab.hotel_name,
                hotel_id,
                name: tab_info.name,
                section_link: tab.link,
            };
            return <HotelAbout tab_info={aboutTab} />;
        }

        if (tab_code === 'information_wi_fi' && tab !== null) {
            const tab_info = infoTabs.find(el => el.link === tab_code);
            const wifiTab = {
                id: tab_info.id,
                hotel_name: tab.hotel_name,
                hotel_id,
                name: tab_info.name,
                section_link: tab.link,
            };
            return <HotelWiFi tab_info={wifiTab} />;
        }

        if (tab_code === 'information_events' && tab !== null) {
            const eventsTab = {
                ...tab.subitems.find(el => el.link === tab_code),
                hotel_name: tab.hotel_name,
                hotel_id,
                section_link: tab.link,
            };
            return <HotelEvents tab={eventsTab} />;
        }

        if (tab_code === 'information_guest_book' && tab !== null) {
            const guestBookTab = {
                ...tab.subitems.find(el => el.link === tab_code),
                hotel_name: tab.hotel_name,
                hotel_id,
                section_link: tab.link,
            };
            return <HotelGuestBook tab_info={guestBookTab} />;
        }

        if (tab_code === 'information_near_us' && tab !== null) {
            const nearTab = {
                ...tab.subitems.find(el => el.link === tab_code),
                hotel_name: tab.hotel_name,
                hotel_id,
            };
            if (location.search === '') {
                return <HotelHearUs tab={nearTab} />;
            } else {
                const locationName = location.search.split('=')[1];
                const sub_tab_info = infoTabs.find(el => el.link === tab_code);
                const tab_info = sub_tab_info.subitems.find(el => el.name_en.toLowerCase() === locationName);
                const nearLocationTab = {
                    ...tab_info,
                    sub_tab_id: sub_tab_info.id,
                    hotel_name: tab.hotel_name,
                    hotel_id,
                    section_link: tab.link,
                    sub_section_name: nearTab.name,
                };
                return <HotelNearLocation tab_info={nearLocationTab} />;
            }
        }

        if (tab_code === 'information_title_main_link' && tab !== null) {
            const tab_info = {
                ...tab.subitems.find(el => el.link === tab_code),
                hotel_name: tab.hotel_name,
                hotel_id,
                section_link: tab.link,
            };

            return <HotelMain tab_info={tab_info} />;
        }

        if (tab_code === 'template_info' && tab !== null) {
            const tab_info = {
                hotel_name: tab.hotel_name,
                hotel_id,
                name: 'Добавить новый раздел информации',
                tab_code: 'template_info',
                section_link: tab.link,
            };

            return <TemplatePage tab_info={tab_info} />;
        }

        if (tab_code === 'template' && tab !== null) {
            const tab_info = {
                hotel_name: tab.hotel_name,
                hotel_id,
                name: 'Добавить новый раздел информации',
                tab_code: 'template_info',
                section_link: tab.link,
            };

            return <TemplateInfo1 tab_info={tab_info} />;
        }

        if (tab_code === 'washing' && tab !== null) {
            const tab_info = {
                hotel_name: tab.hotel_name,
                hotel_id,
                name: 'Прачечная',
                tab_code: 'laundry',
                section_link: tab.link,
            };
            return <HotelLaundry tab={tab_info} />;
        }

        if (tab_code)
            if (
                tab_code !== 'information_about' &&
                tab_code !== 'information_contacts' &&
                tab_code !== 'information_hotel_rooms' &&
                tab_code !== 'information_booking' &&
                tab_code !== 'information_wi_fi' &&
                tab_code !== 'information_near_us' &&
                tab_code !== 'information_events' &&
                tab_code !== 'information_guest_book' &&
                tab !== null
            ) {
                const tab_info = infoTabs.find(el => el.link === tab_code);
                if (tab_info === undefined) {
                    return;
                }
                const servicesTab = {
                    id: tab_info.id,
                    hotel_name: tab.hotel_name,
                    hotel_id,
                    name: tab_info.name,
                    section_link: tab.link,
                };
                return <HotelServices tab_info={servicesTab} />;
            }
    }

    return <p className="m-6">Страница в разработке</p>;
};
