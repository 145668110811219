import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { RotatingLines } from 'react-loader-spinner';
import { Field, Form, Formik } from 'formik';

import { errorNotification } from '../../../api/functions';

import { addShopItem, editShopItem } from '../../../redux/slices/shopSlice';

import { SortablePicsLoader } from '../../common/SortablePicsLoader';
import { CustomReactQuill } from '../../common/CustomReactQuill';

const initState = {
    name: '',
    name_en: '',
    comment: '',
    comment_en: '',
    short_comment: '',
    short_comment_en: '',
    price: '',
    id: '',
    files: [],
};

const SignupSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Поле "Название" слишком короткое').max(100, 'Поле "Название" слишком длинное').required('Поле "Название" обязательное'),
    name_en: Yup.string()
        .min(2, 'Поле "Название (на англ)" слишком короткое')
        .max(100, 'Поле "Название (на англ)" слишком длинное')
        .required('Поле "Название (на англ)" обязательное'),
});

export const Detail = ({ item, setItem, hotelId }) => {
    const dispatch = useDispatch();
    const formikRef = useRef();
    const [initialState, setInitialState] = useState(initState);

    useEffect(() => {
        if (item && item.id !== 'new') {
            (async () => {
                setInitialState({
                    name: item.name || '',
                    name_en: item.name_en || '',
                    comment: item.comment || '',
                    comment_en: item.comment_en || '',
                    short_comment: item.short_comment || '',
                    short_comment_en: item.short_comment_en || '',
                    price: item.price || '',
                    id: item.id,
                    files: item.files2 ? item.files2 : [],
                });
            })();
        }
    }, [item]);

    const closeHandler = resetForm => {
        setItem(null);
        resetForm();
        setInitialState(initState);
    };

    const onSubmitHandler = async (values, resetForm) => {
        let res;

        if (item.id !== 'new') {
            res = await dispatch(editShopItem({ data: { ...values, hotel_id: hotelId, shop_id: item.shop_id, type: item.type } }));
        } else {
            res = await dispatch(addShopItem({ data: { ...values, hotel_id: hotelId, shop_id: item.shop_id, type: item.type } }));
        }

        if (res.status) {
            closeHandler(resetForm);
        } else {
            if (res.error) {
                errorNotification(res.error.toString());
            }
        }
    };

    const sortDispatch = data => {
        setInitialState({ ...formikRef.current.values, files: data });
    };

    return (
        <Formik
            initialValues={initialState}
            innerRef={formikRef}
            validationSchema={SignupSchema}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => await onSubmitHandler(values, resetForm)}
        >
            {({ values, errors, isValidating, isSubmitting, setFieldValue, resetForm }) => {
                if (isSubmitting && !isValidating) {
                    for (const err in errors) {
                        errorNotification(errors[err].toString());
                    }
                }

                return (
                    <Form className="flex items-center justify-center fixed inset-0 bg-slate-900 bg-opacity-30 z-40 transition-opacity duration-200 opacity-100">
                        <div className="flex flex-col bg-white w-[770px] max-w-full p-6 max-h-[97vh] overflow-auto">
                            <div className="w-full flex flex-row border border-gray-300 mb-6">
                                <div className="p-4 border-r border-r-gray-300 bg-gray-100 w-1/3">
                                    <SortablePicsLoader setFieldValue={setFieldValue} files={values.files} sortDispatch={sortDispatch} />
                                </div>
                                <div className="p-4 w-2/3 flex flex-row flex-wrap justify-between content-start">
                                    <div className="mb-5 flex flex-col w-full">
                                        <label htmlFor="name" className="font-semibold text-base text-black mb-2 truncate">
                                            Название
                                        </label>
                                        <Field id="name" name="name" type="text" className="border border-gray-300 p-2" />
                                    </div>
                                    <div className="mb-5 flex flex-col w-full">
                                        <label htmlFor="name_en" className="font-semibold text-base text-black mb-2 truncate">
                                            Название (на англ)
                                        </label>
                                        <Field id="name_en" name="name_en" type="text" className="border border-gray-300 p-2" />
                                    </div>

                                    <div className="mb-5 flex flex-col w-full">
                                        <label htmlFor="short_comment" className="font-semibold text-base text-black mb-2 truncate">
                                            Описание (короткое)
                                        </label>
                                        <Field id="short_comment" name="short_comment" as="textarea" className="border border-gray-300 p-2" />
                                    </div>
                                    <div className="mb-5 flex flex-col w-full">
                                        <label htmlFor="short_comment_en" className="font-semibold text-base text-black mb-2 truncate">
                                            Описание (короткое) (на англ)
                                        </label>
                                        <Field id="short_comment_en" name="short_comment_en" as="textarea" className="border border-gray-300 p-2" />
                                    </div>

                                    <div className="mb-20 flex flex-col w-full">
                                        <label htmlFor="comment" className="font-semibold text-base text-black mb-2 truncate">
                                            Описание
                                        </label>
                                        <Field id="comment" name="comment" className="border border-gray-300 p-2">
                                            {({ field, imageHandler }) => (
                                                <CustomReactQuill imageHandler={imageHandler} value={field.value} onChange={field.onChange(field.name)} />
                                            )}
                                        </Field>
                                    </div>
                                    <div className="mb-20 flex flex-col w-full">
                                        <label htmlFor="comment_en" className="font-semibold text-base text-black mb-2 truncate">
                                            Описание (на англ)
                                        </label>
                                        <Field id="comment_en" name="comment_en" className="border border-gray-300 p-2">
                                            {({ field, imageHandler }) => (
                                                <CustomReactQuill imageHandler={imageHandler} value={field.value} onChange={field.onChange(field.name)} />
                                            )}
                                        </Field>
                                    </div>
                                    <div className="mb-5 flex flex-col w-full">
                                        <label htmlFor="price" className="font-semibold text-base text-black mb-2 truncate">
                                            Цена
                                        </label>
                                        <Field
                                            id="price"
                                            name="price"
                                            type="text"
                                            className="border border-gray-300 p-2"
                                            onChange={e => {
                                                e.preventDefault();
                                                const { value } = e.target;

                                                let formattedValue = value.trim().replace(/[^0-9,]/g, '');

                                                if (formattedValue[0] === ',') {
                                                    formattedValue = `0${formattedValue}`;
                                                }

                                                if (formattedValue[0] === '0' && formattedValue[1] === '0') {
                                                    formattedValue = formattedValue[0] + formattedValue.slice(2, formattedValue.length);
                                                }

                                                if (formattedValue.indexOf(',') >= 0 && formattedValue.indexOf(',', formattedValue.indexOf(',') + 1) >= 0) {
                                                    formattedValue = formattedValue.slice(0, formattedValue.indexOf(',', formattedValue.indexOf(',') + 1));
                                                }

                                                if (formattedValue.indexOf(',') >= 0) {
                                                    formattedValue = formattedValue.slice(0, formattedValue.indexOf(',') + 3);
                                                }

                                                setFieldValue('price', formattedValue);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex flex-row items-center justify-end">
                                <button
                                    type="button"
                                    className=" h-10 bg-transparent text-gray-700 px-6 transition-colors hover:text-black mr-4"
                                    onClick={() => closeHandler(resetForm)}
                                >
                                    Отмена
                                </button>
                                <button
                                    type="submit"
                                    className={`w-[135px] h-10 flex items-center justify-center text-white px-6 ${
                                        isSubmitting ? 'bg-gray-500' : 'bg-green-500 transition-colors hover:bg-green-600'
                                    }`}
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? <RotatingLines width="20" strokeColor="white" /> : 'Сохранить'}
                                </button>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
