import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Header } from './header/Header';
import { CreateMeetingPane } from './createMeetingPane/CreateMeetingPane';
import { BookingTable } from './bookingTable/BookingTable';
import { updateAllMeetingRoomBooking, set_selected_date, getAllMeetingRoomForFilter } from '../../../redux/slices/meetingRoomSlice';

export const BookingCalendar = () => {
    const dispatch = useDispatch();

    const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));
    const [bookingData, setBookingData] = useState(null);
    const [createMeetingPaneIsOpen, setCreateMeetingPaneIsOpen] = useState(false);

    useEffect(() => {
        dispatch(set_selected_date(Date.parse(selectedDate) / 1000));
        dispatch(getAllMeetingRoomForFilter());

        const interval = setInterval(async () => {
            await dispatch(updateAllMeetingRoomBooking());
        }, 10000);

        return () => clearInterval(interval);
    }, [selectedDate]);

    return (
        <>
            <Header setCreateMeetingPaneIsOpen={setCreateMeetingPaneIsOpen} setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
            <BookingTable selectedDate={selectedDate} setCreateMeetingPaneIsOpen={setCreateMeetingPaneIsOpen} setBookingData={setBookingData} />
            <CreateMeetingPane
                isOpen={createMeetingPaneIsOpen}
                setIsOpen={setCreateMeetingPaneIsOpen}
                selectedDate={selectedDate}
                bookingData={bookingData}
                setBookingData={setBookingData}
            />
        </>
    );
};
