import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import ReactTooltip from 'react-tooltip';
import { RotatingLines } from 'react-loader-spinner';

import { Header } from '../Header';
import { ContentWrapper } from '../../common/ContentWrapper';
import { PicsLoader } from '../../common/PicsLoader';
import { MeetingRoomScheme } from './helpers/MeetingRoomScheme';
import { initialValues } from './helpers/initialValues';
import { getValidateError } from './helpers/getValidateError';
import { IncludedOptions } from './templateParts/IncludedOptions';
import { AdditionOptions } from './templateParts/AdditionOptions';
import { MainInfo } from './templateParts/MainInfo';
import { successNotification, errorNotification, getColors } from '../../../api/functions';

import {
    meeting_rooms as meeting_rooms_state,
    editMeetingRoom,
    response_status as response_status_state,
    set_response_status,
    set_update_meetingRoom_fetch,
    update_meetingRoom_fetch as update_meetingRoom_fetch_state,
    meetingRoom_colors as meetingRoom_colors_state,
} from '../../../redux/slices/meetingRoomSlice';
import { access_token as access_token_state } from '../../../redux/slices/loginSlice';

export const MeetingRoom = ({ tab }) => {
    const formikRef = useRef();

    const dispatch = useDispatch();
    const meeting_rooms = useSelector(meeting_rooms_state);
    const access_token = useSelector(access_token_state);
    const response_status = useSelector(response_status_state);
    const update_meetingRoom_fetch = useSelector(update_meetingRoom_fetch_state);
    const meetingRoom_colors = useSelector(meetingRoom_colors_state);

    const [initialState, setInitialState] = useState(initialValues);
    const [fetchAccessToken, setFetchAccessToken] = useState('');
    const [colors, setColors] = useState([]);
    const [selectColor, setSelectColor] = useState([]);

    useEffect(() => {
        if (response_status) {
            successNotification();
            dispatch(set_response_status(false));
        }
    }, [response_status]);

    useEffect(() => {
        if (tab && meeting_rooms.length !== 0) {
            const meetingRoom = meeting_rooms.find(el => el.id === tab.id);

            setInitialState({
                ...meetingRoom,
                working_hours_from: new Date(2021, 1, 1, meetingRoom.working_hours_from, 0),
                working_hours_to: new Date(2021, 1, 1, meetingRoom.working_hours_to, 0),
                active: meetingRoom.active === '1' ? ['active'] : [],
            });
            setSelectColor(meetingRoom.color);
            setColors(getColors(meeting_rooms, meetingRoom_colors, meetingRoom.id));
        }
    }, [tab, meeting_rooms]);

    const formSubmit = async (values, { setSubmitting, resetForm }) => {
        setFetchAccessToken(access_token);
        dispatch(set_update_meetingRoom_fetch(true));

        let status;

        const data = {
            ...values,
            working_hours_from: values.working_hours_from.getHours(),
            working_hours_to: values.working_hours_to.getHours(),
            active: values.active[0] === 'active' ? '1' : '0',
            hotel_id: tab.hotel_id,
            section_link: tab.section_link,
            tab_item_id: values.id,
            color: selectColor,
        };

        status = await dispatch(editMeetingRoom({ data }));

        if (status === 200) {
            setSubmitting(false);
            resetForm();
        } else {
            errorNotification(status);
        }
    };

    useEffect(() => {
        (async () => {
            if (fetchAccessToken && fetchAccessToken !== access_token) {
                await formSubmit(formikRef.current.values, formikRef.current);
            }
        })();
    }, [access_token]);

    return (
        <>
            <Header tab={tab} />

            <ContentWrapper>
                <Formik initialValues={initialState} validationSchema={MeetingRoomScheme} innerRef={formikRef} onSubmit={formSubmit} enableReinitialize={true}>
                    {({ values, errors, isSubmitting, isValidating, dirty, setFieldValue }) => {
                        if (isSubmitting && !isValidating) {
                            getValidateError(errors);
                        }
                        return (
                            <Form>
                                <div>
                                    <div className="sm:flex ">
                                        {/* main info */}
                                        <div className=" sm:w-1/2 bg-white mb-6 pb-6">
                                            <MainInfo
                                                values={values}
                                                isSubmitting={isSubmitting}
                                                setFieldValue={setFieldValue}
                                                selectColor={selectColor}
                                                colors={colors}
                                                setSelectColor={setSelectColor}
                                            />
                                        </div>

                                        {/* photo */}
                                        <div className="ml-0 pb-6 sm:w-1/2 bg-white mb-6 sm:ml-6 ">
                                            <div className="flex items-center border-b">
                                                <p className="pb-6 pt-6 pl-6 font-medium">Загрузите фотографии</p>
                                                <div className="ml-auto mr-6" data-tip="Фото переговорной">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path
                                                            d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                            stroke="#1890FF"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path
                                                            d="M11.9502 8H12.0502V8.1H11.9502V8Z"
                                                            stroke="#1890FF"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className=" ml-6 mt-6">
                                                <PicsLoader setFieldValue={setFieldValue} files={values.files} maxFiles={10} />
                                            </div>
                                        </div>
                                    </div>

                                    {/*  options */}
                                    <div className=" mb-6 bg-white">
                                        <div className="flex items-center border-b mb-6">
                                            <p className="pb-6 pt-6 pl-6 font-medium">Укажите дополнительную информацию о переговорной</p>
                                            <div className="ml-auto mr-6" data-tip="Дополнительная информация">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path
                                                        d="M11.9502 8H12.0502V8.1H11.9502V8Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                        </div>

                                        <div className="pl-6 pr-6 pb-6 flex gap-10">
                                            {/* included options */}
                                            <div className="w-1/2">
                                                <IncludedOptions values={values} isSubmitting={isSubmitting} />
                                            </div>
                                            {/* addition options */}
                                            <div className="w-1/2">
                                                <AdditionOptions values={values} isSubmitting={isSubmitting} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className={` mt-3 rounded-sm font-medium text-sm px-14 h-10  ${
                                        dirty
                                            ? 'bg-blue-500 text-white cursor-pointer transition-colors hover:bg-blue-700'
                                            : 'bg-slate-300 cursor-default text-gray-400'
                                    } `}
                                    disabled={(!isSubmitting && !dirty) || update_meetingRoom_fetch}
                                >
                                    {update_meetingRoom_fetch ? <RotatingLines width="20" strokeColor="white" /> : 'Сохранить'}
                                </button>
                            </Form>
                        );
                    }}
                </Formik>
            </ContentWrapper>
            <ReactTooltip textColor="#FCFCFC" backgroundColor="#8c8d9d" effect="solid" className="!rounded-md !py-2 !px-2.5" />
        </>
    );
};
