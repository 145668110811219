export const ChangeButton = ({ setChangePopupIsOpen, machine, setMachineId }) => {
    return (
        <button
            type="button"
            className="text-sm text-blue-500 px-2 py-1 bg-white rounded-2xl"
            onClick={() => {
                setChangePopupIsOpen(true);
                setMachineId(machine.id);
            }}
        >
            Изменить
        </button>
    );
};
