import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api';
import { set_token_error } from './loginSlice';

export const hotelSlice = createSlice({
    name: 'hotel',

    initialState: {
        tabs: [],
        tab_fetching: false,
    },

    reducers: {
        set_tabs: (state, action) => {
            state.tabs = action.payload;
        },
        set_tab_fetching: (state, action) => {
            state.tab_fetching = action.payload;
        },
        upd_loyalty: (state, action) => {
            state.tabs = state.tabs.map(el => (el.id === action.payload.hotel_id ? { ...el, loyalty_json: action.payload.loyalty } : el));
        },
        add_section: (state, action) => {
            state.tabs = state.tabs.map(hotel => {
                if (hotel.id === action.payload.hotel_id) {
                    const hotel_subitems = hotel.subitems.map(subitem => {
                        if (subitem.link === action.payload.section_link) {
                            return { ...subitem, subitems: [...subitem.subitems, action.payload] };
                        }
                        return subitem;
                    });
                    return { ...hotel, subitems: hotel_subitems };
                }
                return hotel;
            });
        },
        set_sort_info_sections: (state, action) => {
            state.tabs = state.tabs.map(hotel => {
                if (hotel.id === action.payload.hotel_id) {
                    const hotel_subitems = hotel.subitems.map(subitem => {
                        if (subitem.link === 'information') {
                            const sort_info = subitem.subitems.sort((x, y) => x.sort - y.sort);
                            return { ...subitem, subitems: sort_info };
                        }
                        return subitem;
                    });
                    return { ...hotel, subitems: hotel_subitems };
                }
                return hotel;
            });
        },
        update_hotel_tab: (state, action) => {
            state.tabs = state.tabs.map(hotel => {
                if (hotel.id === action.payload.hotel_id) {
                    const hotel_subitems = hotel.subitems.map(subitem => {
                        if (subitem.link === action.payload.section_link) {
                            const sections = subitem.subitems.map(section => {
                                if (section.id === action.payload.tab_item_id) {
                                    return { ...section, ...action.payload };
                                }
                                return section;
                            });
                            return { ...subitem, subitems: sections };
                        }
                        return subitem;
                    });
                    return { ...hotel, subitems: hotel_subitems };
                }
                return hotel;
            });
        },
        update_manage_info: (state, action) => {
            state.tabs = state.tabs.map(hotel => {
                if (hotel.id === action.payload.hotel_id) {
                    return { ...hotel, manage_additional_html: action.payload.manage_additional_html };
                }
                return hotel;
            });
        },
        update_tab: (state, action) => {
            state.tabs = state.tabs.map(hotel => {
                if (hotel.id === action.payload.hotel_id) {
                    return {
                        ...hotel,
                        subitems: hotel.subitems.map(subitem => {
                            if (subitem.link === action.payload.shop_link) {
                                return {
                                    ...subitem,
                                    subitems: subitem.subitems.map(subSubitem => {
                                        if (subSubitem.id === action.payload.id) {
                                            return { ...subSubitem, ...action.payload };
                                        }
                                        return subSubitem;
                                    }),
                                };
                            }
                            return subitem;
                        }),
                    };
                }
                return hotel;
            });
        },
        add_tab: (state, action) => {
            state.tabs
                .find(hotel => hotel.id === action.payload.hotel_id)
                .subitems.find(subitem => subitem.link === action.payload.shop_link)
                .subitems.push(action.payload);
        },
    },
});

export const { update_manage_info, upd_loyalty, set_tabs, set_tab_fetching, add_section, set_sort_info_sections, update_hotel_tab, update_tab, add_tab } =
    hotelSlice.actions;

export const getHotelsList =
    ({ access_token }) =>
    async dispatch => {
        dispatch(set_tab_fetching(true));
        try {
            if (access_token) {
                const response = await api.getHotelList.fetch(access_token);

                if (response.status === 200) {
                    const res = await response.json();
                    dispatch(set_tabs(res));
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                }
            }
        } catch (e) {
            console.log('getHotelsList error: ', e);
        }
        dispatch(set_tab_fetching(false));
    };

export const tabs = state => state.hotel.tabs;
export const tab_fetching = state => state.hotel.tab_fetching;

export default hotelSlice.reducer;
