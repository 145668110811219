import React from 'react';
import { useDropzone } from 'react-dropzone';

import { Fancybox } from './Fancybox';
import { toast } from 'react-toastify';

const errors = [
    {
        code: 'too-many-files',
        message: 'Слишком много файлов',
    },
    {
        code: 'file-too-large',
        message: 'Файл слишком большой',
    },
];

export const PicsLoader = ({ setFieldValue, files = [], maxFiles = 1, callback }) => {
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            // 'image/*': [],
            'image/jpeg': [],
            'image/png': [],
            'image/webp': [],
            'image/heic': [],
            'image/jfif': [],
        },
        maxFiles,
        maxSize: 5242880, //5mb
        onDrop: (acceptedFiles, fileRejections) => {
            if (acceptedFiles.length) {
                if (files.length && maxFiles > 1) {
                    setFieldValue('files', [...files, ...acceptedFiles.map(file => URL.createObjectURL(file))]);
                } else {
                    setFieldValue(
                        'files',
                        acceptedFiles.map(file => URL.createObjectURL(file))
                    );
                }
            }

            if (fileRejections.length) {
                fileRejections.forEach(file => {
                    toast.error(`Файл ${file.file.name.substring(0, 10)} не удалось загрузить. ${errors.find(er => er.code === file.errors[0].code).message}`, {
                        position: 'bottom-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                });
            }
        },
    });

    return (
        <>
            <input {...getInputProps()} />
            <div className="font-semibold text-base text-black mb-2">Фото</div>
            <aside className="flex flex-row flex-wrap gap-2">
                {files.length !== 0 &&
                    files.map((file, index) => (
                        <div key={file} className="w-[90px] h-[90px] relative cursor-pointer" data-src={file}>
                            <img src={file} alt="img" data-fancybox="gallery" className="object-cover w-full h-full" />
                            <button
                                type="button"
                                className="w-6 h-6 bg-black flex items-center justify-center absolute right-0 top-0 z-10"
                                onClick={() => {
                                    if (callback) {
                                        callback(file);
                                    }
                                    setFieldValue('files', [...files.slice(0, index), ...files.slice(index + 1)]);
                                }}
                            >
                                <svg className="w-4 h-4 fill-white" viewBox="0 0 14 14">
                                    <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" />
                                </svg>
                            </button>
                        </div>
                    ))}
                <Fancybox />
                {(files.length === 0 || maxFiles > 1) && (
                    <div {...getRootProps({ className: 'dropzone' })} className="w-[90px] h-[90px] flex items-center justify-center cursor-pointer bg-gray-200">
                        <svg viewBox="0 0 14 14" className="w-4 h-4 fill-black">
                            <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fillOpacity="0.45" />
                        </svg>
                    </div>
                )}
            </aside>
        </>
    );
};
